import {InPageTab} from "../../components/InPageTab";
import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {Button} from "../../../components/Button";
import {Table} from "../../../components/Table";
import {downloadPostingComments} from "../../../api/postingAPI";
import {getComments} from "../../../api/commentAPI";
import {CommentDto} from "../../../types/comment";
import {toast} from "react-toastify";
import {MomentFormat, utcToLocalFormat} from "../../../plugins/moment";
import {Card} from "../../../components/Card";
import {CommentModal} from "../../components/CommentModal";

const PostCommentList = () => {
    const {id} = useParams<{ id: string }>();
    const numId = Number(id);
    const history = useHistory();
    const [comments, setComments] = useState<CommentDto[]>([]);
    const [modal, setModal] = useState<{ isOpen: boolean, content?: CommentDto }>({isOpen: false});

    const excelDownload = () => {
        if (comments.length === 0) {
            toast.error('다운로드 받을 내용이 없습니다.');
            return;
        } else {
            downloadPostingComments(id);
        }
    }

    const getList = () => {
        getComments(`where[postingId]=${numId}`, 1, 999).then(res => {
            const list = res.data.items.sort((a, b) => b.id - a.id);
            setComments(list);
        })
    }

    useEffect(() => {
        getList();
    }, [])

    return (
        <div>
            <InPageTab tabs={[
                {
                    name: '게시글 정보',
                    selected: !history.location.pathname.includes('comments'),
                    onClick: () => history.push(`/admin/post/a/${id}`)
                },
                {
                    name: '코멘트 정보',
                    selected: history.location.pathname.includes('comments'),
                    onClick: () => history.push(`/admin/post/a/${id}/comments`)
                },
            ]}/>
            <Button
                text="Excel export"
                className="filled-black h-12 mb-4"
                onClick={excelDownload}
            />
            <Card>
                <Table>
                    <Table.Head>
                        <Table.Row>
                            <Table.Th>ID</Table.Th>
                            <Table.Th>작성일</Table.Th>
                            <Table.Th>회원명</Table.Th>
                            <Table.Th>핸드폰번호</Table.Th>
                            <Table.Th>이메일</Table.Th>
                            <Table.Th>노출여부</Table.Th>
                            <Table.Th>내용</Table.Th>
                            <Table.Th></Table.Th>
                        </Table.Row>
                    </Table.Head>
                    <Table.Body>
                        {comments.length === 0 && (
                            <Table.Row>
                                <Table.Td colSpan={4}>데이터가 없습니다.</Table.Td>
                            </Table.Row>
                        )}
                        {comments.map((comment) => (
                            <Table.Row key={comment.id}>
                                <Table.Td>{comment.id}</Table.Td>
                                <Table.Td>{utcToLocalFormat(
                                    comment.createdAt.toLocaleString(),
                                    MomentFormat.YYYYMMDDHmm
                                )}</Table.Td>
                                <Table.Td>{comment.user ? comment.user.name : '탈퇴한 유저'}</Table.Td>
                                <Table.Td>{comment.user ? comment.user.phone : '-'}</Table.Td>
                                <Table.Td>{comment.user ? comment.user.email : '-'}</Table.Td>
                                <Table.Td>{comment.isHidden ? '숨김' : '노출'}</Table.Td>
                                <Table.Td>{comment.comment}</Table.Td>
                                <Table.Td className="text-right">
                                    <button className="rounded-md font-normal text-indigo-600 hover:text-indigo-900"
                                            onClick={() => setModal({isOpen: true, content: comment})}>
                                        상세보기
                                    </button>
                                </Table.Td>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </Card>
            <CommentModal title={'코멘트 정보'}
                          content={modal.content!}
                          open={modal.isOpen}
                          onClose={() => {
                              setModal({isOpen: false});
                              getList();
                          }}
            />
        </div>
    )
}

export default PostCommentList