import {api} from "../plugins/axios";
import {Paged} from "../types/common";
import {CreatePostingDto, PostingDto, UpdatePostingDto} from "../types/posting";
import {CreateCurationProductDto} from "../types/arcnShop";
import {CreateMainBannerDto} from "../types/banner";

export const getPostings = (query?: string, page?: number, itemsPerPage?: number) => {
    const params = {
        page: page || 1,
        itemsPerPage: itemsPerPage || 30,
    };
    return api.get<Paged<PostingDto>>(`/postings?${query}`, {params});
}

export const postPosting = (data: CreatePostingDto) => {
    return api.post<PostingDto>('/postings', data);
}

export const getPosting = (id: number) => {
    return api.get<PostingDto>(`/postings/${id}`);
}

export const patchPosting = (id: number, data: Partial<UpdatePostingDto>) => {
    return api.patch<PostingDto>(`/postings/${id}`, data);
}

export const deletePosting = (id: number) => {
    return api.delete<void>(`/postings/${id}`);
}

export const addPostingProduct = (id: number, data: CreateCurationProductDto) => {
    return api.post(`/postings/${id}/products`, data);
}

export const addPostingBanners = (id: number, data: CreateMainBannerDto) => {
    return api.post(`/postings/${id}/banners`, data);
}

export const downloadPostingComments = (id: string) => {
    return window.open(`${process.env.REACT_APP_API_URL}/postings/${id}/comments/excel`);
}