export interface LoginForm {
    email: string;
    password: string;
}

export interface Token {
    token: string;
}

export interface UserDto {
    id: number;
    name: string;
    email: string;
    role: UserRole;
    adminLevel: AdminLevel;
    createdAt: string;
    updatedAt: string;
}

export enum UserRole {
    ADMIN = 'ADMIN',
    USER = 'USER',
}

export interface UserManageItemDto {
    id: number;
    name: string;
    email: string;
    phone: string;
    createdAt: string;
    updatedAt: string;
}

export interface CreateUserManageDto {
    name: string;
    phone: string;
    phoneConfirmedAt?: string;
    email: string;
    password: string;
    zipcode?: string;
    address?: string;
    addressDetail?: string;
    memo?: string;
    isMarketingUsageAgreed: boolean;
}

export interface UserManageDetailDto {
    id: number;
    name: string;
    phone: string;
    level: string;
    levelEndDate: string;
    levelStartDate: string;
    levelUpdatedAt: string;
    libroOrderAmount: number;
    libroOrderAmountLastUpdatedAt: string;
    phoneConfirmedAt?: string;
    email: string;
    zipcode: string;
    address: string;
    addressDetail?: string;
    serviceTermAgreedAt: string;
    marketingUsageAgreedAt?: string;
    mileageAmount: number;
    memo?: string;
    createdAt: string;
    updatedAt: string;
}

export enum AdminLevel {
    NOT_ADMIN = 'NOT_ADMIN',
    ROOT_ADMIN = 'ROOT_ADMIN',
    BRANCH_MANAGER = 'BRANCH_MANAGER',
}

export const getAdminLevelText = (level: AdminLevel) => ({
    NOT_ADMIN: '관리자가 아닙니다',
    BRANCH_MANAGER: '지점 관리자',
    ROOT_ADMIN: '최고 관리자',
}[level] || '');

export interface AdminDto {
    id: number;
    name: string;
    email: string;
    phone: string;
    role: UserRole;
    adminLevel: AdminLevel;
    memo: string;
    createdAt: string;
    updatedAt: string;
}

export interface CreateAdminDto {
    adminLevel: AdminLevel;
    name: string;
    email: string;
    password: string;
    memo: string;
}
