import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from '../../components/Card';
import { Pagination } from '../../components/Pagination';
import { Table } from '../../components/Table';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { PartnershipFormModal } from './PartnershipFormModal';
import queryString from 'query-string';
import { getPartnershipForms } from '../../api/partnershipFormAPI';
import { PartnershipForm, getInquiryType } from '../../types/partnershipForm';
import { Paged } from '../../types/common';

export const PartnershipFormListTable = () => {
  const history = useHistory();
  const query = queryString.parse(history.location.search);
  const [isViewModal, setIsViewModal] = useState(Boolean);
  const [partnershipFormId, setPartnershipFormId] = useState(Number);
  const [partnershipForms, setPartnershipForms] = React.useState<
    Paged<PartnershipForm>
  >({
    items: [],
  });

  const getList = () => {
    let nQuery = ``;
    nQuery += `&order[id]=DESC`;
    getPartnershipForms(`${nQuery}`, Number(query.page), Number(query.limit))
      .then((res) => setPartnershipForms(res.data))
      .catch((err) => console.error(err.response));
  };

  useEffect(() => getList(), [history.location]);

  return (
    <>
      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>문의 요청 일자</Table.Th>
              <Table.Th>문의 타입</Table.Th>
              <Table.Th>이름</Table.Th>
              <Table.Th>이메일</Table.Th>
              <Table.Th>전화번호</Table.Th>
              <Table.Th>업체명</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {partnershipForms.items.length === 0 && (
              <Table.Row>
                <Table.Td colSpan={8}>제휴 요청이 없습니다.</Table.Td>
              </Table.Row>
            )}
            {partnershipForms.items.map((partnershipForm) => (
              <Table.Row key={partnershipForm.id}>
                <Table.Td>
                  {utcToLocalFormat(
                    partnershipForm.createdAt.toLocaleString(),
                    MomentFormat.YYYYMMDDHmm
                  )}
                </Table.Td>
                <Table.Td>{getInquiryType(partnershipForm)}</Table.Td>
                <Table.Td>{partnershipForm.name}</Table.Td>
                <Table.Td>{partnershipForm.email}</Table.Td>
                <Table.Td>{partnershipForm.phoneNumber}</Table.Td>
                <Table.Td>{partnershipForm.companyName}</Table.Td>
                <Table.Td>
                  <button
                    className="rounded-md font-normal text-indigo-600 hover:text-indigo-900"
                    onClick={() => {
                      setIsViewModal(true);
                      setPartnershipFormId(partnershipForm.id);
                    }}
                  >
                    상세보기
                  </button>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Pagination>
          <Pagination.Label
            count={partnershipForms.items.length}
            total={partnershipForms.pagination?.totalItemCount!}
          />
          <Pagination.Nav
            basePath={history.location.pathname}
            total={partnershipForms.pagination?.totalItemCount!}
          />
        </Pagination>
      </Card>
      <PartnershipFormModal
        partnershipFormId={partnershipFormId}
        open={isViewModal}
        onClose={() => setIsViewModal(false)}
      />
    </>
  );
};
