import {TextField} from "../../../components/TextField";
import {Button} from "../../../components/Button";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {CreateRecommendedCategoryDto, UpdateRecommendedCategoryDto} from "../../../types/recommendedCategory";
import {uploadFile} from "../../../api/fileAPI";
import {FileAbout} from "../../../types/common";
import {
    deleteRecommendedCategory,
    getRecommendedCategory,
    postRecommendedCategory,
    updateRecommendedCategory
} from "../../../api/recommendedCategoryAPI";

export interface RecommendedCategoryTemplateProps {
    type: 'ADD' | 'EDIT';
    targetId?: number;
}

const RecommendedCategoryTemplate = (props: RecommendedCategoryTemplateProps) => {
    const {type, targetId} = props;
    const [categoryImg, setCategoryImg] = useState<File | null | undefined>();
    const {goBack} = useHistory();
    const form = useForm<CreateRecommendedCategoryDto | UpdateRecommendedCategoryDto>();

    const onSubmit = async (data: CreateRecommendedCategoryDto) => {
        data.order = Number(data.order);
        if (categoryImg) {
            data.imageUrl = await uploadFile(FileAbout.RECOMMENDED_CATEGORY, categoryImg);
        }
        if (type === 'ADD') {
            postRecommendedCategory(data).then(() => goBack()).catch(err => console.error(err.response));
        } else {
            updateRecommendedCategory(targetId!, data).then(() => goBack()).catch(err => console.error(err.response));
        }
    }

    const removeThis = () => {
        const confirm = window.confirm("정말 삭제하시겠습니까?");
        if (confirm) {
            deleteRecommendedCategory(targetId!).then(() => goBack()).catch(err => console.error(err.response));
        }
    }

    useEffect(() => {
        targetId && getRecommendedCategory(targetId).then(res => {
            form.setValue('order', res.data.order);
            form.setValue('name', res.data.name);
            form.setValue('imageUrl', res.data.imageUrl);
            form.setValue('url', res.data.url);
        }).catch(err => console.error(err.response));
    }, [targetId])

    return (
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="max-w-md space-y-4">
                <TextField label="노출 우선순위"
                           {...form.register('order', {required: true})}/>
                <TextField label="카테고리명"
                           {...form.register('name', {required: true})}/>
                <TextField label="클릭시 URL"
                           {...form.register('url', {required: true})}/>
                {/*<div>*/}
                {/*    <label htmlFor="categoryImg">*/}
                {/*        <div className="mb-2 text-sm text-gray-800 ">*/}
                {/*            추천 카테고리 이미지*/}
                {/*        </div>*/}

                {/*        {categoryImg || form.watch('imageUrl') ? (*/}
                {/*            <>*/}
                {/*                <img src={categoryImg ? URL.createObjectURL(categoryImg) : form.watch('imageUrl')} alt=""/>*/}
                {/*                <div className="mt-4 w-full">*/}
                {/*                    <Button type={'button'} className="filled-black h-12 py-3">*/}
                {/*                        <label*/}
                {/*                            htmlFor="categoryImg"*/}
                {/*                            className="w-full cursor-pointer"*/}
                {/*                        >*/}
                {/*                            수정하기*/}
                {/*                        </label>*/}
                {/*                    </Button>*/}
                {/*                </div>*/}
                {/*            </>*/}
                {/*        ) : !categoryImg ? (*/}
                {/*            <div*/}
                {/*                className="border-grey-5 flex w-full flex-col items-center justify-center space-y-1 border-2 border-dashed py-10">*/}
                {/*                <FileUploadImg/>*/}
                {/*                <div className="w-3/5 text-center text-xs text-gray-500">*/}
                {/*                    상세 이미지 첨부하기*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        ) : (*/}
                {/*            ''*/}
                {/*        )}*/}
                {/*    </label>*/}
                {/*    <input*/}
                {/*        type="file"*/}
                {/*        id="categoryImg"*/}
                {/*        className="hidden"*/}
                {/*        accept="image/*"*/}
                {/*        onChange={(e) => {*/}
                {/*            e.target.validity.valid &&*/}
                {/*            setCategoryImg(e.target.files?.item(0));*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</div>*/}
                {type === 'ADD' ? (
                    <>
                        <Button type={'submit'} text="추가하기" className="filled-black h-12 w-full px-6"/>
                        <Button type={'button'}
                                text="취소"
                                className="outlined-black h-12 w-full px-6"
                                onClick={() => goBack()}
                        />
                    </>
                ) : (
                    <>
                        <Button type={'submit'} text="저장하기" className="filled-black h-12 w-full px-6"/>
                        <Button type={'button'}
                                text="삭제하기"
                                onClick={removeThis}
                                className="filled-gray-200 h-12 w-full px-6 text-gray-800"
                        />
                    </>
                )}

            </div>
        </form>
    )
}

export default RecommendedCategoryTemplate;