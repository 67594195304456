import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Icon } from '../../../components/Icon';
import { Button } from '../../../components/Button';
import { deleteSection, getSections } from '../../../api/sectionAPI';

interface DeleteSectionModalProps {
  getList: () => void;
  open: boolean;
  onClose: () => void;
  section: any;
}
const DeleteSectionModal = (props: DeleteSectionModalProps) => {
  // TODO: Section 삭제 시 오류 처리 필요 (섹션의 데이터가 존재할 경우)
  const handleDelete = () => {
    deleteSection(props.section.id)
      .then(() => {
        props.onClose();
      })
      .then(() => {
        props.getList();
      })
      .catch((err) => console.error(err.response));
  };

  return (
    <Transition appear show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto bg-gray-500 bg-opacity-75 transition-opacity"
        onClose={props.onClose}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="my-8 inline-block w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              <div className="mb-5 flex items-center justify-between">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  섹션 삭제
                </Dialog.Title>
                <Icon.X onClick={props.onClose} className="cursor-pointer" />
              </div>
              <div>
                <p className=" text-gray-500">
                  <span className={'font-bold text-gray-700'}>
                    {props.section?.type}{' '}
                  </span>
                  섹션을 삭제하시겠습니까?
                </p>
                <p className={'text-sm text-red-500'}>
                  *섹션 삭제 시 섹션의 데이터를 모두 삭제한 뒤 섹션을
                  삭제해주세요.
                </p>
              </div>

              <div className="mt-4">
                <Button
                  disabled={false}
                  onClick={handleDelete}
                  text="삭제"
                  className="filled-black h-12 text-sm"
                />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default DeleteSectionModal;
