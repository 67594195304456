import {ProductDto} from "../types/product";

export const productStatus = (product: ProductDto) => {
    if (product.isSoldOut) {
        return '품절';
    } else if (product.isDisplayHidden) {
        return '숨김';
    } else {
        return '판매중';
    }
}