import { CategoryDto } from './categories';

export enum ProductType {
  GENERAL = 'GENERAL',
  BOOK = 'BOOK',
}

export enum DiscountType {
  RATE = 'RATE',
  PRICE = 'PRICE',
}

export interface CreateProductDto {
  companyCode: string;
  companyId: number;
  name: string;
  images: string[];
  type: ProductType;
  barcode: string;
  isNew: boolean;
  isAdultOnly: boolean;
  isDisplayHidden: boolean;
  isSoldOut: boolean;
  remainQuantity?: number;
  price: number;
  regularPrice: number;
  sourcePrice: number;
  discountType: DiscountType;
  discountAmount: number;
  contentText?: string;
  contentHtml?: string;
  bookInfo?: BookInfoDto;
  categoryId: number;
  categoryRank: number;
}

export interface ProductDto {
  id: number;
  companyId: number;
  category: CategoryDto;
  categoryId: number;
  categoryRank: number;
  company: CompanyDto;
  name: string;
  type: ProductType;
  barcode: string;
  isNew: boolean;
  isAdultOnly: boolean;
  isDisplayHidden: boolean;
  isDependOnBooksenSoldOut: boolean;
  isSoldOut: boolean;
  remainQuantity: number;
  price: number;
  regularPrice: number;
  sourcePrice: number;
  isReturnable: boolean;
  discountType: DiscountType;
  discountAmount: number;
  images: string[];
  contentText: string;
  contentHtml: string;
  bookInfo: BookInfoDto;
  createdAt: string;
  updatedAt: string;
  promotionId?: number;
  soldQuantity?: number;
}

export interface CompanyDto {
  id: number;
  name: string;
  description: string;
  mobileCompanyImageUrl: string;
  pcCompanyImageUrl: string;
  createdAt: string;
  updatedAt: string;
  paginatedIndex?: number;
}

export interface BookInfoDto {
  authorName: string;
  publisherName: string;
  publishedAt: string;
  descTable: string;
  descPub: string;
}

export interface UpdateBookInfoDto {
  authorName?: string;
  publisherName?: string;
  publishedAt?: string;
  descTable?: string; // 서지정보 - 목차
  descPub?: string; //서지정보 - 출판사 서평
}

export interface EventTagDto {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export interface CreateGeneralDto {
  isDisplayHidden: boolean;
  isSoldOut: boolean;
  isNew: boolean;
  companyId: number;
  images: string[];
  barcode: string;
  regularPrice: number;
  discountType: DiscountType;
  discountAmount: number;
  price: number;
  name: string;
  contentText?: string;
  contentHtml?: string;
  promotionId?: number;
  categoryId?: number;
}

export interface CreateBookDto {
  isDisplayHidden: boolean;
  isSoldOut: boolean;
  isNew: boolean;
  images: string[];
  barcode: string;
  remainQuantity: number;
  regularPrice: number;
  sourcePrice: number;
  discountType: DiscountType;
  discountAmount: number;
  price: number;
  categoryId: number;
  categoryRank: number;
  name: string;
  bookInfo: UpdateBookInfoDto;
  contentText?: string;
  contentHtml?: string;
  isDependOnBooksenSoldOut?: boolean;
  promotionId?: number;
}

export enum DetailDtoType {
  BOOKSEN = 'booksen',
  PRODUCT = 'product',
}
