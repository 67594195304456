import React, { useEffect, useState } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { Button } from '../../../../components/Button';
import { AdminH1 } from '../../../components/AdminH1';
import { Tab } from '../../../components/Tab';
import { MobilePageEdit } from './MobliePageEdit';
import { PcPageEdit } from './PcPageEdit';
import {
  Partnership,
  UpdatePartnershipDto,
} from '../../../../types/partnersip';
import { useForm } from 'react-hook-form';
import {
  getPartnership,
  patchPartnership,
  postPartnership,
} from '../../../../api/partnershipAPI';
import { toast } from 'react-toastify';

export const PageEditMainPage = () => {
  const { pathname } = useLocation();
  const { push } = useHistory();
  const history = useHistory();
  const [partnership, setPartnership] = useState<Partnership>();

  const form = useForm<UpdatePartnershipDto>({
    defaultValues: {
      pcContents: partnership?.pcContents,
      mobileContents: partnership?.mobileContents,
    },
  });

  const getList = () => {
    getPartnership()
      .then((res) => {
        form.setValue('pcContents', res.data.pcContents);
        form.setValue('mobileContents', res.data.mobileContents);
        setPartnership(res.data);
      })
      .catch((err) => console.error(err.response));
  };

  useEffect(() => getList(), [history.location]);

  const onSave = () => {
    if (!partnership) {
      postPartnership(form.getValues())
        .then(() => {
          toast.success('저장되었습니다.');
        })
        .catch((e) => console.error(e.response));
    }

    if (partnership?.id) {
      patchPartnership(partnership?.id, form.getValues())
        .then(() => {
          toast.success('저장되었습니다.');
        })
        .catch((e) => console.error(e.response));
    }
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <AdminH1>제휴 페이지 관리</AdminH1>
        <Button
          text="Save"
          className="h-fit w-fit bg-gray-800 px-4 py-2 text-white"
          onClick={onSave}
        />
      </div>
      <div className="flex space-x-4 border-b">
        <Tab
          text="PC"
          selected={pathname === '/admin/partnership/page/pc'}
          onClick={() => push('/admin/partnership/page/pc')}
        />
        <Tab
          text="MOBILE"
          selected={pathname === '/admin/partnership/page/moblie'}
          onClick={() => push('/admin/partnership/page/moblie')}
        />
      </div>
      <Switch>
        <Route
          path="/admin/partnership/page/pc"
          render={() => <PcPageEdit form={form} />}
        />
        <Route
          path="/admin/partnership/page/moblie"
          render={() => <MobilePageEdit form={form} />}
        />
        <Route path="/admin/partnership/page">
          <Redirect to="/admin/partnership/page/pc" />
        </Route>
      </Switch>
    </>
  );
};
