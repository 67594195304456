import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { Checkbox } from '../../../components/Checkbox';
import { TextArea } from '../../../components/TextArea';
import { TextField } from '../../../components/TextField';
import { FloalaEditor } from '../../components/FloalaEditor';
import { FileAbout } from '../../../types/common';
import { getBooksenProduct, postBookProduct } from '../../../api/productAPI';
import { useForm } from 'react-hook-form';
import { CreateBookDto, DiscountType } from '../../../types/product';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Select } from '../../../components/Select';
import useCategories from '../../../hooks/useCategories';

export const BooksProductAdd = () => {
  const history = useHistory();
  const form = useForm<CreateBookDto>();
  const [hasIsbn, setHasIsbn] = React.useState<boolean>(false);
  // const { categories, setCategories, subCategories, setSubCategories } =
  //   useCategories({
  //     id: 1,
  //     title: '도서 상품',
  //   });

  const getBookInfo = () => {
    getBooksenProduct(form.watch('barcode'))
      .then((res) => {
        const data = res.data;
        form.setValue('isNew', data.isNew);
        form.setValue('isDisplayHidden', data.isDisplayHidden);
        form.setValue('isSoldOut', data.isSoldOut);
        form.setValue('remainQuantity', data.remainQuantity);
        form.setValue('discountType', data.discountType);
        form.setValue('discountAmount', data.discountAmount);
        form.setValue('name', data.name);
        form.setValue('regularPrice', data.regularPrice);
        form.setValue('sourcePrice', data.sourcePrice);
        form.setValue('price', data.price);
        // @ts-ignore
        form.setValue('images', JSON.parse(data.images));
        form.setValue('bookInfo', data.bookInfo);
        setHasIsbn(true);
        toast.success('도서 정보를 가져왔습니다.');
      })
      .catch((err) => console.error(err.response));
  };

  // const onsubmit = () => {
  //   if (!!categories.selected) {
  //     form.setValue('categoryId', categories.selected.id);
  //   } else if (!!subCategories.selected) {
  //     form.setValue('categoryId', subCategories.selected.id);
  //   } else {
  //     toast.error('Please select category');
  //     return;
  //   }
  //   postBookProduct(form.getValues())
  //     .then((res) => {
  //       toast.success('상품 등록 성공. 태그를 선택해 주세요.');
  //       history.replace(`/admin/books/product${res.data.id}`);
  //     })
  //     .catch((e) => console.error(e.response));
  // };

  useEffect(() => {
    if (form.watch('discountType') === DiscountType.RATE) {
      let price =
        form.watch('regularPrice') -
        (form.watch('regularPrice') * (form.watch('discountAmount') || 0)) /
          100;
      form.setValue('price', price);
    } else if (form.watch('discountType') === DiscountType.PRICE) {
      let price = form.watch('regularPrice') - form.watch('discountAmount');
      form.setValue('price', price);
    }
  }, [form]);

  return (
    <>
      {/*<div className="space-y-4">*/}
      {/*  <div className="flex space-x-5">*/}
      {/*    <Checkbox*/}
      {/*      label="상품 숨김처리하기"*/}
      {/*      checked={form.watch('isDisplayHidden')}*/}
      {/*      onChange={() =>*/}
      {/*        form.setValue('isDisplayHidden', !form.watch('isDisplayHidden'))*/}
      {/*      }*/}
      {/*    />*/}
      {/*    <Checkbox*/}
      {/*      label="상품 품절처리하기"*/}
      {/*      checked={form.watch('isSoldOut')}*/}
      {/*      onChange={() =>*/}
      {/*        form.setValue('isSoldOut', !form.watch('isSoldOut'))*/}
      {/*      }*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*  <div className="flex space-x-5">*/}
      {/*    /!*<Checkbox label="성인 인증 상품" checked={form.watch('isAdultOnly')}*!/*/}
      {/*    /!*          onChange={() => form.setValue('isAdultOnly', !form.watch('isAdultOnly'))}/>*!/*/}
      {/*    <Checkbox*/}
      {/*      label="신상품으로 설정(NEW)"*/}
      {/*      checked={form.watch('isNew')}*/}
      {/*      onChange={() => form.setValue('isNew', !form.watch('isNew'))}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/*<div className="w-full bg-gray-50 py-2" />*/}

      {/*<div className="grid grid-cols-3 gap-5">*/}
      {/*  {(form.watch('images') || []).map((img, index) => (*/}
      {/*    <div key={index}>*/}
      {/*      <div className="mb-2 text-sm">표지이미지{index + 1} (북센)</div>*/}
      {/*      <div className="relative aspect-[3/5] overflow-hidden rounded border">*/}
      {/*        <img*/}
      {/*          className="absolute h-full w-full object-cover"*/}
      {/*          src={img || ''}*/}
      {/*          alt=""*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  ))}*/}
      {/*</div>*/}

      {/*<div className="grid grid-cols-3 gap-5 pt-5">*/}
      {/*  <div className="col-span-3 text-lg font-semibold">상품 세부정보</div>*/}
      {/*  <TextField*/}
      {/*    label="ISBN"*/}
      {/*    {...form.register('barcode')}*/}
      {/*    onKeyDown={(e) => e.key === 'Enter' && getBookInfo()}*/}
      {/*  />*/}
      {/*  <p>*/}
      {/*    /!*최근 업데이트 일자 : <b>2022.04.03.13:00</b>*!/*/}
      {/*    {!hasIsbn && '👈 ISBN 우선 입력해주세요.'}*/}
      {/*  </p>*/}
      {/*  <p>/!*도서 상태 : <b>도서 상태</b>*!/</p>*/}
      {/*  <TextField*/}
      {/*    label="재고수량"*/}
      {/*    value={(form.watch('remainQuantity') || 0).toLocaleString()}*/}
      {/*    disabled={true}*/}
      {/*  />*/}
      {/*  <TextField*/}
      {/*    label="정가(원)"*/}
      {/*    value={(form.watch('regularPrice') || 0).toLocaleString()}*/}
      {/*    disabled={true}*/}
      {/*  />*/}
      {/*  <TextField*/}
      {/*    label="출고가(원)"*/}
      {/*    value={(form.watch('sourcePrice') || 0).toLocaleString()}*/}
      {/*    disabled={true}*/}
      {/*  />*/}
      {/*  <div>*/}
      {/*    {form.watch('discountType') === DiscountType.RATE ? (*/}
      {/*      <TextField*/}
      {/*        label="할인율(%)"*/}
      {/*        type={'number'}*/}
      {/*        defaultValue={10}*/}
      {/*        {...form.register('discountAmount', {*/}
      {/*          required: '할인율을 입력하세요',*/}
      {/*          setValueAs: (value) => Number(value),*/}
      {/*        })}*/}
      {/*        helper={form.formState.errors.discountAmount?.message}*/}
      {/*      />*/}
      {/*    ) : (*/}
      {/*      <TextField*/}
      {/*        label="할인금액(원)"*/}
      {/*        type={'number'}*/}
      {/*        defaultValue={0}*/}
      {/*        {...form.register('discountAmount', {*/}
      {/*          required: '할인율을 입력하세요',*/}
      {/*          setValueAs: (value) => Number(value),*/}
      {/*        })}*/}
      {/*        helper={form.formState.errors.discountAmount?.message}*/}
      {/*      />*/}
      {/*    )}*/}
      {/*    <button*/}
      {/*      type={'button'}*/}
      {/*      className={'label text-blue-500'}*/}
      {/*      onClick={() => {*/}
      {/*        form.setValue(*/}
      {/*          'discountType',*/}
      {/*          form.watch('discountType') === DiscountType.RATE*/}
      {/*            ? DiscountType.PRICE*/}
      {/*            : DiscountType.RATE*/}
      {/*        );*/}
      {/*        form.setValue('discountAmount', 0);*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      전환 {'→'}{' '}*/}
      {/*      {form.watch('discountType') === DiscountType.RATE ? '₩' : '%'}*/}
      {/*    </button>*/}
      {/*  </div>*/}
      {/*  <TextField*/}
      {/*    label="할인율 적용시 할인가(실판매가)"*/}
      {/*    value={(form.watch('price') || 0).toLocaleString()}*/}
      {/*    disabled={true}*/}
      {/*  />*/}
      {/*  <TextField*/}
      {/*    label="분류값에서 랭킹"*/}
      {/*    defaultValue={0}*/}
      {/*    {...form.register('categoryRank', {*/}
      {/*      required: '랭킹 번호를 입력하세요',*/}
      {/*      setValueAs: (value) => Number(value),*/}
      {/*    })}*/}
      {/*    disabled={!hasIsbn}*/}
      {/*    helper={form.formState.errors.categoryRank?.message}*/}
      {/*  />*/}
      {/*  <TextField label="도서명" value={form.watch('name')} disabled={true} />*/}
      {/*  <TextField*/}
      {/*    label="저자"*/}
      {/*    value={form.watch('bookInfo.authorName')}*/}
      {/*    disabled={true}*/}
      {/*  />*/}
      {/*  <TextField*/}
      {/*    label="출판사"*/}
      {/*    value={form.watch('bookInfo.publisherName')}*/}
      {/*    disabled={true}*/}
      {/*  />*/}
      {/*  <Select*/}
      {/*    label={'대분류'}*/}
      {/*    defaultValue={''}*/}
      {/*    disabled={!hasIsbn}*/}
      {/*    onChange={(e) =>*/}
      {/*      setCategories({*/}
      {/*        ...categories,*/}
      {/*        selected:*/}
      {/*          categories.list.find(*/}
      {/*            (item) => item.id === Number(e.target.value)*/}
      {/*          ) || null,*/}
      {/*      })*/}
      {/*    }*/}
      {/*  >*/}
      {/*    <option value={''}>선택하세요</option>*/}
      {/*    {categories.list.map((category, index) => (*/}
      {/*      <option key={index} value={category.id}>*/}
      {/*        {category.name}*/}
      {/*      </option>*/}
      {/*    ))}*/}
      {/*  </Select>*/}
      {/*  <Select*/}
      {/*    label={'중분류'}*/}
      {/*    defaultValue={''}*/}
      {/*    disabled={!hasIsbn}*/}
      {/*    onChange={(e) =>*/}
      {/*      setSubCategories({*/}
      {/*        ...subCategories,*/}
      {/*        selected:*/}
      {/*          subCategories.list.find(*/}
      {/*            (item) => item.id === Number(e.target.value)*/}
      {/*          ) || null,*/}
      {/*      })*/}
      {/*    }*/}
      {/*  >*/}
      {/*    <option hidden={true} value={''}>*/}
      {/*      선택하세요*/}
      {/*    </option>*/}
      {/*    <option value={''}>전체</option>*/}
      {/*    {subCategories.list*/}
      {/*      .filter((item) => item.categoryId === categories.selected?.id)*/}
      {/*      .map((category, index) => (*/}
      {/*        <option key={index} value={category.id}>*/}
      {/*          {category.name}*/}
      {/*        </option>*/}
      {/*      ))}*/}
      {/*  </Select>*/}
      {/*  <TextField*/}
      {/*    label="발간일"*/}
      {/*    value={moment(form.watch('bookInfo.publishedAt')).format(*/}
      {/*      'YYYY.MM.DD'*/}
      {/*    )}*/}
      {/*    disabled={true}*/}
      {/*  />*/}
      {/*  <div className="col-span-3 ">*/}
      {/*    <TextArea*/}
      {/*      label="목차"*/}
      {/*      className="h-32"*/}
      {/*      value={form.watch('bookInfo.descTable')}*/}
      {/*      disabled={true}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*  <div className="col-span-3 ">*/}
      {/*    <TextArea*/}
      {/*      label="출판사서평"*/}
      {/*      className="h-32"*/}
      {/*      value={form.watch('bookInfo.descPub')}*/}
      {/*      disabled={true}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*  <div className="col-span-3 ">*/}
      {/*    <TextArea*/}
      {/*      label="상세설명"*/}
      {/*      className="h-32"*/}
      {/*      {...form.register('contentText')}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*  <div className="col-span-3 ">*/}
      {/*    <div className="col-span-3 mb-2 text-lg font-semibold">*/}
      {/*      상품 상세이미지*/}
      {/*    </div>*/}
      {/*    <FloalaEditor*/}
      {/*      imageType={FileAbout.PRODUCT_BOOK}*/}
      {/*      onChange={(content) => form.setValue('contentHtml', content)}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/*<div className="w-full py-10" />*/}

      {/*<div className="fixed bottom-0 z-10 -ml-8 w-full space-x-4 border-t bg-white py-2 pl-10">*/}
      {/*  <Button*/}
      {/*    text="취소"*/}
      {/*    className="outlined-black h-12 px-6"*/}
      {/*    onClick={() => history.goBack()}*/}
      {/*  />*/}
      {/*  <Button*/}
      {/*    text="저장"*/}
      {/*    className="filled-black h-12 px-6"*/}
      {/*    onClick={onsubmit}*/}
      {/*    disabled={form.watch('barcode') === ''}*/}
      {/*  />*/}
      {/*</div>*/}
    </>
  );
};
