import {api} from "../plugins/axios";
import {Paged} from "../types/common";
import {CreateTopMenuDto, TopMenuDto} from "../types/topMenu";

export const getMenus = (query: string, page?: number, itemsPerPage?: number) => {
    const params = {
        page: page || 1,
        itemsPerPage: itemsPerPage || 30,
    };
    return api.get<Paged<TopMenuDto>>('/top-menus?' + query, {params});
}

export const getMenu = (id: number) => {
    return api.get<TopMenuDto>(`/top-menus/${id}`);
}

export const createMenu = (data: CreateTopMenuDto) => {
    return api.post<TopMenuDto>('/top-menus', data);
}

export const updateMenu = (id: number, data: Partial<CreateTopMenuDto>) => {
    return api.patch<TopMenuDto>(`/top-menus/${id}`, data);
}

export const deleteMenu = (id: number) => {
    return api.delete<TopMenuDto>(`/top-menus/${id}`);
}