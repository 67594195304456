import {User} from "../types";
import {CompanyDto, ProductType} from "./product";
import {CouponDto, UsersCouponDto} from "./coupons.type";
import {ReviewDto} from "./review";

export interface Order {
    title: string;
    image: string
    code: string;
    userId: number;
    user: User;
    payment: Payment;
    shippingLines: ShippingLine[];
    id: number;
    createdAt: string;
    updatedAt: string;
    orderedAt: string;
    branchCode: string;
    usersCoupons: UsersCouponDto[];
    paidCoupons: CouponDto[];
    reviews: ReviewDto[];


}

export interface Payment {
    id: number;
    code?: string;
    orderId: number;
    receiptId: string;
    orderCode: string;
    method: PaymentMethod;
    totalProductPrice: number;
    totalDiscountAmount: number;
    shippingPrice: number;
    usedPointAmount: number;
    totalPayAmount: number;
    receivablePointAmount: number;
    requestedAt: string;
    confirmedAt?: string;
    createdAt: string;
    updatedAt: string;
}

export enum PaymentMethod {
    CREDIT_CARD = 'CREDIT_CARD',
    NAVER_PAY = 'NAVER_PAY',
    KAKAO_PAY = 'KAKAO_PAY',
    BANK_TRANSFER = 'BANK_TRANSFER',
}

export interface ShippingLine {
    orderId: number;
    orderCode: string;
    order: any;
    items: ShippingLineItem[];
    companyId: number;
    company: CompanyDto;
    shippingStatus: ShippingStatus;
    statusLogs: ShippingStatusChangeLog[];
    courier: string;
    waybillCode?: string;
    adminMemo?: string;
    addressId: number;
    receiverName: string;
    receiverTel: string;
    zipcode: string;
    address: string;
    addressDetail: string;
    publicDoorPassword: string;
    requestMemo: string;
    id: number;
    createdAt: string;
    updatedAt: string;
    finishedAt: string;
}

export enum ShippingStatus {
    INIT = 'INIT',
    READY = 'READY',
    IN_PROGRESS = 'IN_PROGRESS',
    FINISH = 'FINISH',
    CANCEL = 'CANCEL',
    CHANGE = 'CHANGE',
    RETURN = 'RETURN',
    REJECT = 'REJECT',
}

export interface ShippingLineItem {
    shippingLineId: number;
    line: {};
    productType: ProductType;
    productId: number;
    productName: string;
    barcode: string;
    productImages: string;
    unitPrice: number;
    quantity: number;
    totalPrice: number;
    id: number;
    createdAt: string;
    updatedAt: string;
}

export interface UpdateShippingLineDto {
    courier?: string;
    waybillCode?: string;
    adminMemo?: string;
    shippingStatus?: ShippingStatus;
}

export interface ShippingStatusChangeLog {
    after: ShippingStatus;
    before: ShippingStatus;
    changedAt: string;
    orderCode: string;
}
