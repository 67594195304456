import React, {Fragment} from "react";
import {Dialog, Transition} from "@headlessui/react";
import DaumPostcode from "react-daum-postcode";
import {Icon} from "../../components/Icon";

type PostcodeModalProps = {
    open: boolean;
    onClose: () => void;
    onComplete: (data: any) => void;
}

const PostcodeModal = (props: PostcodeModalProps) => {

    return (
        <>
            <Transition appear show={props.open} as={Fragment}>
                <Dialog as="div"
                        className="fixed inset-0 z-10 overflow-y-auto bg-gray-500 bg-opacity-75 transition-opacity"
                        onClose={() => null}
                >

                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div
                                className="my-8 inline-block w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <div className="flex items-center justify-between">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900 mb-5"
                                    >
                                        주소 검색
                                    </Dialog.Title>
                                    <Icon.X onClick={props.onClose} className="cursor-pointer"/>
                                </div>
                                <DaumPostcode onComplete={props.onComplete}/>
                            </div>
                        </Transition.Child>

                    </div>
                </Dialog>

            </Transition>
        </>
    )
}

export default PostcodeModal