import {api} from "../plugins/axios";
import {
    ArcnShopDto,
    CreateCurationProductDto,
    CurationProductDto,
    UpdateArcnShopDto,
    UpdateCurationProductDto
} from "../types/arcnShop";

export const getShop = () => {
    return api.get<ArcnShopDto>('/arc-n-shop')
}

export const updateShop = (data: UpdateArcnShopDto) => {
    return api.post<ArcnShopDto>('/arc-n-shop', data)
}

export const addShopProduct = (data: CreateCurationProductDto) => {
    return api.post<ArcnShopDto>('/arc-n-shop/products', data)
}

export const getCurationProduct = (id: number) => {
    return api.get<CurationProductDto>('/curation-products/' + id)
}

export const updateCurationProduct = (id: number, data: UpdateCurationProductDto) => {
    return api.patch<CurationProductDto>(`/curation-products/${id}`, data)
}

export const deleteCurationProduct = (id: number) => {
    return api.delete<void>(`/curation-products/${id}`)
}