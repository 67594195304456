import {PaymentMethod, ShippingStatus} from "../types/order";
import {ProductType} from "../types/product";

export const orderStatusTxt = (status: ShippingStatus) => {
    switch (status) {
        case ShippingStatus.INIT:
            return '결제완료';
        case ShippingStatus.READY:
            return '배송준비중';
        case ShippingStatus.IN_PROGRESS:
            return '배송중';
        case ShippingStatus.FINISH:
            return '배송완료';
        case ShippingStatus.CHANGE:
            return '교환';
        case ShippingStatus.RETURN:
            return '반품';
        case ShippingStatus.CANCEL:
            return '주문취소';
    }
}

export const productTypeTxt = (type: ProductType) => {
    switch (type) {
        case ProductType.GENERAL:
            return '일반';
        case ProductType.BOOK:
            return '도서';
    }
}

export const paymentTypeTxt = (type: PaymentMethod) => {
    switch (type) {
        case PaymentMethod.CREDIT_CARD:
            return '신용카드';
        case PaymentMethod.NAVER_PAY:
            return '네이버페이';
        case PaymentMethod.KAKAO_PAY:
            return '카카오페이';
        case PaymentMethod.BANK_TRANSFER:
            return '계좌이체';
    }
}

export const getUserGradeText = (level: string) => {
  switch (level) {
    case 'PEOPLE':
      return '아크앤피플';
    case 'FRIEND':
      return '아크앤프렌즈';
    case 'FAMILY':
      return '아크앤패밀리';
    default:
      return '아크앤피플';
  }
}
