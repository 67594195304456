import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { Pagination } from '../../../components/Pagination';
import { Search } from '../../../components/Search';
import { Table } from '../../../components/Table';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';
import { AdminH1 } from '../../components/AdminH1';
import { Paged } from '../../../types/common';
import { CompanyDto } from '../../../types/product';
import { getCompanies } from '../../../api/companyAPI';
import queryString from 'query-string';

// TODO: 업체 삭제 시 상품이 있을 경우 제거가 불가능 함 - 7/23
//    - 업체 삭제 시 상품이 있을 경우 상품은 비노출로 변경 되고 업체 삭제 가능하도록 api 수정후 확인 예정
export const NormalProductBrandList = () => {
  const history = useHistory();
  const { page, limit, search } = queryString.parse(history.location.search);
  const [companies, setCompanies] = React.useState<Paged<CompanyDto>>({
    items: [],
  });

  const calculatePaginatedItems = (items:  CompanyDto[], totalItemCount: number) => {
    const currentPage = Number(page) || 1;
    const itemsPerPage = Number(limit) || 30;
    const startIndex = (currentPage - 1) * itemsPerPage;
    return items.map((item, index) => ({
      ...item,
      paginatedIndex: totalItemCount - (startIndex + index),
    }));
  };

  const getList = () => {
    let query = ``;
    if (search) query += `name=${search}`;
    query += `&order[id]=DESC`;
    getCompanies(query, Number(page), Number(limit))
      .then((res) => {
        const paginatedItems = calculatePaginatedItems(res.data.items, res.data.pagination?.totalItemCount || 0);
        setCompanies({ ...res.data, items: paginatedItems });
      })
      .catch((err) => console.error(err.response));
  };

  useEffect(() => getList(), [history.location.search]);

  return (
    <>
      <AdminH1>일반 상품 업체 관리</AdminH1>
      <div className="flex items-center justify-between">
        <Button
          text="업체 추가"
          className="filled-black h-12"
          to={`${history.location.pathname}/add`}
        />
        <Search
          className="h-12"
          onSearch={(keyword) => history.push(`?search=${keyword}`)}
        />
      </div>

      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>ID</Table.Th>
              <Table.Th>등록일자</Table.Th>
              <Table.Th>업체명</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {companies.items.length === 0 && (
              <Table.Row>
                <Table.Td colSpan={4}>데이터가 없습니다.</Table.Td>
              </Table.Row>
            )}
            {companies.items.map((brand, index) => (
              <Table.Row key={index}>
                <Table.Td>{brand.paginatedIndex}</Table.Td>
                <Table.Td>
                  {utcToLocalFormat(
                    brand.createdAt!.toLocaleString(),
                    MomentFormat.YYYYMMDDHmm
                  )}
                </Table.Td>
                <Table.Td>{brand.name}</Table.Td>
                <Table.Td className="text-right">
                  <div className={'flex justify-end gap-5'}>
                    <Link
                      className="rounded-md font-normal text-indigo-600 hover:text-indigo-900"
                      to={`${history.location.pathname}/${brand.id}`}
                    >
                      상세보기
                    </Link>
                  </div>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination>
          <Pagination.Label
            count={companies.items.length}
            total={companies.pagination?.totalItemCount!}
          />
          <Pagination.Nav
            basePath={history.location.pathname}
            total={companies.pagination?.totalItemCount!}
          />
        </Pagination>
      </Card>
    </>
  );
};
