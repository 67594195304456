import React, {useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Card} from '../../../components/Card';
import {Pagination} from '../../../components/Pagination';
import {Table} from '../../../components/Table';
import {AdminH1} from '../../components/AdminH1';
import {MomentFormat, utcToLocalFormat} from '../../../plugins/moment';
import {Search} from '../../../components/Search';
import {Button} from '../../../components/Button';
import {downloadAllUserExcel, downloadUserExcel, getUsers} from "../../../api/userAPI";
import {Paged} from "../../../types/common";
import {UserManageItemDto} from "../../../types/user";
import queryString from "query-string";
import {useLoading} from "../../../hooks/useLoading";
import {Select} from "../../../components/Select";
import {UserGradeSelector} from "../../components/UserGradeSelector";

export const UserList = () => {
    const history = useHistory();
    const [users, setUsers] = React.useState<Paged<UserManageItemDto>>({items: []});
    const [userDate, setUserDate] = React.useState<{ from: string, to: string }>({
        from: '2020-01-01',
        to: '2099-12-31'
    });
    const query = queryString.parse(history.location.search);
    const {setIsLoading, LoadingView} = useLoading();

    const getList = () => {
        let nQuery = ``;
        if (query.find) nQuery += `&${query.searchType || 'name'}=${query.find}`;
        if (query.from) nQuery += `&where[createdAt][from]=${query.from}&where[createdAt][to]=${query.to}`;
        nQuery += `&order[id]=DESC`;
        getUsers(nQuery, Number(query.page), Number(query.limit))
            .then(res => setUsers(res.data))
            .catch(e => console.error(e.response))
            .finally(() => setIsLoading(false))
    }

    const excelDownload = () => {
        const userList = users.items.map(item => item.id);
        downloadUserExcel(userList);
    }

    const excelAllDownload = () => {
        const confirm = window.confirm('회원 수가 너무 많으면 서버에 부하가 걸릴 수 있어요. 전체 회원의 정보를 다운로드 하시겠어요?');
        if (confirm) {
            downloadAllUserExcel();
        }
    }

    useEffect(() => {
        getList();
    }, [history.location.search]);

    return (
        <>
            <LoadingView/>
            <AdminH1>회원관리</AdminH1>
            <div className={'flex'}>
                {/*<div className={'mr-6'}>*/}
                {/*    <UserGradeSelector selected={'all'} onSelect={() => {}}/>*/}
                {/*</div>*/}
                <div className={'space-y-4 pb-20'} style={{ width: '100%' }}>
                    <div className="flex justify-end">
                        <Select className={'h-12 text-sm mr-4'}
                                value={query.searchType?.toString() || 'code'}
                                onChange={(e) => {
                                    query.searchType = e.target.value;
                                    history.push(`?${queryString.stringify(query)}`);
                                }}
                        >
                            <option value="name">이름</option>
                            <option value="email">이메일</option>
                            <option value="phone">전화번호</option>
                        </Select>
                        <Search className="w-52"
                                defaultValue={query.find?.toString()}
                                onSearch={(keyword) => {
                                    query.find = keyword;
                                    history.push(`?${queryString.stringify(query)}`);
                                }}/>
                    </div>
                    {/*<div className="flex justify-end">*/}
                    {/*    <Search defaultValue={query.name?.toString()}*/}
                    {/*            onSearch={keyword => {*/}
                    {/*                query.name = keyword;*/}
                    {/*                history.push(`?${queryString.stringify(query)}`);*/}
                    {/*            }}/>*/}
                    {/*</div>*/}
                    <div className="flex items-center justify-between space-x-2">
                        <div>
                            <Button
                                text="회원추가"
                                className="filled-black h-12"
                                to="/admin/users/add"
                            />
                            <Button
                                text="현재 페이지 엑셀 다운로드"
                                className="filled-black h-12 ml-4"
                                onClick={excelDownload}
                            />
                            <Button
                                text="전체 회원 엑셀 다운로드"
                                className="filled-black h-12 ml-4"
                                onClick={excelAllDownload}
                            />
                        </div>
                        <div className="flex items-center space-x-2 rounded-lg bg-gray-50 pl-4">
                            <input
                                type="date"
                                className="rounded-md border-none bg-gray-50 text-gray-600 focus:ring-gray-500"
                                defaultValue={query.from?.slice(0, 10).toString() || undefined}
                                onChange={e => setUserDate({...userDate, from: e.target.value + ' 00:00:00'})}
                            />
                            <p className="font-semibold">부터</p>
                            <input
                                type="date"
                                className="rounded-md border-none bg-gray-50 text-gray-600 focus:ring-gray-500"
                                defaultValue={query.to?.slice(0, 10).toString() || undefined}
                                onChange={e => setUserDate({...userDate, to: e.target.value + ' 23:59:59'})}
                            />
                            <Button text="검색" className="filled-black h-12 px-5"
                                    onClick={() => {
                                        query.from = userDate.from;
                                        query.to = userDate.to;
                                        history.push(`?${queryString.stringify(query)}`);
                                    }}/>
                        </div>
                    </div>

                    <Card className={'min-w-[800px]'}>
                        <Table>
                            <Table.Head>
                                <Table.Row>
                                    <Table.Th>ID</Table.Th>
                                    <Table.Th>가입일자</Table.Th>
                                    <Table.Th>회원명</Table.Th>
                                    <Table.Th>핸드폰번호</Table.Th>
                                    <Table.Th>아이디</Table.Th>
                                    <Table.Th></Table.Th>
                                </Table.Row>
                            </Table.Head>
                            <Table.Body>
                                {users.items.length === 0 && (
                                    <Table.Row>
                                        <Table.Td>데이터가 없습니다.</Table.Td>
                                    </Table.Row>
                                )}
                                {users.items.map((user) => (
                                    <Table.Row key={user.id}>
                                        <Table.Td>{user.id}</Table.Td>
                                        <Table.Td>
                                            {utcToLocalFormat(
                                                user.createdAt.toLocaleString(),
                                                MomentFormat.YYYYMMDDHmm
                                            )}
                                        </Table.Td>
                                        <Table.Td>{user.name}</Table.Td>
                                        <Table.Td>
                                            {user.phone
                                                .toString()
                                                .replace(
                                                    /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
                                                    '$1-$2-$3'
                                                )}
                                        </Table.Td>
                                        <Table.Td>{user.email}</Table.Td>
                                        <Table.Td className="text-right">
                                            <Link
                                                className="rounded-md font-normal text-indigo-600 hover:text-indigo-900"
                                                to={`/admin/users/${user.id}`}
                                            >
                                                상세보기
                                            </Link>
                                        </Table.Td>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>

                        <Pagination>
                            <Pagination.Label count={users.items.length} total={users.pagination?.totalItemCount!}/>
                            <Pagination.Nav basePath={history.location.pathname}
                                            total={users.pagination?.totalItemCount!}/>
                        </Pagination>
                    </Card>
                </div>
            </div>
        </>
    );
};
