import {api} from "../plugins/axios";
import { Paged } from "../types/common";
import {CreateUserManageDto, UserManageDetailDto, UserManageItemDto} from "../types/user";

export const getUsers = (query: string, page?: number, itemsPerPage?: number) => {
    const params = {
        page: page || 1,
        itemsPerPage: itemsPerPage || 30,
    };
    return api.get<Paged<UserManageItemDto>>("/users?" + query, {params});
}

export const postUser = (user: CreateUserManageDto) => {
    return api.post<UserManageDetailDto>("/users", user);
}

export const getUser = (id: number) => {
    return api.get<UserManageDetailDto>(`/users/${id}`);
}

export const patchUser = (id: number, user: Partial<CreateUserManageDto>) => {
    return api.patch<UserManageDetailDto>(`/users/${id}`, user);
}

export const deleteUser = (id: number) => {
    return api.delete<void>(`/users/${id}`);
}

export const downloadUserExcel = (ids: number[]) => {
    return window.open(`${process.env.REACT_APP_API_URL}/users/excel/${ids}`);
}

export const downloadAllUserExcel = () => {
    return window.open(`${process.env.REACT_APP_API_URL}/users/excel/all`);
}
