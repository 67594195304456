import { UserDto } from "./user";

export type CouponDto = {
    id: number;
    title: string;
    description: string;
    code: string;
    kind: string;
    allowedProductTypes: string[];
    discountType: string;
    discountAmount: number;
    expireAt: string;
    expireType: string;
    expireDayCount: number;
    isAbleToUseWithOtherCoupons: boolean;
    activationLimitPrice: number;
    maximumDiscountPrice: number;
    createdAt: string;
    updatedAt: string;
    // usersCoupons: UsersCouponDto[];
    users: UserDto[];
}


export type UsersCouponDto = {
    id: number;
    userId: number;
    couponId: number;
    orderId: number;
    orderCode: string;
    isUsed: boolean;
    expiredAt: string;
    expireReason: ExpireReason;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
    user: UserDto;
    coupon: CouponDto;
    order: any;
} & CouponDto

export enum ExpireReason {
    ALIVE= 'ALIVE',
    OVERDUE = 'OVERDUE',
    LV_UPDATE = 'LV_UPDATE',
    USED = 'USED',
    ADMIN_SET = 'ADMIN_SET',
    ETC = 'ETC',
}
