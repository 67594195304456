import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';
import { Button } from '../../components/Button';
import { Icon } from '../../components/Icon';
import { TextArea } from '../../components/TextArea';
import { PartnershipForm, getInquiryType } from '../../types/partnershipForm';
import {
  getPartnershipForm,
  patchPartnershipForm,
} from '../../api/partnershipFormAPI';
import { toast } from 'react-toastify';

interface PartnershipFormModalProps {
  open: boolean;
  onClose: () => void;
  partnershipFormId?: number;
}

export const PartnershipFormModal = (args: PartnershipFormModalProps) => {
  const [partnershipForm, setPartnershipForm] = useState<PartnershipForm>(
    {} as PartnershipForm
  );

  useEffect(() => {
    if (!args.partnershipFormId) return;

    getPartnershipForm(args.partnershipFormId)
      .then((res) => {
        setPartnershipForm({
          ...res.data,
          adminMemo: res.data.adminMemo || '',
        });
      })
      .catch((err) => console.error(err.response));
  }, [args.partnershipFormId]);

  const update = () => {
    patchPartnershipForm(args.partnershipFormId!, {
      adminMemo: partnershipForm.adminMemo,
    }).then(() => {
      toast.success('저장되었습니다.');
      args.onClose();
    });
  };

  if (!args.open) return <></>;
  return (
    <>
      <Transition appear show={args.open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto bg-gray-500 bg-opacity-75 transition-opacity"
          onClose={args.onClose}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="my-8 inline-block w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex items-center justify-between">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    제휴 문의 상세보기
                  </Dialog.Title>
                  <Icon.X onClick={args.onClose} className="cursor-pointer" />
                </div>
                <div className="mb-2 grid grid-cols-2">
                  <LabelWithText
                    text={getInquiryType(partnershipForm)}
                    label={'제휴 문의 선택'}
                    className="col-span-2"
                  />
                  <LabelWithText text={partnershipForm.name} label={'이름'} />
                  <LabelWithText
                    text={partnershipForm.email}
                    label={'이메일'}
                  />
                  <LabelWithText
                    text={partnershipForm.phoneNumber}
                    label={'전화번호'}
                  />
                  <LabelWithText
                    text={partnershipForm.companyName}
                    label={'업체명'}
                  />
                </div>
                <div className="space-y-4">
                  <TextArea
                    value={partnershipForm.contents}
                    label={'문의 내용'}
                    disabled
                    className="col-span-2 h-36 w-full"
                  />
                  <TextArea
                    className="h-36 resize-none focus:border-black"
                    placeholder="관리자 메모"
                    label={'관리자 메모'}
                    value={partnershipForm.adminMemo}
                    onChange={(e) =>
                      setPartnershipForm({
                        ...partnershipForm,
                        adminMemo: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="mt-10 space-y-2">
                  <Button
                    text="저장하기"
                    className="filled-black h-12 w-full text-sm"
                    onClick={update}
                  />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

const LabelWithText = (args: {
  label: string;
  text?: string;
  className?: string;
}) => {
  const { className, label, text } = args;
  return (
    <div className={`mt-2.5 ${className}`}>
      <p className="text-14 text-gray-600 ">{label}</p>
      <p className="mt-2 text-18 text-gray-900">{text || '-'}</p>
    </div>
  );
};
