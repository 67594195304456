import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { Checkbox } from '../../../components/Checkbox';
import { Pagination } from '../../../components/Pagination';
import { Search } from '../../../components/Search';
import { Table } from '../../../components/Table';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';
import { ProductDto, ProductType } from '../../../types/product';
import CompanySelector from '../../components/CompanySelector';
import queryString from 'query-string';
import ProductTabBar from '../../components/ProductTabBar';
import { productStatus } from '../../../utils/productStatus';
import { useLoading } from '../../../hooks/useLoading';
import { toast } from 'react-toastify';
import { CategoryLeftBar } from '../../components/CategoryLeftBar';
import { TabType } from '../../enum';
import useGetProducts from '../../../hooks/useGetProducts';
import {
  useCopyGeneralProduct,
  useUpdateGeneralProduct,
} from '../../../hooks/useUpdateProduct';

export const NormalProductList = () => {
  const history = useHistory();
  const query = queryString.parse(history.location.search);
  const { setIsLoading, LoadingView } = useLoading();
  const { data, isLoading } = useGetProducts(ProductType.GENERAL);
  const copyNormalProduct = useCopyGeneralProduct();

  const updateNormalProduct = useUpdateGeneralProduct({
    type: ProductType.GENERAL,
    onSuccess: () => {
      toast('상품 노출 여부가 업데이트 되었습니다.', {
        type: 'success',
      });
    },
    onError: () => {
      toast('상품 노출 여부 업데이트 도중 오류가 발생하였습니다.', {
        type: 'error',
      });
    },
  });

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  const updateProductInfo = (id: number, checked: boolean) => {
    updateNormalProduct(id, { isDisplayHidden: checked });
  };

  const onDuplicate = (item: ProductDto) => {
    const count = prompt('상품을 복제하시겠습니까? 개수를 입력해주세요.');
    if (count && /^\d+$/.test(count.toString())) {
      copyNormalProduct({
        id: item.id,
        count: Number(count),
      });
    } else {
      alert('올바른 값을 입력해주세요.');
    }
  };

  return (
    <>
      <div className="relative mt-5 h-full w-full">
        <LoadingView />
        <div className={'flex w-full gap-10'}>
          <div className={'sticky top-0 h-full'}>
            <CategoryLeftBar
              tabId={TabType.LIFE}
              isShowUndefinedCategory={true}
            />
          </div>
          <div className={'w-full space-y-5'}>
            <CompanySelector
              selectedCompanyId={Number(query.company)}
              onChange={(company) => {
                query.company = company?.id.toString() as string;
                history.push(`?${queryString.stringify(query)}`);
              }}
            />

            <ProductTabBar products={data} />

            <div className="flex items-center justify-between">
              <Button
                text="상품추가"
                className="filled-black h-10 text-sm"
                to="/admin/normal/product/add"
              />
              <Search
                className="h-10"
                onSearch={(keyword) => {
                  query.name = keyword;
                  history.push(`?${queryString.stringify(query)}`);
                }}
              />
            </div>

            <Card>
              <Table>
                <Table.Head>
                  <Table.Row>
                    <Table.Th>노출여부</Table.Th>
                    <Table.Th>최초 등록일자</Table.Th>
                    <Table.Th>업체명</Table.Th>
                    <Table.Th>상품명</Table.Th>
                    <Table.Th>실판매가</Table.Th>
                    <Table.Th>판매상태</Table.Th>
                    <Table.Th></Table.Th>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {!data ? (
                    <Table.Row>
                      <Table.Td>상품이 없습니다.</Table.Td>
                    </Table.Row>
                  ) : (
                    data.items.map((product) => (
                      <Table.Row key={product.id}>
                        <Table.Td>
                          <Checkbox
                            checked={!product.isDisplayHidden}
                            onChange={() =>
                              updateProductInfo(
                                product.id,
                                !product.isDisplayHidden
                              )
                            }
                          />
                        </Table.Td>
                        <Table.Td>
                          {utcToLocalFormat(
                            product.createdAt.toLocaleString(),
                            MomentFormat.YYYYMMDDHmm
                          )}
                        </Table.Td>
                        <Table.Td>
                          {product.company ? product.company.name : ''}
                        </Table.Td>
                        <Table.Td>{product.name}</Table.Td>
                        <Table.Td>
                          {product.price
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          원
                        </Table.Td>
                        <Table.Td>{productStatus(product)}</Table.Td>
                        <Table.Td className="text-right">
                          <Button onClick={() => onDuplicate(product)}>
                            복제하기
                          </Button>
                          <Link
                            className="rounded-md font-normal text-indigo-600 hover:text-indigo-900"
                            to={`/admin/normal/product/${product.id}`}
                          >
                            상세보기
                          </Link>
                        </Table.Td>
                      </Table.Row>
                    ))
                  )}
                </Table.Body>
              </Table>

              {data && (
                <Pagination>
                  <Pagination.Label
                    count={data.items.length}
                    total={data.pagination?.totalItemCount!}
                  />
                  <Pagination.Nav
                    basePath={history.location.pathname}
                    total={data.pagination?.totalItemCount!}
                  />
                </Pagination>
              )}
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};
