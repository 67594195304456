import React from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { Tab } from '../../components/Tab';
import { ReturnAllOrderPage } from './return/ReturnAllOrderPage';
import { ReturnBooksOrderPage } from './return/ReturnBooksOrderPage';
import { ReturnProductsOrderPage } from './return/ReturnProductsOrderPage';

export const ReturnOrderMain = () => {
  const { pathname } = useLocation();
  const { push } = useHistory();
  return (
    <>
      <div className="flex space-x-4 border-b">
        <Tab
          text="전체"
          selected={pathname === '/admin/return/order/all'}
          onClick={() => push('/admin/return/order/all')}
        />
        <Tab
          text="도서"
          selected={pathname === '/admin/return/order/books'}
          onClick={() => push('/admin/return/order/books')}
        />
        <Tab
          text="일반상품"
          selected={pathname === '/admin/return/order/products'}
          onClick={() => push('/admin/return/order/products')}
        />
      </div>
      <Switch>
        <Route component={ReturnAllOrderPage} path="/admin/return/order/all" />
        <Route
          component={ReturnProductsOrderPage}
          path="/admin/return/order/products"
        />
        <Route
          component={ReturnBooksOrderPage}
          path="/admin/return/order/books"
        />
        <Route path="/admin/return/order">
          <Redirect to="/admin/return/order/all" />
        </Route>
      </Switch>
    </>
  );
};
