import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {TextArea} from '../../../components/TextArea';
import {TextField} from '../../../components/TextField';
import {Button} from '../../../components/Button';
import {PostProductBModal} from '../../components/modal/PostProductBModal';
import {useForm} from "react-hook-form";
import {CreatePostingDto, PostingType} from "../../../types/posting";
import {postPosting} from "../../../api/postingAPI";
import {toast} from "react-toastify";

export const BtypePostAdd = () => {
    const {goBack, push} = useHistory();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const form = useForm<CreatePostingDto>();

    const onSubmit = (data: CreatePostingDto) => {
        data.type = PostingType.B;
        data.products = [];
        postPosting(data)
            .then(res => {
                toast.success('저장되었습니다. 상품을 추가해 주세요.');
                push(`/admin/post/${res.data.type.toLowerCase()}/${res.data.id}`);
            })
            .catch(err => console.error(err.response));
    };

    return (
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <PostProductBModal open={isOpen} onClose={() => setIsOpen(false)}/>
            <div className="grid grid-cols-3 gap-5">
                <TextField label="타이틀" {...form.register('title', {required: true})}/>
                {/*<TextField label="url" disabled/>*/}
                <div className="col-span-3 col-start-1">
                    <TextArea label="상세설명" className="h-32" {...form.register('description', {required: true})}/>
                </div>
            </div>

            <div className="fixed bottom-0 -ml-8 w-full space-x-4 border-t bg-white py-2 pl-10">
                <Button type={'button'}
                        text="취소"
                        className="outlined-black h-12 px-6"
                        onClick={() => goBack()}
                />
                <Button type={'submit'} text="저장" className="filled-black h-12 px-6"/>
            </div>

            <div className="p-14"/>
        </form>
    );
};
