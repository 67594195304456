import React, {useEffect, useState} from 'react';
import {TextArea} from '../../../components/TextArea';
import {TextField} from '../../../components/TextField';
import {ReactComponent as FileUploadImg} from '../../../assets/svg/file-upload.svg';
import {Button} from '../../../components/Button';
import {PostProductAModal} from '../../components/modal/PostProductAModal';
import {useHistory, useParams} from "react-router-dom";
import {addPostingProduct, deletePosting, getPosting, patchPosting} from "../../../api/postingAPI";
import {PostingDto} from "../../../types/posting";
import {ProductList} from "../../components/ProductList";
import {uploadFile} from "../../../api/fileAPI";
import {FileAbout} from "../../../types/common";
import {toast} from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
import {InPageTab} from "../../components/InPageTab";

export const AtypePostShow = () => {
    const {id} = useParams<{ id: string }>();
    const numId = Number(id);
    const history = useHistory();
    const [pcBanner, setPcBanner] = useState<File | null | undefined>();
    const [mobileBanner, setMobileBanner] = useState<File | null | undefined>();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [posting, setPosting] = useState<PostingDto>({} as PostingDto);

    const getThis = () => {
        getPosting(numId).then(res => setPosting(res.data)).catch(err => console.error(err.response));
    }

    const saveThis = async () => {
        patchPosting(numId, {
            title: posting.title,
            description: posting.description,
            pcImageUrl: pcBanner ? await uploadFile(FileAbout.BANNER_PC, pcBanner) : undefined,
            mobileImageUrl: mobileBanner ? await uploadFile(FileAbout.BANNER_MOBILE, mobileBanner) : undefined,
        }).then(() => {
            toast.success('저장되었습니다.');
            history.push('/admin/post');
        }).catch(err => toast.error(err.response.data.message));
    }

    const removeThis = () => {
        const confirm = window.confirm("정말로 게시글을 삭제하시겠습니까?");
        if (confirm) {
            deletePosting(numId)
                .then(() => {
                    toast.success("삭제되었습니다.");
                    history.push('/admin/post');
                })
                .catch(err => console.error(err.response));
        }
    }

    const addProduct = (productId: number, order: number) => {
        addPostingProduct(numId, {productId, order}).then(() => getThis()).catch(err => console.error(err.response));
    }

    useEffect(() => getThis(), [])

    return (
        <div>
            <PostProductAModal open={isOpen} onClose={() => setIsOpen(false)}/>
            <InPageTab tabs={[
                {name: '게시글 정보', selected: !history.location.pathname.includes('comments'), onClick: () => history.push(`/admin/post/a/${id}`)},
                {name: '코멘트 정보', selected: history.location.pathname.includes('comments'), onClick: () => history.push(`/admin/post/a/${id}/comments`)},
            ]}/>
            <div className="grid grid-cols-3 gap-5">
                <TextField label="타이틀"
                           defaultValue={posting?.title}
                           onChange={e => setPosting({...posting, title: e.target.value})}/>
                <TextField label="url" value={`${process.env.REACT_APP_FRONT_URL}/lists/${posting.id}`} disabled/>
                <CopyToClipboard text={`${process.env.REACT_APP_FRONT_URL}/lists/${posting.id}`}
                                 onCopy={() => toast.info('복사되었습니다.')}>
                    <Button text="복사" className="filled-black h-12 px-6 w-20 mt-7"/>
                </CopyToClipboard>
                <div className="col-span-3 col-start-1">
                    <TextArea label="상세설명" className="h-32"
                              defaultValue={posting?.description}
                              onChange={e => setPosting({...posting, description: e.target.value})}/>
                </div>
                <div>
                    <label htmlFor="pcBanner">
                        <div className="mb-2 text-sm text-gray-800 ">
                            PC 상단배너 이미지
                        </div>

                        {pcBanner || posting.pcImageUrl ? (
                            <>
                                <img src={pcBanner ? URL.createObjectURL(pcBanner) : posting.pcImageUrl} alt=""/>
                                <div className="mt-4 w-full">
                                    <Button className="filled-black h-12 py-3">
                                        <label htmlFor="pcBanner" className="w-full cursor-pointer">
                                            수정하기
                                        </label>
                                    </Button>
                                </div>
                            </>
                        ) : !pcBanner ? (
                            <div
                                className="border-grey-5 flex w-full flex-col items-center justify-center space-y-1 border-2 border-dashed py-40">
                                <FileUploadImg/>
                                <div className="w-3/5 text-center text-xs text-gray-500">
                                    상세 이미지 첨부하기
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </label>
                    <input
                        type="file"
                        id="pcBanner"
                        className="hidden"
                        accept="image/*"
                        onChange={(e) => {
                            e.target.validity.valid && setPcBanner(e.target.files?.item(0));
                        }}
                    />
                </div>

                <div>
                    <label htmlFor="mobileBanner">
                        <div className="mb-2 text-sm text-gray-800 ">
                            Mobile 상단배너 이미지
                        </div>

                        {mobileBanner || posting.mobileImageUrl ? (
                            <>
                                <img src={mobileBanner ? URL.createObjectURL(mobileBanner) : posting.mobileImageUrl}
                                     alt=""/>
                                <div className="mt-4 w-full">
                                    <Button className="filled-black h-12 py-3">
                                        <label
                                            htmlFor="mobileBanner"
                                            className="w-full cursor-pointer"
                                        >
                                            수정하기
                                        </label>
                                    </Button>
                                </div>
                            </>
                        ) : !mobileBanner ? (
                            <div
                                className="border-grey-5 flex w-full flex-col items-center justify-center space-y-1 border-2 border-dashed py-40">
                                <FileUploadImg/>
                                <div className="w-3/5 text-center text-xs text-gray-500">
                                    상세 이미지 첨부하기
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </label>
                    <input
                        type="file"
                        id="mobileBanner"
                        className="hidden"
                        accept="image/*"
                        onChange={(e) => {
                            e.target.validity.valid &&
                            setMobileBanner(e.target.files?.item(0));
                        }}
                    />
                </div>
            </div>

            <div className="my-10 h-3 w-full bg-gray-50"/>

            <ProductList products={posting.products || []} onModalClose={getThis} onAddProduct={addProduct} isHasOrder={true}/>

            <div className="fixed bottom-0 -ml-8 w-full space-x-4 border-t bg-white py-2 pl-10">
                <Button text="삭제" onClick={removeThis} className="outlined-black h-12 px-6"/>
                <Button text="저장" onClick={saveThis} className="filled-black h-12 px-6"/>
            </div>

            <div className="pt-14"/>
        </div>
    );
};
