import {Link, useHistory} from "react-router-dom";
import queryString from "query-string";
import React, {useEffect} from "react";
import {Paged} from "../../types/common";
import {AdminH1} from "../../admin/components/AdminH1";
import {Button} from "../../components/Button";
import {Card} from "../../components/Card";
import {Table} from "../../components/Table";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {toast} from "react-toastify";
import {Pagination} from "../../components/Pagination";
import {getMenus, updateMenu} from "../../api/menuAPI";
import {TopMenuDto} from "../../types/topMenu";
import Reorderable from "../../admin/components/Reorderable";

const MenuList = () => {
    const history = useHistory();
    const {page, limit} = queryString.parse(history.location.search);
    const [menus, setMenus] = React.useState<Paged<TopMenuDto>>({items: []});

    const getList = () => {
        getMenus(`order[order]=ASC`, Number(page), Number(limit))
            .then(res => setMenus(res.data))
            .catch(err => console.error(err.response));
    }

    const onReorder = (id: number, order: number) => {
        updateMenu(id, {order}).then(() => {
            toast.success('순서가 변경되었습니다');
            getList();
        })
    }

    useEffect(() => {
        getList();
    }, [])

    return (
        <>
            <AdminH1>상단 메뉴 관리</AdminH1>
            <div>
                <Button
                    text="상단 메뉴 추가"
                    className="filled-black h-12"
                    to="/admin/menu/add"
                />
            </div>

            <Card>
                <Table>
                    <Table.Head>
                        <Table.Row>
                            <Table.Th>노출 우선순위</Table.Th>
                            <Table.Th>메뉴명</Table.Th>
                            <Table.Th>클릭시 URL</Table.Th>
                            <Table.Th></Table.Th>
                        </Table.Row>
                    </Table.Head>
                    <Table.Body>
                        {menus.items.length === 0 && (
                            <Table.Row>
                                <Table.Td colSpan={4}>데이터가 없습니다.</Table.Td>
                            </Table.Row>
                        )}
                        {menus.items.map((category, index) => (
                            <Table.Row key={index}>
                                <Table.Td>
                                    <Reorderable order={category.order}
                                                 onReordered={(order) => onReorder(category.id, order)}/>
                                </Table.Td>
                                <Table.Td>{category.name}</Table.Td>
                                <Table.Td>
                                    <CopyToClipboard text={category.url} onCopy={() => toast.info('복사되었습니다.')}>
                                        <span className={'text-blue-500 underline cursor-pointer'}>{category.url}</span>
                                    </CopyToClipboard>
                                </Table.Td>
                                <Table.Td className="text-right">
                                    <Link
                                        className="rounded-md font-normal text-indigo-600 hover:text-indigo-900"
                                        to={`/admin/menu/${category.id}`}
                                    >
                                        상세보기
                                    </Link>
                                </Table.Td>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>

                <Pagination>
                    <Pagination.Label count={menus.items.length}
                                      total={menus.pagination?.totalItemCount!}/>
                    <Pagination.Nav basePath={history.location.pathname}
                                    total={menus.pagination?.totalItemCount!}/>
                </Pagination>
            </Card>
        </>
    );
}

export default MenuList;