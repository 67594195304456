import React from 'react';
import { AdminH1 } from '../../components/AdminH1';
import { PartnershipFormListTable } from '../../components/PartnershipFormListTable';

export const PartnershipFormPage = () => {
  return (
    <div className="space-y-5">
      <AdminH1>제휴 요청 관리</AdminH1>
      <PartnershipFormListTable />
    </div>
  );
};
