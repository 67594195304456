import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Icon } from '../../../components/Icon';
import { Button } from '../../../components/Button';
import { ShippingStatus } from '../../../types/order';

type ChangeBulkConfirmModalProps = {
  shippingStatus: ShippingStatus | null;
  onClose: () => void;
  selected: number[];
  onConfirm: () => void;
};

const ShippingStatusObject = {
  [ShippingStatus.INIT]: '배송시작',
  [ShippingStatus.READY]: '배송준비중',
  [ShippingStatus.IN_PROGRESS]: '배송중',
  [ShippingStatus.FINISH]: '배송완료',
  [ShippingStatus.CANCEL]: '취소',
  [ShippingStatus.CHANGE]: '교환',
  [ShippingStatus.RETURN]: '반품',
  [ShippingStatus.REJECT]: '리젝',
};

const ChangeBulkConfirmModal = (props: ChangeBulkConfirmModalProps) => {
  if (!props.shippingStatus) return <></>;
  return (
    <Transition appear show={!!props.shippingStatus} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto bg-gray-500 bg-opacity-75 transition-opacity"
        onClose={props.onClose}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="my-8 inline-block w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              <div className="mb-5 flex items-center justify-between">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  주문 상태 수동 변경
                </Dialog.Title>
                <Icon.X onClick={props.onClose} className="cursor-pointer" />
              </div>
              <div>
                <p>
                  총 {props.selected.length}개의 주문을{' '}
                  <span className={'font-bold'}>
                    "{ShippingStatusObject[props.shippingStatus]}"
                  </span>{' '}
                  상태로 변경하시겠어요?
                </p>
              </div>
              <div className="mt-4">
                <Button
                  onClick={props.onConfirm}
                  text="변경"
                  className="filled-black h-12 text-sm"
                />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ChangeBulkConfirmModal;
