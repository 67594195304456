import {Dialog, Transition} from '@headlessui/react';
import {Fragment, useState} from 'react';
import {Button} from '../../../components/Button';
import {Icon} from '../../../components/Icon';
import {TextField} from '../../../components/TextField';
import {postMileageHistory} from "../../../api/mileageHistoryAPI";
import {toast} from "react-toastify";
import {CreateMileageHistoryDto} from "../../../types/mileageHistory";

interface MileageModalProps {
    open: boolean;
    onClose: () => void;
    userId: number;
}

export const MileageModal: React.FC<MileageModalProps> = ({
                                                              open,
                                                              onClose,
                                                              userId,
                                                          }) => {
    const [mileage, setMileage] = useState<number>(0);
    const [memo, setMemo] = useState<string>('');

    const submit = () => {
        let data = {
            memo: memo,
        } as CreateMileageHistoryDto;
        if (mileage === 0) toast.warn('마일리지 금액을 입력하세요');
        if (mileage > 0) {
            data.givenMileage = mileage;
            data.kind = '61';
        } else {
            data.useMileage = mileage * -1;
            data.kind = '62';
        }
        postMileageHistory(userId, data).then(() => {
            toast.success('마일리지 사용/차감 등록 완료');
            onClose();
        })
    }

    if (!open) return <></>;
    return (
        <>
            {/* <div
        className="fixed inset-0 z-10 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
        onClick={onClose}
      ></div> */}
            <Transition appear show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-10 overflow-y-auto bg-gray-500 bg-opacity-75 transition-opacity"
                    onClose={onClose}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0"/>
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
              &#8203;
            </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div
                                className="my-8 inline-block w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <div className="flex items-center justify-between">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        마일리지 사용/적립
                                    </Dialog.Title>
                                    <Icon.X onClick={onClose} className="cursor-pointer"/>
                                </div>

                                <p className="mt-4 text-sm text-gray-500">
                                    차감하실 경우 숫자 앞에 - 를 붙여주세요
                                </p>
                                <div className="my-6">
                                    <TextField label="마일리지" type="number"
                                               onChange={(e) => setMileage(Number(e.target.value))}/>
                                </div>
                                <div className="my-6">
                                    <TextField label="메모" type="text"
                                               onChange={(e) => setMemo(e.target.value)}/>
                                </div>

                                <div className="mt-4">
                                    <Button onClick={submit}
                                            text="저장하기"
                                            className="filled-black h-12 text-sm"
                                    />
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};
