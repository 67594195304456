import {useHistory, useParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {UpdateSectionDto} from "../types/sections";
import {useEffect, useState} from "react";
import {CurationProductDto} from "../types/arcnShop";
import {addSectionProduct, getSection, patchSection} from "../api/sectionAPI";
import {toast} from "react-toastify";

const useSections = () => {
    const params = useParams<{ id: string }>();
    const numId = Number(params.id);
    const {goBack} = useHistory();
    const form = useForm<UpdateSectionDto>();
    const [products, setProducts] = useState<CurationProductDto[]>([]);

    const getData = () => {
        getSection(numId)
            .then(res => {
                form.setValue('title', res.data.title);
                form.setValue('description', res.data.description);
                form.setValue('order', res.data.order);
                form.setValue('type', res.data.type);
                form.setValue('clickUrl', res.data.clickUrl);
                const products = res.data.products.sort((a, b) => a.order - b.order);
                setProducts(products);
            })
            .catch(err => console.error(err.response));
    }

    const onsubmit = (data: UpdateSectionDto) => {
        patchSection(numId, data)
            .then(() => {
                toast.success('저장되었습니다.');
                goBack();
            })
            .catch(err => console.error(err.response));
    }

    const addProduct = (productId: number, order: number, title?: string, description?: string) => {
        addSectionProduct(numId, {
            productId,
            order,
            title,
            description
        })
            .then(() => {
                toast.success('상품이 추가되었습니다.');
                getData();
            })
            .catch(err => console.error(err.response));
    }

    useEffect(() => getData(), [numId]);

    return {
        form,
        onsubmit,
        goBack,
        products,
        addProduct,
        getData
    }
}

export default useSections;