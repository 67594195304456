import React, { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { FileAbout } from '../../../../types/common';
import { FloalaEditor } from '../../../components/FloalaEditor';
import { UpdatePartnershipDto } from '../../../../types/partnersip';

interface MobilePageEditProps {
  form: UseFormReturn<UpdatePartnershipDto>;
}

export const MobilePageEdit: FC<MobilePageEditProps> = ({ form }) => {
  return (
    <div className="flex justify-between">
      <div className="max-w-sm">
        <FloalaEditor
          imageType={FileAbout.PARTNERSHIP_MOBILE}
          defaultValue={form.getValues().mobileContents}
          onChange={(content) => form.setValue('mobileContents', content)}
        />
      </div>
    </div>
  );
};
