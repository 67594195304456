import React, { useEffect, useState } from 'react';
import {useHistory} from 'react-router-dom';
import {Button} from '../../../components/Button';
import {TextField} from '../../../components/TextField';
import {AdminH1} from '../../components/AdminH1';
import {useForm} from "react-hook-form";
import { AdminLevel, CreateAdminDto, getAdminLevelText } from "../../../types/user";
import {postAdminUser} from "../../../api/adminUserAPI";
import {toast} from "react-toastify";
import { Select } from "../../../components/Select";

export const AdminUserAdd = () => {
    const history = useHistory();
    const form = useForm<CreateAdminDto>();
    const [adminLevel, setAdminLevel] = useState<AdminLevel>(AdminLevel.BRANCH_MANAGER);

    const onSubmit = (data: CreateAdminDto) => {
        postAdminUser(data).then(() => {
            toast.success('관리자 계정이 추가되었습니다.');
            history.push('/admin/adminUsers');
        });
    }

    useEffect(() => {
        form.setValue('adminLevel', adminLevel);
    }, [adminLevel]);

    return (
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <AdminH1>관리자 계정 추가</AdminH1>

            <div className="grid grid-cols-3 gap-5 my-5">
                <Select label="관리자 구분" onChange={(e) => setAdminLevel(e.target.value as AdminLevel)}>
                    <option value={AdminLevel.BRANCH_MANAGER} selected={adminLevel === AdminLevel.BRANCH_MANAGER}>{getAdminLevelText(AdminLevel.BRANCH_MANAGER)}</option>
                    <option value={AdminLevel.ROOT_ADMIN} selected={adminLevel === AdminLevel.ROOT_ADMIN}>{getAdminLevelText(AdminLevel.ROOT_ADMIN)}</option>
                </Select>
            </div>

            <div className="grid grid-cols-3 gap-5 my-5">
                <TextField label="관리자 또는 계정 이름"
                           {...form.register('name', {required: '관리자 또는 계정 이름을 입력하세요'})}
                           helper={form.formState.errors.name?.message}
                />
            </div>

            <div className="grid grid-cols-3 gap-5 my-5">
                <TextField label="아이디"
                           {...form.register('email', {required: '아이디를 입력하세요'})}
                           helper={form.formState.errors.email?.message}
                />
            </div>

            <div className="grid grid-cols-3 gap-5 my-5">
                <TextField label="비밀번호"
                           {...form.register('password', {required: '비밀번호를 입력하세요'})}
                           helper={form.formState.errors.password?.message}
                />
            </div>

            <div className="grid grid-cols-3 gap-5 my-5">
                <TextField label="관리자 메모"
                           {...form.register('memo')}
                           helper={form.formState.errors.memo?.message}
                />
            </div>

            <div className="fixed bottom-0 -ml-8 w-full space-x-4 border-t bg-white py-2 pl-10">
                <Button text="취소" className="outlined-black h-12 px-6" onClick={() => history.goBack()}/>
                <Button type={'submit'} text="추가하기" className="filled-black h-12 px-6"/>
            </div>
            <div className="pt-14"/>
        </form>
    );
};
