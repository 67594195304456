import React, { useState } from "react";
// @ts-ignore
import LoadingScreen from 'react-loading-screen';

export const useLoading = () => {
    const [isLoading, setIsLoading] = useState(false);

    const LoadingView = () => {
        return (
            <LoadingScreen
                loading={isLoading}
                bgColor='#0000001A'
                spinnerColor='#FF5733'
                textColor='#333333'
                text='loading...'
            />
        )
    }

    return {
        isLoading, setIsLoading, LoadingView,
    }
}