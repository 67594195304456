import React from 'react';
import BannerListTemplate from "../../components/templates/BannerListTemplate";
import {BannerType} from "../../../types/banner";
import {AdminH1} from "../../components/AdminH1";

export const BannerList = () =>
    <>
        <AdminH1>메인 배너 관리</AdminH1>
        <BannerListTemplate type={BannerType.MAIN}/>
    </>
