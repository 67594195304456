import {Dialog, Transition} from "@headlessui/react";
import React, {Fragment, useEffect} from "react";
import {Icon} from "../../components/Icon";
import {Button} from "../../components/Button";
import {CommentDto} from "../../types/comment";
import moment from "moment/moment";
import {Checkbox} from "../../components/Checkbox";
import {patchComment} from "../../api/commentAPI";

type CommentModalProps = {
    open: boolean,
    onClose: () => void,
    title: string,
    content: CommentDto,
}

export const CommentModal = (props: CommentModalProps) => {
    const [isHidden, setIsHidden] = React.useState<boolean>(false);

    const changeCommentHidden = () => {
        patchComment(props.content.id, {isHidden: isHidden}).then(() => props.onClose())
    }

    useEffect(() => {
        !!props.content && setIsHidden(props.content.isHidden)
    }, [props.content])

    if (!props.open) return <></>;
    return (
        <>
            <Transition appear show={props.open} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-10 overflow-y-auto bg-gray-500 bg-opacity-75 transition-opacity"
                    onClose={props.onClose}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0"/>
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
              &#8203;
            </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div
                                className="my-8 inline-block w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <div className="flex items-center justify-between">
                                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                        {props.title}
                                    </Dialog.Title>
                                    <Icon.X onClick={props.onClose} className="cursor-pointer"/>
                                </div>

                                <div className={'flex space-x-3 pt-4'}>
                                    <p>{props.content.user.name}</p>
                                    <p className={'text-[#7A7979]'}>{moment(props.content.createdAt).format('YYYY.MM.DD HH:mm')}</p>
                                </div>

                                <div className={'border-b py-3 text-[#6D7684]'}>
                                    <p>{props.content.comment}</p>
                                </div>

                                <div className={'py-3'}>
                                    <Checkbox label={'숨김 처리하기'}
                                          checked={isHidden}
                                          onChange={() => setIsHidden(!isHidden)}/>
                                </div>

                                <div className="mt-4 space-y-2">
                                    <Button text="저장하기"
                                            className="filled-black h-12 w-full text-sm"
                                            onClick={changeCommentHidden}/>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}