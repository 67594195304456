import React, { useEffect, useState } from 'react';
import { Button } from '../../../components/Button';
import { MileageModal } from '../../components/modal/MileageModal';
import { TextField } from '../../../components/TextField';
import { AdminH1 } from '../../components/AdminH1';
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AdminDto, AdminLevel, CreateAdminDto, getAdminLevelText } from "../../../types/user";
import { toast } from "react-toastify";
import { deleteAdminUser, getAdminUser, patchAdminUser } from "../../../api/adminUserAPI";
import { Select } from "../../../components/Select";

export const AdminUserShow = () => {
    const {id} = useParams<{ id: string }>();
    const numId = Number(id);
    const history = useHistory();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const form = useForm<Partial<CreateAdminDto>>();
    const [adminLevel, setAdminLevel] = useState<AdminLevel>(AdminLevel.BRANCH_MANAGER);
    const [user, setAdminUser] = useState<AdminDto>();

    const onsubmit = (data: Partial<CreateAdminDto>) => {
        patchAdminUser(numId, data).then(() => {
            toast.success('수정되었습니다.');
            history.goBack();
        });
    }

    const getAdminUserInfo = () => {
        getAdminUser(numId).then(res => {
            setAdminUser(res.data);
            setAdminLevel(res.data.adminLevel);
            // form.setValue('adminLevel', res.data.adminLevel);
            form.setValue('name', res.data.name);
            form.setValue('email', res.data.email);
            form.setValue('memo', res.data.memo);
        });
    }

    const onDeleteAdminUser = () => {
        const confirm = window.confirm('정말정말로 삭제하시겠습니까? 회원정보가 모두 사라집니다 ㅠㅠ');
        if (confirm) {
            deleteAdminUser(numId).then(() => {
                toast.success('삭제되었습니다.');
                history.goBack();
            })
        }
    }

    useEffect(() => {
        getAdminUserInfo();
    }, [history.location])

    useEffect(() => {
        form.setValue('adminLevel', adminLevel);
    }, [adminLevel]);

    return (
        <form onSubmit={form.handleSubmit(onsubmit)}>
            <MileageModal open={isOpen}
                          onClose={() => {
                              getAdminUserInfo();
                              setIsOpen(false);
                          }}
                          userId={numId}/>
            <AdminH1>관리자 계정 상세 정보</AdminH1>

            <div className="grid grid-cols-3 gap-5 my-5">
                <Select label="관리자 구분" onChange={(e) => setAdminLevel(e.target.value as AdminLevel)}>
                    <option value={AdminLevel.NOT_ADMIN} selected={adminLevel === AdminLevel.NOT_ADMIN}>{getAdminLevelText(AdminLevel.NOT_ADMIN)}</option>
                    <option value={AdminLevel.BRANCH_MANAGER} selected={adminLevel === AdminLevel.BRANCH_MANAGER}>{getAdminLevelText(AdminLevel.BRANCH_MANAGER)}</option>
                    <option value={AdminLevel.ROOT_ADMIN} selected={adminLevel === AdminLevel.ROOT_ADMIN}>{getAdminLevelText(AdminLevel.ROOT_ADMIN)}</option>
                </Select>
            </div>

            <div className="grid grid-cols-3 gap-5 my-5">
                <TextField label="관리자 또는 계정 이름"
                           {...form.register('name', {required: '관리자 또는 계정 이름을 입력하세요'})}
                           helper={form.formState.errors.name?.message}
                />
            </div>

            <div className="grid grid-cols-3 gap-5 my-5">
                <TextField label="아이디"
                           {...form.register('email', {required: '아이디를 입력하세요'})}
                           helper={form.formState.errors.email?.message}
                />
            </div>

            <div className="grid grid-cols-3 gap-5 my-5">
                <TextField label="비밀번호 (변경시에만 입력)"
                           onChange={e => form.setValue('password', e.target.value)}
                />
            </div>

            <div className="grid grid-cols-3 gap-5 my-5">
                <TextField label="관리자 메모"
                           {...form.register('memo')}
                           helper={form.formState.errors.memo?.message}
                />
            </div>



            <div className="fixed bottom-0 -ml-8 w-full space-x-4 border-t bg-white py-2 pl-10 z-5">
              {user && user.adminLevel !== AdminLevel.ROOT_ADMIN ? (
                <Button text="삭제" className="filled-red-500 h-12 px-6" onClick={onDeleteAdminUser}/>
              ) : ''}
              <Button text="취소" className="outlined-black h-12 px-6" onClick={() => history.goBack()}/>
              <Button type={'submit'} text="저장" className="filled-black h-12 px-6"/>
            </div>
        </form>
    );
};
