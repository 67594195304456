import { useQuery } from 'react-query';
import { getProducts } from '../api/productAPI';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { getProductQueryString } from '../utils/getProductQueryString';
import { ProductType } from '../types/product';
import { PageQuery } from '../types/common';
import { useEffect, useMemo } from 'react';

const useGetProducts = (type: ProductType) => {
  const history = useHistory();
  const query = queryString.parse(history.location.search);

  const parsedQuery = useMemo(() => getProductQueryString(query, type), [query, type]);
  const params: PageQuery = useMemo(() => ({
    page: Number(query.page) || 1,
    itemsPerPage: Number(query.limit) || 30,
  }), [query.page, query.limit]);

  useEffect(() => {
    if (query.cate || query.sub) {
      query.page = '1';
      history.push(`?${queryString.stringify(query)}`);
    }
  }, [query.cate, query.sub]);


  return useQuery(
    ['products', parsedQuery, params],
    async () => {
      try {
        const { data } = await getProducts(parsedQuery, params);
        if (query.order === 'rank') {
          data.items.sort(
            (a, b) =>
              // @ts-ignore
              (a.categoryRank === 0) - (b.categoryRank === 0) ||
              a.categoryRank - b.categoryRank
          );
        }
        return data;
      } catch (e) {
        console.error(e);
      }
    },
  );
};

export default useGetProducts;
