import { api } from '../plugins/axios';
import {
  CreateSectionDto,
  SectionDto,
  UpdateSectionDto,
} from '../types/sections';
import { CreateCurationProductDto } from '../types/arcnShop';

export const getSections = (position?: 'MAIN' | 'LIFE') => {
  const params = position ? { position } : {};
  return api.get<SectionDto[]>('/sections', { params });
};

export const getSection = (id: number) => {
  return api.get<SectionDto>(`/sections/${id}`);
};

export const patchSection = (id: number, section: UpdateSectionDto) => {
  return api.patch<SectionDto>(`/sections/${id}`, section);
};

export const deleteSection = (id: number) => {
  return api.delete(`/sections/${id}`);
};

export const addSection = (section: CreateSectionDto) => {
  return api.post<SectionDto>('/sections', section);
};

export const addSectionProduct = (
  id: number,
  data: CreateCurationProductDto
) => {
  return api.post<SectionDto>(`/sections/${id}/products`, data);
};
