import {api} from "../plugins/axios";
import {Paged} from "../types/common";
import {CouponDto, UsersCouponDto} from "../types/coupons.type";

export const getOriginCoupons = () => {
    return api.get<Paged<CouponDto>>(`/coupons`, {params: {itemsPerPage: 1000}});
}

export const getUsersCoupons = (userId: number, page?: number, itemsPerPage?: number) => {
    const params = {
        page: page || 1,
        itemsPerPage: itemsPerPage || 30,
    }
    return api.get<Paged<UsersCouponDto>>(`/users/${userId}/coupons?order[id]=DESC`, {params});
}

export const postUsersCoupon = (userId: number, couponId: number) => {
    return api.post(`/users/${userId}/coupons`, {couponId});
}
