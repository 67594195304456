import {api} from "../plugins/axios";
import {Keyword, KeywordType, UpdateKeywordDto} from "../types/keyword";

export const getKeywords = (type: KeywordType) => {
    const params = {type};
    return api.get<Keyword[]>(`/keywords`, {params});
}

export const updateKeywords = (data: UpdateKeywordDto[]) => {
    return api.put<Keyword[]>(`/keywords`, data);
}