export const specialAllowAdminList = [
    'anb.dongtan@google.com',
    'anb.myongji@google.com',
    'anb.mondrian@google.com',
    'anb.asiad@google.com',
    'anb.sejong@google.com',
    'anb.suzy@google.com',
    'anb.sinchon@google.com',
    'anb.wolgye@google.com',
    'anb.jamsil@google.com',
    'anb.cc@google.com',
]
