import { api } from '../plugins/axios';
import { Paged } from '../types/common';
import {
  CreatePartnershipDto,
  Partnership,
  UpdatePartnershipDto,
} from '../types/partnersip';

export const getPartnerships = (
  query?: string,
  page?: number,
  itemsPerPage?: number
) => {
  const params = {
    page: page || 1,
    itemsPerPage: itemsPerPage || 30,
  };
  return api.get<Paged<Partnership>>(`/admin/partnerships?${query}`, {
    params,
  });
};

export const getPartnership = () => {
  return api.get<Partnership>(`/admin/partnerships/one`);
};

export const postPartnership = (data: Partial<CreatePartnershipDto>) => {
  return api.post<Partnership>(`/admin/partnerships`, data);
};

export const patchPartnership = (
  id: number,
  data: Partial<UpdatePartnershipDto>
) => {
  return api.patch<Partnership>(`/admin/partnerships/${id}`, data);
};
