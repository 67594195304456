import { api } from '../plugins/axios';
import { Paged } from '../types/common';
import { CategoryDto, CreateCategoryDto } from '../types/categories';
import { TabType } from '../admin/enum';

export const getCategories = async (
  page: number,
  itemsPerPage: number,
  type?: TabType,
  cate?: number
) => {
  const params = { page, itemsPerPage, type: type ?? TabType.BOOK };
  const result = await api.get<Paged<CategoryDto>>(`/categories`, { params });
  return result.data;
};

export const postCategories = (data: CreateCategoryDto) => {
  return api.post<any>(`/categories`, data);
};

export const getCategory = async (id: number) => {
  const result = await api.get<CategoryDto>(`/categories/${id}`);
  return result.data.categories;
};

export const patchCategory = (id: number, data: Partial<CreateCategoryDto>) => {
  return api.patch<CategoryDto>(`/categories/${id}`, data);
};

export const deleteCategory = (id: number) => {
  return api.delete<CategoryDto>(`/categories/${id}`);
};
