export interface ReorderableProps {
    order: number;
    onReordered: (order: number) => void;
}

const Reorderable = (props: ReorderableProps) => {

    const reorderPrompt = () => {
        const reorder = prompt('변경하실 우선순위 입력');
        if (reorder && !isNaN(Number(reorder))) {
            props.onReordered(Number(reorder));
        }
    }

    return (
        <span className={'cursor-pointer'} onClick={reorderPrompt}>{props.order}</span>
    )
}

export default Reorderable;