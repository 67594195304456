import { Tab } from './Tab';
import queryString from 'query-string';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ProductDto } from '../../types/product';
import { Paged } from '../../types/common';

export interface ProductTabBarProps {
  products?: Paged<ProductDto>;
}

const ProductTabBar = (props: ProductTabBarProps) => {
  const history = useHistory();
  const query = queryString.parse(history.location.search);

  if (!props.products) return null;
  return (
    <div className="flex space-x-4 border-b">
      <Tab
        text={`전체${
          query.tab === '0' || query.tab === undefined
            ? `(${props.products.pagination?.totalItemCount})`
            : ''
        }`}
        selected={query.tab === '0' || query.tab === undefined}
        onClick={() => {
          query.tab = '0';
          history.push(`?${queryString.stringify(query)}`);
        }}
      />
      <Tab
        text={`판매중${
          query.tab === '1'
            ? `(${props.products.pagination?.totalItemCount})`
            : ''
        }`}
        selected={query.tab === '1'}
        onClick={() => {
          query.tab = '1';
          history.push(`?${queryString.stringify(query)}`);
        }}
      />
      <Tab
        text={`품절${
          query.tab === '2'
            ? `(${props.products.pagination?.totalItemCount})`
            : ''
        }`}
        selected={query.tab === '2'}
        onClick={() => {
          query.tab = '2';
          history.push(`?${queryString.stringify(query)}`);
        }}
      />
      <Tab
        text={`숨김${
          query.tab === '3'
            ? `(${props.products.pagination?.totalItemCount})`
            : ''
        }`}
        selected={query.tab === '3'}
        onClick={() => {
          query.tab = '3';
          history.push(`?${queryString.stringify(query)}`);
        }}
      />
    </div>
  );
};

export default ProductTabBar;
