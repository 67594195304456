import React from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Button} from '../../../components/Button';
import {TextField} from '../../../components/TextField';
import {AdminH1} from '../../components/AdminH1';
import {getEventTag, patchEventTag} from "../../../api/eventTagAPI";
import {toast} from "react-toastify";
import { useEffect } from 'react';

export const ProductTagShow = () => {
    const {id} = useParams<{ id: string }>();
    const numId = parseInt(id);
    const history = useHistory();
    const [name, setName] = React.useState<string>('');

    const onSave = () => {
        patchEventTag(numId, {name}).then(() => {
            toast.success('저장되었습니다.');
            history.goBack();
        }).catch(err => console.error(err.response));
    }

    useEffect(() => {
        getEventTag(numId).then(res => {
            setName(res.data.name);
        }).catch(err => console.error(err.response));
    }, [numId])

    return (
        <>
            <AdminH1>태그 상세</AdminH1>
            <TextField label="태그명" value={name} autoFocus={true} onChange={e => setName(e.target.value)}/>

            <div className="fixed bottom-0 -ml-8 w-full space-x-4 border-t bg-white py-2 pl-10">
                <Button text="취소" className="outlined-black h-12 px-6" onClick={() => history.goBack()}/>
                <Button text="저장" className="filled-black h-12 px-6" onClick={onSave} disabled={name === ''}/>
            </div>
        </>
    );
};
