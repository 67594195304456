export const phoneNumber = (num: string) => {
    let reNumber = num.replaceAll('-', '').replaceAll(' ', '');

    switch (reNumber.length) {
        case 10:
            return reNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        case 11:
            return reNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    }
}
