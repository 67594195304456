import { CurationProductDto } from './arcnShop';

export enum SectionType {
  SINGLE_CARD_SECTION = 'SINGLE_CARD_SECTION',
  MULTI_CARD_SECTION_1 = 'MULTI_CARD_SECTION_1',
  MULTI_CARD_SECTION_2 = 'MULTI_CARD_SECTION_2',
  GRID_CARD_SECTION_1 = 'GRID_CARD_SECTION_1',
  GRID_CARD_SECTION_2 = 'GRID_CARD_SECTION_2',
  KEYWORD_SECTION = 'KEYWORD_SECTION',
  BANNER_SECTION = 'BANNER_SECTION',
}

export interface SectionDto {
  id: number;
  createdAt: string;
  updatedAt: string;
  type: SectionType;
  order: number;
  isShow: boolean;
  title: string;
  description: string;
  clickUrl: string;
  products: CurationProductDto[];
}

export interface CreateSectionDto {
  position: 'MAIN' | 'LIFE';
  type: SectionType;
  order: number;
  isShow: boolean;
  title: string;
  description: string;
  clickUrl: string;
}

export interface UpdateSectionDto {
  id?: number;
  createdAt?: string;
  updatedAt?: string;
  type?: SectionType;
  order?: number;
  isShow?: boolean;
  title?: string;
  description?: string;
  clickUrl?: string;
  products?: CurationProductDto[];
}
