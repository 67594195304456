import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../../components/Button';
import { Search } from '../../../../components/Search';
import { TextArea } from '../../../../components/TextArea';
import { TextField } from '../../../../components/TextField';
import { AdminH1 } from '../../../components/AdminH1';

export const SingleProductAdd = () => {
  const { goBack } = useHistory();
  return (
    <div className="max-w-md space-y-4">
      <AdminH1>상품 추가</AdminH1>
      <div>
        <div className="label">상품 검색</div>
        <Search height={14} />
      </div>
      <TextField label="노출 우선순위" className="h-12" />
      <TextArea label="상세설명" className="h-52" />
      <Button text="저장하기" className="filled-black w-full" />
      <Button
        text="취소하기"
        className="filled-gray-200 w-full text-gray-800"
        onClick={() => goBack()}
      />
    </div>
  );
};
