import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Card } from '../../../components/Card';
import { Pagination } from '../../../components/Pagination';
import { Table } from '../../../components/Table';
import { AdminH1 } from '../../components/AdminH1';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';
import { Button } from '../../../components/Button';
import { getPostings } from '../../../api/postingAPI';
import { Paged } from '../../../types/common';
import { PostingDto } from '../../../types/posting';
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Search } from '../../../components/Search';
import queryString from 'query-string';

export const PostList = () => {
  const history = useHistory();
  const query = queryString.parse(history.location.search);
  const [posts, setPosts] = React.useState<Paged<PostingDto>>({ items: [] });

  const typeList = ['a', 'b', 'c', 'd'];

  const getList = () => {
    let nQuery = ``;
    if (query.name) {
      nQuery += `name=${query.name}`;
    }
    getPostings(
      `${nQuery}&order[id]=DESC`,
      Number(query.page),
      Number(query.limit)
    )
      .then((res) => setPosts(res.data))
      .catch((err) => console.error(err.response));
  };

  useEffect(() => getList(), [history.location]);

  return (
    <>
      <AdminH1>게시글 관리</AdminH1>
      <div className={'flex justify-between'}>
        <div className="space-x-4">
          {typeList.map((type, index) => (
            <Button
              key={index}
              text={`${type.toUpperCase()}타입 추가`}
              className="filled-black h-12"
              to={`/admin/post/${type}/add`}
            />
          ))}
        </div>
        <Search
          className="h-10"
          defaultValue={query.name?.toString()}
          onSearch={(keyword) => {
            query.name = keyword;
            history.push(`?${queryString.stringify(query)}`);
          }}
        />
      </div>

      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>등록일자</Table.Th>
              <Table.Th>타입</Table.Th>
              <Table.Th>게시글명</Table.Th>
              <Table.Th>URL</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {posts.items.length === 0 && (
              <Table.Row>
                <Table.Td colSpan={4}>데이터가 없습니다.</Table.Td>
              </Table.Row>
            )}
            {posts.items.map((post) => (
              <Table.Row key={post.id}>
                <Table.Td>
                  {utcToLocalFormat(
                    post.createdAt.toLocaleString(),
                    MomentFormat.YYYYMMDDHmm
                  )}
                </Table.Td>
                <Table.Td>타입 {post.type}</Table.Td>
                <Table.Td>{post.title}</Table.Td>
                <Table.Td>
                  <CopyToClipboard
                    text={`${process.env.REACT_APP_FRONT_URL}/lists/${post.id}`}
                    onCopy={() => toast.info('복사되었습니다.')}
                  >
                    <span
                      className={'cursor-pointer text-blue-500 underline'}
                    >{`${process.env.REACT_APP_FRONT_URL}/lists/${post.id}`}</span>
                  </CopyToClipboard>
                </Table.Td>
                <Table.Td className="text-right">
                  <Link
                    className="rounded-md font-normal text-indigo-600 hover:text-indigo-900"
                    to={`/admin/post/${post.type.toLowerCase()}/${post.id}`}
                  >
                    상세보기
                  </Link>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination>
          <Pagination.Label
            count={posts.items.length}
            total={posts.pagination?.totalItemCount!}
          />
          <Pagination.Nav
            basePath={history.location.pathname}
            total={posts.pagination?.totalItemCount!}
          />
        </Pagination>
      </Card>
    </>
  );
};
