import React, { FC, InputHTMLAttributes, useRef } from 'react';
import { Icon } from './Icon';

interface SearchProps extends InputHTMLAttributes<HTMLInputElement> {
  onSearch?: (keyword: string) => void;
  onClose?: () => void;
  className?: string;
  isLoading?: boolean;
}

export const Search: FC<SearchProps> = ({
  value,
  onSearch = () => {},
  onClose = () => {},
  className,
  ...props
}) => {
  const input = useRef<HTMLInputElement>(null);
  const [keyword, setKeyword] = React.useState('');

  return (
    <div
      className={`${className} flex h-12 items-center rounded-xl border border-gray-200 px-4 focus-within:border-brand-2`}
    >
      <input
        ref={input}
        className="flex-1 text-sm placeholder-gray-400"
        value={value}
        {...props}
        onChange={(e) => {
          setKeyword(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') onSearch(keyword);
        }}
      />
      <div className="wh-10 -ml-2 flex cursor-pointer items-center justify-center">
        {!props.isLoading && (
          <Icon.Search type={'button'} onClick={() => onSearch(keyword)} />
        )}
      </div>
      {value && (
        <div className="wh-10 -mr-2 flex items-center justify-center">
          <Icon.X
            className="wh-4"
            onClick={() => {
              onClose();
              Object.getOwnPropertyDescriptor(
                window.HTMLInputElement.prototype,
                'value'
              )?.set?.call(input.current, '');
              input.current?.dispatchEvent(
                new Event('change', { bubbles: true })
              );
            }}
          />
        </div>
      )}
    </div>
  );
};
