import {api} from "../plugins/axios";
import { Paged } from "../types/common";
import { AdminDto, CreateAdminDto } from "../types/user";

export const getAdminUsers = (query: string, page?: number, itemsPerPage?: number) => {
    const params = {
        page: page || 1,
        itemsPerPage: itemsPerPage || 30,
    };
    return api.get<Paged<AdminDto>>("/admin/users?" + query, {params});
}

export const postAdminUser = (user: CreateAdminDto) => {
    return api.post<AdminDto>("/admin/users", user);
}

export const getAdminUser = (id: number) => {
    return api.get<AdminDto>(`/admin/users/${id}`);
}

export const patchAdminUser = (id: number, user: Partial<CreateAdminDto>) => {
    return api.patch<AdminDto>(`/admin/users/${id}`, user);
}

export const deleteAdminUser = (id: number) => {
    return api.delete<void>(`/admin/users/${id}`);
}
