import {api} from "../plugins/axios";
import {CompanyDto} from "../types/product";
import {Paged} from "../types/common";
import {CreateCompanyDto} from "../types/companies";

export const getCompanies = (query: string, page?: number, itemsPerPage?: number) => {
    const params = {
        page: page || 1,
        itemsPerPage: itemsPerPage || 30,
    };
    return api.get<Paged<CompanyDto>>('/companies?' + query, {params});
}

export const postCompany = (company: CreateCompanyDto) => {
    return api.post<CompanyDto>('/companies', company);
}

export const getCompany = (id: number) => {
    return api.get<CompanyDto>(`/companies/${id}`);
}

export const patchCompany = (id: number, company: Partial<CreateCompanyDto>) => {
    return api.patch<CompanyDto>(`/companies/${id}`, company);
}

export const deleteCompany = (id: number) => {
    return api.delete<void>(`/companies/${id}`);
}