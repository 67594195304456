import React, {useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Button} from '../../../components/Button';
import {Card} from '../../../components/Card';
import {Pagination} from '../../../components/Pagination';
import {Search} from '../../../components/Search';
import {Table} from '../../../components/Table';
import {MomentFormat, utcToLocalFormat} from '../../../plugins/moment';
import {AdminH1} from '../../components/AdminH1';
import queryString from "query-string";
import {getEventTags} from "../../../api/eventTagAPI";
import {EventTagDto} from "../../../types/product";
import {Paged} from "../../../types/common";

export const ProductTagList = () => {
    const history = useHistory();
    const query = queryString.parse(history.location.search);
    const [tags, setTags] = React.useState<Paged<EventTagDto>>({items: []});

    const getList = () => {
        let dataQuery = ``;
        if (query.name) dataQuery += `name=${query.name}`;
        dataQuery += `&order[id]=DESC`;
        getEventTags(dataQuery, Number(query.page), Number(query.limit))
            .then(res => setTags(res.data))
            .catch(err => console.error(err.response))
    }

    useEffect(() => getList(), [history.location.search]);

    return (
        <>
            <AdminH1>태그관리</AdminH1>
            <div className="flex items-center justify-between">
                <Button
                    text="태그 추가"
                    className="filled-black h-12"
                    to={`${history.location.pathname}/add`}
                />
                <Search className="h-12" defaultValue={query.name?.toString()}
                        onSearch={(keyword) => {
                            query.name = keyword;
                            history.push(`?${queryString.stringify(query)}`);
                        }}/>
            </div>

            <Card>
                <Table>
                    <Table.Head>
                        <Table.Row>
                            <Table.Th>ID</Table.Th>
                            <Table.Th>등록일자</Table.Th>
                            <Table.Th>태그명</Table.Th>
                            <Table.Th></Table.Th>
                        </Table.Row>
                    </Table.Head>
                    <Table.Body>
                        {tags.items.length === 0 && (
                            <Table.Row>
                                <Table.Td colSpan={4}>데이터가 없습니다.</Table.Td>
                            </Table.Row>
                        )}
                        {tags.items.map((tag) => (
                            <Table.Row key={tag.id}>
                                <Table.Td>{tag.id}</Table.Td>
                                <Table.Td>
                                    {utcToLocalFormat(
                                        tag.createdAt!.toLocaleString(),
                                        MomentFormat.YYYYMMDDHmm
                                    )}
                                </Table.Td>
                                <Table.Td>{tag.name}</Table.Td>
                                <Table.Td className="text-right">
                                    <Link
                                        className="rounded-md font-normal text-indigo-600 hover:text-indigo-900"
                                        to={`${history.location.pathname}/${tag.id}`}
                                    >
                                        상세보기
                                    </Link>
                                </Table.Td>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>

                <Pagination>
                    <Pagination.Label count={tags.items.length} total={tags.pagination?.totalItemCount!}/>
                    <Pagination.Nav basePath={history.location.pathname} total={tags.pagination?.totalItemCount!}/>
                </Pagination>
            </Card>
        </>
    );
};
