import {parse, stringify} from 'qs';
import {useLocation} from 'react-router';
import {api} from './plugins/axios';
import {tokenState} from './plugins/ridge';
import { useEffect, useState } from "react";
import { UserDto } from "./types/user";
import { getUserSession } from "./api/sessionAPI";

export const useAuth = () => {
    const [currentUser, setCurrentUser] = useState<UserDto | null>(null);
    const [token, setToken] = tokenState.use();
    const authenticated = token !== null;
    const signup = (data: any) =>
        api
            .post('/auth/signup', data)
            .then(({data: {token}}) => setToken(token));
    const login = (data: any) =>
        api
            .post('/auth/login', data)
            .then(({data: {token}}) => setToken(token));
    const logout = () => {
        tokenState.reset();
        alert('로그아웃 되었습니다.');
        window.location.assign('/login');
    };

    useEffect(() => {
        if (token) {
            getUserSession()
              .then(({ data }) => setCurrentUser(data))
              .catch(e => console.error(e));
        } else {
            setCurrentUser(null);
        }
    }, [token]);

    return {token, authenticated, currentUser, signup, login, logout};
};

export function useQueryString(queryObject: any = {}) {
    const {search} = useLocation();
    const parsed = parse(search, {ignoreQueryPrefix: true});
    const searchObject = {
        page: '1',
        limit: '10',
        sort: {id: 'DESC'},
        ...parsed,
        ...queryObject,
    };
    return stringify(searchObject, {addQueryPrefix: true, encode: false});
}
