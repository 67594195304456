import React from 'react';
import { Button } from '../../../../components/Button';
import { TextField } from '../../../../components/TextField';
import BannerListTemplate from '../../../components/templates/BannerListTemplate';
import { BannerType } from '../../../../types/banner';
import useSections from '../../../../hooks/useSections';
import { useHistory } from 'react-router';
import queryString from 'query-string';

export const BannerTypePage = () => {
  const history = useHistory();
  const query: Record<string, any> = queryString.parse(history.location.search);

  const { form, onsubmit, goBack } = useSections();

  return (
    <>
      <div className="grid grid-cols-3 gap-5">
        <TextField label="템플릿명" disabled value="BannerSection" />
        <TextField
          label="템플릿 노출 우선순위"
          type="number"
          {...form.register('order')}
        />
      </div>

      <div className="h-3 w-full bg-gray-50" />

      <BannerListTemplate type={query.type ?? 'MINI'} paged={false} />

      <div className="fixed bottom-0 -ml-8 w-full space-x-4 border-t bg-white py-2 pl-10">
        <Button
          text="취소"
          className="outlined-black h-12 px-6"
          onClick={() => goBack()}
        />
        <Button
          type={'button'}
          text="저장"
          className="filled-black h-12 px-6"
          onClick={() => onsubmit(form.watch())}
        />
      </div>

      <div className="pt-14" />
    </>
  );
};
