export interface PartnershipForm {
  id: number;
  createdAt: string;
  updatedAt: string;
  inquiryType: InquiryType;
  name: string;
  email: string;
  phoneNumber: string;
  companyName?: string;
  contents: string;
  adminMemo?: string;
}

export enum InquiryType {
  CONSULTING = 'CONSULTING', // 북큐레이션 및 공간 컨설팅 문의
  ENTERED = 'ENTERED', // 입점문의
  COLLABORATION = 'COLLABORATION', // 콘텐츠 및 이벤트 협업 문의
  RENTAL = 'RENTAL', // 대관문의
  ADVERTISEMENT = 'ADVERTISEMENT', // 광고문의
  BULK_PURCHASE = 'BULK_PURCHASE', // 대량구매문의
  BUSINESS = 'BUSINESS', // 기타 사업 관련 문의
}

export interface UpdatePartnershipFormDto {
  adminMemo?: string;
}

export const getInquiryType = (PartnershipForm: PartnershipForm) => {
  const { inquiryType } = PartnershipForm;
  if (inquiryType === InquiryType.CONSULTING) {
    return '북큐레이션 및 공간 컨설팅 문의';
  }
  if (inquiryType === InquiryType.ENTERED) {
    return '입점문의';
  }
  if (inquiryType === InquiryType.COLLABORATION) {
    return '콘텐츠 및 이벤트 협업 문의';
  }
  if (inquiryType === InquiryType.RENTAL) {
    return '대관문의';
  }
  if (inquiryType === InquiryType.ADVERTISEMENT) {
    return '광고문의';
  }
  if (inquiryType === InquiryType.BULK_PURCHASE) {
    return '대량구매문의';
  }
  if (inquiryType === InquiryType.BUSINESS) {
    return '기타 사업 관련 문의';
  }

  return '-';
};
