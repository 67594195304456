import React, {useEffect, useState} from 'react';
import {Button} from '../../../components/Button';
import {TextArea} from '../../../components/TextArea';
import {TextField} from '../../../components/TextField';
import {useHistory, useParams} from "react-router-dom";
import {deleteUser, getUser, patchUser} from "../../../api/userAPI";
import {useForm} from "react-hook-form";
import {CreateUserManageDto, UserManageDetailDto} from "../../../types/user";
import PostcodeModal from "../../components/PostcodeModal";
import {toast} from "react-toastify";
import {phoneNumber} from "../../../utils/phoneNumber";
import {Checkbox} from '../../../components/Checkbox';
import {UserMileageTable} from "../../components/user/UserMileageTable";
import {UserCouponTable} from "../../components/user/UserCouponTable";
import queryString from "query-string";
import {getUserGradeText} from "../../../utils/textMatcher";

export const UserShow = () => {
    const {id} = useParams<{ id: string }>();
    const numId = Number(id);
    const history = useHistory();
    const query = queryString.parse(history.location.search);
    const form = useForm<Partial<CreateUserManageDto>>();
    const [user, setUser] = useState<UserManageDetailDto>();
    const [postcodeModal, setPostcodeModal] = React.useState(false);

    const onsubmit = (data: Partial<CreateUserManageDto>) => {
        patchUser(numId, data).then(() => {
            toast.success('수정되었습니다.');
            history.goBack();
        });
    }

    const getUserInfo = () => {
        getUser(numId).then(res => {
            setUser(res.data);
            form.setValue('name', res.data.name);
            form.setValue('email', res.data.email);
            form.setValue('phone', res.data.phone);
            form.setValue('zipcode', res.data.zipcode);
            form.setValue('address', res.data.address);
            form.setValue('addressDetail', res.data.addressDetail);
            form.setValue('isMarketingUsageAgreed', !!res.data.marketingUsageAgreedAt);
            form.setValue('memo', res.data.memo);
        });
    }

    const onDeleteUser = () => {
        const confirm = window.confirm('정말정말로 삭제하시겠습니까? 회원정보가 모두 사라집니다 ㅠㅠ');
        if (confirm) {
            deleteUser(numId).then(() => {
                toast.success('삭제되었습니다.');
                history.goBack();
            })
        }
    }

    useEffect(() => {
        getUserInfo();
    }, [history.location])

    if (!user) return null;
    return (
        <form onSubmit={form.handleSubmit(onsubmit)}>

            <h3 className="text-lg font-semibold">회원 등급</h3>
            <p className={'mt-4 mb-8'}><b>{getUserGradeText(user.level)}</b> | 최근 3개월 주문금액: {user.libroOrderAmount.toLocaleString()}원</p>

            <h3 className="text-lg font-semibold">회원 정보</h3>

            <div className="grid grid-cols-3 gap-5 my-5">
                <TextField label="고객명"
                           {...form.register('name', {required: '고객명을 입력하세요', setValueAs: value => value.trim()})}
                           helper={form.formState.errors.name?.message}
                />
                <TextField label="핸드폰번호"
                           value={form.watch('phone')}
                           {...form.register('phone', {
                               required: '핸드폰번호를 입력하세요',
                               setValueAs: value => phoneNumber(value),
                           })}
                           helper={form.formState.errors.phone?.message}
                />
                {/*<TextField label="성인인증여부" disabled/>*/}
            </div>
            <div className="grid grid-cols-3 gap-5">
                <TextField label="아이디"
                           {...form.register('email', {required: '아이디를 입력하세요'})}
                           helper={form.formState.errors.email?.message}
                />
                <TextField label="비밀번호 (변경시에만 입력)"
                           onChange={e => form.setValue('password', e.target.value)}
                />
            </div>

            <div className="space-y-5 py-10">
                <h3 className="text-lg font-semibold">기본배송지</h3>
                <TextField label="주소" value={form.watch('address')} onClick={() => setPostcodeModal(true)}/>
                <TextField label="상세주소" {...form.register('addressDetail')}/>
                <TextArea label="관리자 메모" className="h-24" {...form.register('memo')}/>
                <Checkbox label="마케팅 수신여부" checked={!!form.watch('isMarketingUsageAgreed')}
                          onChange={() => form.setValue('isMarketingUsageAgreed', !form.watch('isMarketingUsageAgreed'))}/>
            </div>

            <div className={'flex mt-5'}>
                <div
                    className={`border-b-2 p-4 text-lg cursor-pointer ${query.tab !== 'coupon' ? 'border-slate-900 font-semibold' : 'border-slate-100 font-normal'}`}
                    onClick={() => history.push(`/admin/users/${id}?tab=mileage`)}
                >
                    <h3>마일리지 정보</h3>
                </div>
                <div
                    className={`border-b-2 p-4 text-lg cursor-pointer ${query.tab === 'coupon' ? 'border-slate-900 font-semibold' : 'border-slate-100 font-normal'}`}
                    onClick={() => history.push(`/admin/users/${id}?tab=coupon`)}
                >
                    <h3>쿠폰 정보</h3>
                </div>
                <div className={'border-b-2 border-slate-100 flex-1'}/>
            </div>

            {query.tab !== 'coupon' && <UserMileageTable user={user}/>}
            {query.tab === 'coupon' && <UserCouponTable user={user}/>}

            <div className="fixed bottom-0 -ml-8 w-full space-x-4 border-t bg-white py-2 pl-10 z-5">
                <Button text="삭제" className="outlined-black h-12 px-6" onClick={onDeleteUser}/>
                <Button type={'submit'} text="저장" className="filled-black h-12 px-6"/>
            </div>
            <div className="pt-24"/>
            <PostcodeModal open={postcodeModal}
                           onComplete={(data) => {
                               form.setValue('zipcode', data.zonecode);
                               form.setValue('address', data.address);
                               setPostcodeModal(false);
                           }}
                           onClose={() => setPostcodeModal(false)}/>
        </form>
    );
};
