import {api} from "../plugins/axios";
import {Paged} from "../types/common";
import {CreatePromotionDto, PromotionDto} from "../types/promotion";
import {ProductDto} from "../types/product";

export const getPromotions = (query: any, page?: number, itemsPerPage?: number) => {
    const params = {
        page: page || 1,
        itemsPerPage: itemsPerPage || 30,
    };
    return api.get<Paged<PromotionDto>>(`/promotions?${query}`, {params});
}

export const getPromotionDetail = (id: string) => {
    return api.get<PromotionDto>(`/promotions/${id}`);
}

export const setPromotion = (promotionId: number, productId: number) => {
    return api.post<ProductDto>(`/promotions/${promotionId}/products/${productId}`);
}

export const addPromotion = (data: CreatePromotionDto) => {
    return api.post<PromotionDto>(`/promotions`, data);
}

export const patchPromotion = (id: number, data: Partial<CreatePromotionDto>) => {
    return api.patch<PromotionDto>(`/promotions/${id}`, data);
}

export const deletePromotion = (id: number) => {
    return api.delete<void>(`/promotions/${id}`);
}

export const getKindKeys = () => {
    const params = {
        page: 1,
        itemsPerPage: 300,
    }
    return api.get<Paged<string>>(`/promotions/keys`, {params});
}

export const getCurrentOfKey = (key: string) => {
    return api.get<PromotionDto>(`/promotions/keys/${key}/current`);
}
