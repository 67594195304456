import React from 'react';
import { Avatar } from '../../components/Avatar';
import { Dropdown } from '../../components/Dropdown';
import { Menu } from '../../components/Menu';
import { useAuth } from '../../hooks';
import { getAdminLevelText } from "../../types/user";

export const AdminHeader = () => {
  const { currentUser, logout } = useAuth();

  return (
    <div className="flex justify-between px-4 h-16 bg-white shadow">
      <div className="flex-1" />

      <div className="ml-4 flex items-center">
        <Dropdown>
          <button className="flex items-center">
            {!!currentUser && (
              <span className="mr-3 text-gray-700 inline-block text-right" style={{ lineHeight: 1 }}>
                <span className="block">{currentUser.name}</span>
                <small className="block"><b>lv. {getAdminLevelText(currentUser.adminLevel)}</b> | {currentUser.email}</small>
              </span>
            )}
            <Avatar className="wh-10" />
          </button>
          <Dropdown.View className="right-0 rounded-md">
            <Menu>
              <Menu.Item text="Logout" onClick={logout} />
            </Menu>
          </Dropdown.View>
        </Dropdown>
      </div>
    </div>
  );
};
