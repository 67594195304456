import React, {useEffect} from 'react';
import {Button} from '../../../../components/Button';
import {TextArea} from '../../../../components/TextArea';
import {TextField} from '../../../../components/TextField';
import {AdminH1} from '../../../components/AdminH1';
import {getGridCardProduct, patchGridCardProduct} from "../../../../api/gridCardProductAPI";
import {useHistory, useParams} from 'react-router-dom';
import {useForm} from "react-hook-form";
import {UpdateGridCardProductDto} from "../../../../types/gridCardProduct";
import {ProductSearch} from "../../../components/templates/ProductSearch";
import {toast} from "react-toastify";

export const GridTypeProductShow = () => {
    const {id} = useParams<{ id: string }>();
    const numId = parseInt(id);
    const history = useHistory();
    const form = useForm<UpdateGridCardProductDto>();
    const [products, setProducts] = React.useState<{ id: number, name: string }[]>([]);

    const onSubmit = (data: UpdateGridCardProductDto) => {
        data.productIds = products.map(product => product.id);
        if (data.productIds?.length === 5) {
            patchGridCardProduct(numId, data)
                .then(() => {
                    toast.success('저장되었습니다.');
                    history.goBack();
                })
                .catch((err) => console.error(err.response));
        } else {
            toast.warn('상품을 5개 선택해 주세요.');
        }
    };

    const getData = () => {
        getGridCardProduct(numId).then(res => {
            form.setValue('title', res.data.title);
            form.setValue('description', res.data.description);
            form.setValue('order', res.data.order);
            form.setValue('clickUrl', res.data.clickUrl);
            setProducts(res.data.curationProducts.map(curationProduct => ({
                id: curationProduct.product.id,
                name: curationProduct.product.name
            })));
        }).catch(err => {
            console.error(err.response);
        })
    }

    useEffect(() => getData(), [])

    return (
        <div className="max-w-md space-y-4">
            <AdminH1>카드 상세</AdminH1>
            <ProductSearch extraText={'(5개 선택 필수)'}
                           selectedProducts={products}
                           onSelect={(product) => {
                               if (products.length < 5) {
                                   setProducts([...products, {
                                       id: product.id,
                                       name: product.name
                                   }]);
                               }
                           }}
                           onDragEnd={(fromIndex: number, toIndex: number) => {
                               const data = [...products];
                               const item = data.splice(fromIndex, 1)[0];
                               data.splice(toIndex, 0, item);
                               setProducts(data);
                           }}
                           onDelete={(deletedProduct) => {
                               let nProducts = [...products].filter(product => product.id !== deletedProduct.id);
                               setProducts([...nProducts]);
                           }}/>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <div className={'space-y-4'}>
                    <TextField label="노출 우선순위" className="h-12" {...form.register('order')}/>
                    <TextField label="타이틀" {...form.register('title')}/>
                    <TextArea label="상세설명" className="h-52" {...form.register('description')}/>
                    <TextField label="버튼 클릭시 url" {...form.register('clickUrl')}/>
                    <Button type={'submit'} text="저장하기" className="filled-black w-full"/>
                    <Button type={'button'}
                            text="삭제하기"
                            className="filled-gray-200 w-full text-gray-800"
                    />
                </div>
            </form>
        </div>
    );
};
