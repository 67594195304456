import {api} from "../plugins/axios";
import {
    CreateRecommendedCategoryDto,
    RecommendedCategoryDto,
    UpdateRecommendedCategoryDto
} from "../types/recommendedCategory";
import {Paged} from "../types/common";

export const postRecommendedCategory = (data: CreateRecommendedCategoryDto) => {
    return api.post<RecommendedCategoryDto>(`/recommended-categories`, data)
};

export const getRecommendedCategories = (query: any, page?: number, itemsPerPage?: number) => {
    const params = {
        page: page || 1,
        itemsPerPage: itemsPerPage || 30,
    };
    return api.get<Paged<RecommendedCategoryDto>>(`/recommended-categories?${query}`, {params})
}

export const getRecommendedCategory = (id: number) => {
    return api.get<RecommendedCategoryDto>(`/recommended-categories/${id}`)
}

export const updateRecommendedCategory = (id: number, data: UpdateRecommendedCategoryDto) => {
    return api.patch<RecommendedCategoryDto>(`/recommended-categories/${id}`, data)
}

export const deleteRecommendedCategory = (id: number) => {
    return api.delete<void>(`/recommended-categories/${id}`)
}