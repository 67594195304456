import {TextArea} from "../../components/TextArea";
import {Button} from "../../components/Button";
import {MomentFormat, utcToLocalFormat} from "../../plugins/moment";
import React from "react";
import {ShippingLine} from "../../types/order";

export interface OrderListMemoProps {
    shippingLine: ShippingLine;
    onSaveMemo: (id: number, memo: string) => void;
}

export const OrderListMemo = (props: OrderListMemoProps) => {
    const {shippingLine, onSaveMemo} = props;
    const [memo, setMemo] = React.useState<string>('');

    return (
        <>
            <TextArea className="h-24  bg-yellow-100"
                      defaultValue={shippingLine.adminMemo}
                      onChange={(e) => setMemo(e.target.value)}
            />
            <Button text="저장"
                    className="filled-gray-100 h-10 px-7 text-black"
                    onClick={() => onSaveMemo(shippingLine.id, memo)}
            />
            {(shippingLine.adminMemo && shippingLine.createdAt !== shippingLine.updatedAt) && (
                <div>
                    {utcToLocalFormat(
                        shippingLine.updatedAt.toLocaleString(),
                        MomentFormat.YYYYMMDDHmm
                    )}{' '}수정됨
                </div>
            )}
        </>
    )
}