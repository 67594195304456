import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/third_party/embedly.min.js';
// import "froala-editor/js/plugins/fullscreen.min.js"
// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/third_party/embedly.min.css';
// import "froala-editor/css/plugins/fullscreen.min.css";
import Froala from 'react-froala-wysiwyg';
import { uploadFile } from '../../api/fileAPI';
import { FileAbout } from '../../types/common';

type FroalaEditorProps = {
  defaultValue?: string;
  onChange: (model: any) => void;
  imageType: FileAbout;
};

export const FloalaEditor = (props: FroalaEditorProps) => {
  const ref = useRef({ editor: null });
  const [isFroalaInitialized, setIsFroalaInitialized] = useState(false);
  const [editor, setEditor] = useState<any>(undefined);
  const [model, setModel] = useState<string>('');
  const [loaded, setLoaded] = useState(false);

  const handleModelChange = (model: any) => {
    setModel(model);
    props.onChange(model);
  };

  useEffect(() => {
    if (!loaded && !!props.defaultValue) {
      setModel(props.defaultValue);
      setLoaded(true);
    }
  }, [props.defaultValue]);

  // Editor initialization
  useEffect(() => {
    setEditor(ref.current.editor);
    editor && setIsFroalaInitialized(true);
  }, [ref.current]);

  // Do after initialization
  useEffect(() => {
    if (isFroalaInitialized) {
      editor.html.set(model);
    }
  }, [isFroalaInitialized]);

  return (
    <div className="App">
      <Froala
        model={model}
        onModelChange={handleModelChange}
        tag="textarea"
        config={{
          key: 'eHE5C-11E3H3E2J2B5C3C3td1CGHNOa1TNSPH1e1J1VLPUUCVd1FC-22C4A3C3D2D4G2G2C3A2A2==',
          imageUpload: true,
          imageDefaultAlign: 'center',
          imageDefaultDisplay: 'block',
          imageDefaultWidth: '100%',
          imageMaxSize: 5 * 1024 * 1024,
          imageAllowedTypes: ['jpeg', 'jpg', 'png', 'gif'],
          videoDefaultAlign: 'center',
          videoDefaultDisplay: 'block',
          // imagePaste: false,
          // imagePasteProcess: true,
          events: {
            'image.beforeUpload': function (images: File[]) {
              if (images.length === 1) {
                images = [images[0]];
              }

              images.forEach((image) => {
                uploadFile(props.imageType, image)
                  .then((res) => {
                    // @ts-ignore
                    this.image.insert(res, null, null, this.image.get());
                  })
                  .catch((err) => {
                    console.error(err.response);
                  });
                return false;
              });
            },
          },
          attribution: false,
          placeholderText: 'Start typing...',
          toolbarButtons: {
            moreText: {
              buttons: [
                'bold',
                'italic',
                'underline',
                'strikeThrough',
                'subscript',
                'superscript',
                'fontFamily',
                'fontSize',
                'textColor',
                'backgroundColor',
                'inlineClass',
                'inlineStyle',
                'clearFormatting',
                'codeView',
              ],
            },
            moreParagraph: {
              buttons: [
                'alignLeft',
                'alignCenter',
                // "formatOLSimple",
                'alignRight',
                'alignJustify',
                'formatOL',
                'formatUL',
                'paragraphFormat',
                'paragraphStyle',
                'lineHeight',
                'outdent',
                'indent',
                'quote',
              ],
            },
            moreRich: {
              buttons: [
                'insertLink',
                'insertImage',
                'insertVideo',
                'emoticons',
                'insertTable',
                'fontAwesome',
                'specialCharacters',
                'embedly',
                'insertFile',
                'insertHR',
              ],
            },
            moreMisc: {
              buttons: [
                'undo',
                'redo',
                'fullscreen',
                'print',
                'getPDF',
                'spellChecker',
                'selectAll',
                'html',
                'help',
              ],
              align: 'right',
              buttonsVisible: 2,
            },
          },
          pluginsEnabled: [
            'table',
            'spell',
            'quote',
            'save',
            'quickInsert',
            'paragraphFormat',
            'paragraphStyle',
            'help',
            'draggable',
            'align',
            'link',
            'lists',
            'file',
            'image',
            'emoticons',
            'url',
            'video',
            'embedly',
            'colors',
            'entities',
            'inlineClass',
            'inlineStyle',
            'codeBeautif ',
            'spellChecker',
            'imageTUI',
            'fontFamily',
            'fontSize',
            'codeView',
          ],
        }}
      />
    </div>
  );
};

// Include special components if required.
// import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
// import FroalaEditorA from 'react-froala-wysiwyg/FroalaEditorA';
// import FroalaEditorButton from 'react-froala-wysiwyg/FroalaEditorButton';
// import FroalaEditorImg from 'react-froala-wysiwyg/FroalaEditorImg';
// import FroalaEditorInput from 'react-froala-wysiwyg/FroalaEditorInput';

// Render Froala Editor component.
