import {api} from "../plugins/axios";
import {Paged} from "../types/common";
import {Order, UpdateShippingLineDto} from "../types/order";
import {ProductType} from "../types/product";

export const getOrders = (query: string, page?: number, itemsPerPage?: number) => {
    const params = {
        page: page || 1,
        itemsPerPage: itemsPerPage || 30,
    };
    return api.get<Paged<Order>>(`/admin/orders?${query}`, {params});
}

export const getOrder = (id: string) => {
    return api.get<Order>(`/admin/orders/${id}`);
}

export const patchShippingLines = (ids: number[], data: UpdateShippingLineDto) => {
    return api.patch<void>(`/admin/orders/shippingLines/${ids.join(',')}`, data);
}

export const excelUpload = (file: File, type: ProductType) => {
    const url = type === ProductType.GENERAL ? `/admin/orders/excel/shippingLines` : '/admin/orders/excel/shippingLines/booksen';
    const formData = new FormData();
    formData.append('file', file);
    return api.post<void>(url, formData, {headers: {'Content-Type': 'multipart/form-data'}});
}

export const exelDownload = (shippingLineIds: number[]) => {
    window.open(`${process.env.REACT_APP_API_URL}/admin/orders/excel/shippingLines/${shippingLineIds}`);
}

export const sendOrdersToBooksen = (orderCode: string) => {
    return api.post<void>(`/booksen/orders/${orderCode}`, {});
}
