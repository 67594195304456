import React, { useEffect, useState } from 'react';
import { SectionDto } from '../../types/sections';
import { getSections, patchSection } from '../../api/sectionAPI';
import { AdminH1 } from './AdminH1';
import { Card } from '../../components/Card';
import { Table } from '../../components/Table';
import { Checkbox } from '../../components/Checkbox';
import Reorderable from './Reorderable';
import { toast } from 'react-toastify';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { Link } from 'react-router-dom';
import { Button } from '../../components/Button';
import DeleteBrandModal from './modal/DeleteBrandModal';
import DeleteSectionModal from './modal/DeleteSectionModal';

export type MainSectionsProps = {
  type: 'MAIN' | 'LIFE';
};

export const Sections = (props: MainSectionsProps) => {
  const [sections, setSections] = React.useState<SectionDto[]>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [selectedSection, setSelectedSection] = useState<SectionDto | null>(
    null
  );
  const handleDelete = (section: SectionDto) => {
    setSelectedSection(section);
    setIsDeleteModalOpen(true);
  };

  const getList = () => {
    getSections(props.type)
      .then((res) => {
        const list = res.data.sort((a, b) => a.order - b.order);
        setSections(list);
      })
      .catch((err) => console.error(err.response));
  };

  const showOrHide = (id: number, isShow: boolean) => {
    patchSection(id, { isShow })
      .then((res) => getList())
      .catch((err) => console.error(err.response));
  };

  useEffect(() => getList(), []);

  return (
    <>
      <div className={'flex items-center justify-between'}>
        <AdminH1>
          {props.type === 'MAIN'
            ? '메인 관리 / 노출 순위 관리'
            : '아크앤샵 메인관리'}
        </AdminH1>
        <Button
          text="추가하기"
          className="filled-black h-10 text-sm"
          to={`/admin/section/add?type=${props.type}`}
        />
      </div>
      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>노출여부</Table.Th>
              <Table.Th>노출 우선순위</Table.Th>
              <Table.Th>최근 업데이트 일자</Table.Th>
              <Table.Th>타이틀</Table.Th>
              <Table.Th>템플릿명</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {sections.map((contents, index) => (
              <Table.Row key={index}>
                <Table.Td>
                  <Checkbox
                    checked={contents.isShow}
                    onChange={() => showOrHide(contents.id, !contents.isShow)}
                  />
                </Table.Td>
                <Table.Td>
                  <Reorderable
                    order={contents.order}
                    onReordered={(order) => {
                      patchSection(contents.id, { order })
                        .then(() => {
                          toast.success('순서가 변경되었습니다');
                          getList();
                        })
                        .catch((err) => console.error(err.response));
                    }}
                  />
                </Table.Td>
                <Table.Td>
                  {utcToLocalFormat(
                    contents.updatedAt.toLocaleString(),
                    MomentFormat.YYYYMMDDHmm
                  )}
                </Table.Td>
                <Table.Td>
                  <div
                    dangerouslySetInnerHTML={{ __html: contents.title }}
                    className={'text-black'}
                  />
                </Table.Td>
                <Table.Td>{contents.type}</Table.Td>
                <Table.Td className="text-right">
                  <Link
                    className="rounded-md font-normal text-indigo-600 hover:text-indigo-900"
                    to={`/admin/contents/${contents.type}/${contents.id}?type=${
                      props.type === 'MAIN' ? 'MINI' : 'LIFE'
                    }`}
                  >
                    상세보기
                  </Link>
                  <Button
                    className={'h-auto text-red-500'}
                    onClick={() => handleDelete(contents)}
                  >
                    삭제
                  </Button>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Card>
      <DeleteSectionModal
        getList={getList}
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        section={selectedSection}
      />
    </>
  );
};
