import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { Button } from '../../../components/Button';
import { Icon } from '../../../components/Icon';
import { Search } from '../../../components/Search';
import { TextField } from '../../../components/TextField';

interface CategoryProductModalProps {
  open: boolean;
  onClose: () => void;
}

export const CategoryProductModal: React.FC<CategoryProductModalProps> = ({
  open,
  onClose,
}) => {
  if (!open) return <></>;
  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto bg-gray-500 bg-opacity-75 transition-opacity"
          onClose={onClose}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="my-8 inline-block w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex items-center justify-between">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    상품 추가 / 수정
                  </Dialog.Title>
                  <Icon.X onClick={onClose} className="cursor-pointer" />
                </div>

                <div className="my-6 space-y-4">
                  <TextField label="노출 우선순위" className="h-12" />
                  <div>
                    <div className="label">상품 검색</div>
                    <Search height={14} />
                  </div>
                </div>

                <div className="mt-4 space-y-2">
                  <Button
                    text="저장하기"
                    className="filled-black h-12 w-full text-sm"
                  />
                  <Button
                    text="취소하기"
                    className="filled-gray-200 h-12 w-full text-sm text-gray-800"
                    onClick={onClose}
                  />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
