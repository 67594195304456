import React from 'react';
import {useHistory} from 'react-router-dom';
import {Button} from '../../../components/Button';
import {TextField} from '../../../components/TextField';
import {AdminH1} from '../../components/AdminH1';
import {postEventTag} from "../../../api/eventTagAPI";
import {toast} from "react-toastify";

export const ProductTagAdd = () => {
    const {goBack} = useHistory();
    const [name, setName] = React.useState<string>('');

    const makeTag = () => {
        postEventTag({name}).then(() => {
            toast.success('저장되었습니다.');
            goBack();
        }).catch(err => console.error(err.response));
    }

    return (
        <>
            <AdminH1>태그 추가</AdminH1>
            <TextField label="태그명" autoFocus={true} onChange={e => setName(e.target.value)}/>

            <div className="fixed bottom-0 -ml-8 w-full space-x-4 border-t bg-white py-2 pl-10">
                <Button
                    text="취소"
                    className="outlined-black h-12 px-6"
                    onClick={() => goBack()}
                />
                <Button text="저장" className="filled-black h-12 px-6" onClick={makeTag} disabled={name === ''}/>
            </div>
        </>
    );
};
