import React, {useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Card} from '../../../components/Card';
import {Pagination} from '../../../components/Pagination';
import {Table} from '../../../components/Table';
import {AdminH1} from '../../components/AdminH1';
import {Button} from '../../../components/Button';
import {getRecommendedCategories, updateRecommendedCategory} from "../../../api/recommendedCategoryAPI";
import {RecommendedCategoryDto} from "../../../types/recommendedCategory";
import {Paged} from '../../../types/common';
import queryString from "query-string";
import {toast} from "react-toastify";
import {CopyToClipboard} from "react-copy-to-clipboard";
import Reorderable from "../../components/Reorderable";

export const RecommendCategory = () => {
    const {location} = useHistory();
    const {page, limit} = queryString.parse(location.search);
    const [recommendedCategories, setRecommendedCategories] = React.useState<Paged<RecommendedCategoryDto>>({items: []});

    const getList = () => {
        getRecommendedCategories(`order[order]=ASC`, Number(page), Number(limit))
            .then(res => setRecommendedCategories(res.data))
            .catch(err => console.error(err.response));
    }

    useEffect(() => {
        getList();
    }, [])

    return (
        <>
            <AdminH1>추천 카테고리 관리</AdminH1>
            <div>
                <Button
                    text="추천 카테고리 추가"
                    className="filled-black h-12"
                    to="/admin/recommend/category/add"
                />
            </div>

            <Card>
                <Table>
                    <Table.Head>
                        <Table.Row>
                            <Table.Th>노출 우선순위</Table.Th>
                            <Table.Th>카테고리명</Table.Th>
                            <Table.Th>클릭시 URL</Table.Th>
                            <Table.Th></Table.Th>
                        </Table.Row>
                    </Table.Head>
                    <Table.Body>
                        {recommendedCategories.items.length === 0 && (
                            <Table.Row>
                                <Table.Td colSpan={4}>데이터가 없습니다.</Table.Td>
                            </Table.Row>
                        )}
                        {recommendedCategories.items.map((category, index) => (
                            <Table.Row key={index}>
                                <Table.Td>
                                    <Reorderable order={category.order} onReordered={(order => {
                                        updateRecommendedCategory(category.id, {order})
                                            .then(() => {
                                                toast.success('순서가 변경되었습니다');
                                                getList();
                                            })
                                            .catch(err => console.error(err.response));
                                    })}/>
                                </Table.Td>
                                <Table.Td>{category.name}</Table.Td>
                                <Table.Td>
                                    <CopyToClipboard text={category.url} onCopy={() => toast.info('복사되었습니다.')}>
                                        <span className={'text-blue-500 underline cursor-pointer'}>{category.url}</span>
                                    </CopyToClipboard>
                                </Table.Td>
                                <Table.Td className="text-right">
                                    <Link
                                        className="rounded-md font-normal text-indigo-600 hover:text-indigo-900"
                                        to={`/admin/recommend/category/${category.id}`}
                                    >
                                        상세보기
                                    </Link>
                                </Table.Td>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>

                <Pagination>
                    <Pagination.Label count={recommendedCategories.items.length}
                                      total={recommendedCategories.pagination?.totalItemCount!}/>
                    <Pagination.Nav basePath="/admin/recommend/category"
                                    total={recommendedCategories.pagination?.totalItemCount!}/>
                </Pagination>
            </Card>
        </>
    );
};
