import {Button} from "../../../components/Button";
import {Card} from "../../../components/Card";
import {Table} from "../../../components/Table";
import moment from "moment/moment";
import {MomentFormat} from "../../../plugins/moment";
import {Pagination} from "../../../components/Pagination";
import React, {useEffect, useState} from "react";
import {UserManageDetailDto} from "../../../types/user";
import {Paged} from "../../../types/common";
import {MileageHistoryDto} from "../../../types/mileageHistory";
import {useHistory} from "react-router";
import {MileageModal} from "../modal/MileageModal";
import queryString from "query-string";
import {getMileageHistories} from "../../../api/mileageHistoryAPI";
import {users} from "../../../dummies";

type UserMileageTableProps = {
    user: UserManageDetailDto;
}

export const UserMileageTable = (props: UserMileageTableProps) => {
    const history = useHistory();
    const query = queryString.parse(history.location.search);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [mileageHistories, setMileageHistories] = useState<Paged<MileageHistoryDto>>({items: []});

    const getHistories = () => {
        getMileageHistories(props.user.id, Number(query.page), Number(query.limit)).then(res => setMileageHistories(res.data));
    }

    useEffect(() => {
        getHistories();
    }, [history.location.search]);

    return (
        <>
            <MileageModal open={isOpen}
                          onClose={() => {
                              getHistories();
                              setIsOpen(false);
                          }}
                          userId={props.user.id}/>
            <div className="pt-5">
                <div className="my-3 flex items-center justify-between">
                    <div className="flex items-center space-x-4">
                        <p>보유마일리지</p>
                        <p className="text-xl font-semibold">
                            {props.user?.mileageAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </p>
                    </div>
                    <Button
                        text="관리자 지급하기"
                        className="filled-black h-10 text-sm"
                        onClick={() => setIsOpen(true)}
                    />
                </div>
                <Card>
                    <Table>
                        <Table.Head>
                            <Table.Row>
                                <Table.Th>ID</Table.Th>
                                <Table.Th>생성일</Table.Th>
                                {/*<Table.Th>마일리지</Table.Th>*/}
                                <Table.Th>관리자메모</Table.Th>
                                <Table.Th>사용</Table.Th>
                                <Table.Th>적립</Table.Th>
                                <Table.Th>남은 마일리지</Table.Th>
                            </Table.Row>
                        </Table.Head>
                        <Table.Body>
                            {mileageHistories.items.length === 0 && (
                                <Table.Row>
                                    <Table.Td>데이터가 없습니다.</Table.Td>
                                </Table.Row>
                            )}
                            {mileageHistories.items.map((mileage) => (
                                <Table.Row key={mileage.id}>
                                    <Table.Td>{mileage.id}</Table.Td>
                                    <Table.Td>
                                        {/* 리브로에서 내역 불러오는 것 때문에 9시간을 빼줄수밖에 없음 */}
                                        {moment(mileage.date).subtract(9, 'hour').format(MomentFormat.YYYYMMDDHmm)}
                                    </Table.Td>
                                    {/*<Table.Td>{mileage.kind}</Table.Td>*/}
                                    <Table.Td>{mileage.memo}</Table.Td>
                                    <Table.Td className="text-red-500">
                                        -{(mileage.givenMileage < 0 ? mileage.givenMileage : mileage.useMileage > 0 ? mileage.useMileage : 0)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                        .replace('-', '')}
                                        p
                                    </Table.Td>
                                    <Table.Td className="text-blue-500">
                                        +{(mileage.givenMileage > 0 ? mileage.givenMileage : mileage.useMileage < 0 ? mileage.useMileage : 0)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                        .replace('-', '')}
                                        p
                                    </Table.Td>
                                    <Table.Td>
                                        {mileage.remainMileage
                                            .toString()
                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        p
                                    </Table.Td>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>

                    <Pagination>
                        <Pagination.Label count={mileageHistories.items.length}
                                          total={mileageHistories.pagination?.totalItemCount!}/>
                        <Pagination.Nav basePath={history.location.pathname}
                                        total={mileageHistories.pagination?.totalItemCount!}/>
                    </Pagination>
                </Card>
            </div>
        </>
    )
}