import {PromotionKindKeySelector} from "../../components/PromotionKindKeySelector";
import {TextField} from "../../../components/TextField";
import {TextArea} from "../../../components/TextArea";
import React, {useEffect} from "react";
import {Button} from "../../../components/Button";
import {CreatePromotionDto} from "../../../types/promotion";
import {addPromotion} from "../../../api/promotionAPI";
import {useHistory} from "react-router-dom";
import moment from "moment";
import {toast} from "react-toastify";

export const PromotionAdd = () => {
    const history = useHistory();
    const [promotion, setPromotion] = React.useState<CreatePromotionDto>({
        kindKey: '',
        title: '',
        startAt: '',
        content: '',
    } as CreatePromotionDto);

    const saveThis = () => {
        addPromotion(promotion!).then(res => {
            toast.success('프로모션이 생성되었습니다.');
            history.push(`/admin/promotion/${res.data.id}`);
        });
    }

    return (
        <>
            <div className="grid grid-cols-2 gap-4">
                <PromotionKindKeySelector onSelect={key => setPromotion({...promotion, kindKey: key})}/>
                <TextField label="프로모션명"
                           onChange={e => setPromotion({...promotion, title: e.target.value})}
                />
                <TextField label="노출 시작일" type="date"
                           onChange={e => setPromotion({...promotion, startAt: moment(e.target.value).format()})}
                />
                <TextField label="노출 종료일" type="date"
                           onChange={e => setPromotion({...promotion, finishAt: moment(e.target.value).format()})}
                />
                <div className="col-span-2">
                    <TextArea label="상세설명"
                              className="h-32"
                              onChange={e => setPromotion({...promotion, content: e.target.value})}
                    />
                </div>
            </div>
            <div className="fixed bottom-0 -ml-8 w-full space-x-4 border-t bg-white py-2 pl-10">
                <Button text="저장" onClick={saveThis} className="filled-black h-12 px-6"/>
            </div>
        </>
    )
}