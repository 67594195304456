import React from 'react';
import BannerTemplate from "../../components/templates/BannerTemplate";
import {BannerType} from "../../../types/banner";
import useQuery from '../../../hooks/useQuery';

export const ArcBannerAdd = () => {
    let query = useQuery();
    const bannerType = query.get('banner') as BannerType;
    const curationId = Number(query.get('curationId') as string);
    const postingId = Number(query.get('postingId') as string);
    return <BannerTemplate type={'ADD'} bannerType={bannerType} curationId={curationId} postingId={postingId}/>;
};
