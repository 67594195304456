import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { TextField } from '../../../components/TextField';
import { AdminH1 } from '../../components/AdminH1';
import {
  deleteCompany,
  getCompany,
  patchCompany,
  postCompany,
} from '../../../api/companyAPI';
import { toast } from 'react-toastify';
import { TextArea } from '../../../components/TextArea';
import { uploadFile } from '../../../api/fileAPI';
import { FileAbout } from '../../../types/common';
import { useForm } from 'react-hook-form';
import { CreateCompanyDto } from '../../../types/companies';
import { ReactComponent as FileUploadImg } from '../../../assets/svg/file-upload.svg';
import { useQuery } from 'react-query';
import DeleteBrandModal from '../../components/modal/DeleteBrandModal';

//   id 값이 존재할 경우 수정, 존재하지 않을 경우 추가
export const NormalBrand = () => {
  const { id } = useParams<{ id: string }>();
  const { goBack } = useHistory();
  const form = useForm<CreateCompanyDto>();

  const [pcImage, setPcImage] = React.useState<File | null | undefined>();
  const [mobileImage, setMobileImage] = React.useState<
    File | null | undefined
  >();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const handleClose = () => {
    setIsDeleteModalOpen(false);
  };

  const { data } = useQuery(['company', id], () => getCompany(Number(id)), {
    enabled: !!id,
    onSuccess: (result) => {
      const { data } = result;
      form.setValue('name', data.name);
      form.setValue('description', data.description);
      form.setValue('pcCompanyImageUrl', data.pcCompanyImageUrl);
      form.setValue('mobileCompanyImageUrl', data.mobileCompanyImageUrl);
    },
  });
  const handleDelete = async () => {
    if (!data?.data) return;
    try {
      const result = await deleteCompany(data?.data.id);
      if (result.status === 200) {
        toast('성공적으로 삭제되었습니다.', {
          type: 'success',
        });
        setIsDeleteModalOpen(false);
        goBack();
      }
    } catch (e) {
      toast('삭제 중 오류가 발생했습니다.', {
        type: 'error',
      });
    }
  };
  const onAdd = async (data: any) => {
    const { name, description } = data;
    if (name === '' || description === '' || !pcImage || !mobileImage) {
      toast('모든 항목을 입력해주세요.', {
        type: 'error',
      });
      return;
    }
    try {
      data.mobileCompanyImageUrl = await uploadFile(
        FileAbout.COMPANY_MOBILE,
        mobileImage
      );
      data.pcCompanyImageUrl = await uploadFile(FileAbout.COMPANY_PC, pcImage);
      postCompany(data)
        .then(() => {
          toast.success('추가되었습니다.');
          goBack();
        })
        .catch((err) => console.error(err.response));
    } catch (e) {
      toast('업체 추가 도중 오류가 발생했습니다.', {
        type: 'error',
      });
    }
  };

  const onEdit = async (data: any) => {
    const { name, description } = data;
    if (name === '' || description === '') {
      toast('업체명과 업체설명은 필수값입니다.', {
        type: 'error',
      });
      return;
    }
    try {
      if (pcImage) {
        data.pcCompanyImageUrl = await uploadFile(
          FileAbout.COMPANY_PC,
          pcImage
        );
      }
      if (mobileImage) {
        data.mobileCompanyImageUrl = await uploadFile(
          FileAbout.COMPANY_MOBILE,
          mobileImage
        );
      }
      patchCompany(Number(id), data)
        .then(() => {
          toast.success('수정되었습니다.');
          goBack();
        })
        .catch((err) => console.error(err.response));
    } catch (e) {
      toast('업체 수정 도중 오류가 발생했습니다.', {
        type: 'error',
      });
    }
  };
  return (
    <>
      <form
        onSubmit={id ? form.handleSubmit(onEdit) : form.handleSubmit(onAdd)}
      >
        <div className={'flex items-center justify-between'}>
          <AdminH1>업체 {id ? '수정' : '추가'}</AdminH1>
          {id && (
            <Button
              onClick={() => setIsDeleteModalOpen(true)}
              className="filled-black h-12 px-6"
            >
              삭제
            </Button>
          )}
        </div>
        <div className={'mt-5'}>
          <TextField
            label="업체명"
            autoFocus={true}
            {...form.register('name')}
          />
          <TextArea
            label="업체설명"
            className="h-32"
            {...form.register('description')}
          />
          <div className="col-span-3 mt-10 text-lg font-semibold">
            업체 대표 이미지
          </div>
          <div className={'flex flex-col gap-10 pb-20'}>
            <div>
              <label htmlFor="pcBanner">
                <div className="mb-2 text-sm text-gray-800 ">PC 이미지</div>

                {pcImage || form.watch('pcCompanyImageUrl') ? (
                  <>
                    <div className={'aspect-[3/1] w-full'}>
                      <img
                        src={
                          pcImage
                            ? URL.createObjectURL(pcImage)
                            : form.watch('pcCompanyImageUrl')
                        }
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          objectPosition: 'top',
                        }}
                        alt="pc_image"
                      />
                    </div>
                  </>
                ) : !pcImage ? (
                  <div className="border-grey-5 flex aspect-[3/1] w-full flex-col items-center justify-center space-y-1 border-2 border-dashed py-10">
                    <FileUploadImg />
                    <div className="w-3/5 text-center text-xs text-gray-500">
                      이미지를 업로드 해주세요.
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </label>
              <div className="mt-4 flex w-full gap-4">
                <Button
                  className={'h-12 border py-3'}
                  onClick={() => {
                    setPcImage(null);
                    form.setValue('pcCompanyImageUrl', '');
                  }}
                >
                  삭제
                </Button>
              </div>
              <input
                type="file"
                id="pcBanner"
                className="hidden"
                accept="image/*"
                onChange={(e) => {
                  e.target.validity.valid &&
                    setPcImage(e.target.files?.item(0));
                }}
              />
            </div>
            <div>
              <label htmlFor="mobileBanner">
                <div className="mb-2 text-sm text-gray-800 ">Mobile 이미지</div>
                {mobileImage || form.watch('mobileCompanyImageUrl') ? (
                  <>
                    <div className={'aspect-[2/2.5] w-[400px]'}>
                      <img
                        src={
                          mobileImage
                            ? URL.createObjectURL(mobileImage)
                            : form.watch('mobileCompanyImageUrl')
                        }
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          objectPosition: 'top',
                        }}
                        alt="mobile_image"
                      />
                    </div>
                  </>
                ) : !mobileImage ? (
                  <div className="border-grey-5 flex aspect-[2/2.5] w-[400px] flex-col items-center justify-center space-y-1 border-2 border-dashed py-10">
                    <FileUploadImg />
                    <div className="w-3/5 text-center text-xs text-gray-500">
                      이미지를 업로드 해주세요.
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </label>
              <div className="mt-4 flex w-full gap-4">
                <Button
                  className={'h-12 border py-3'}
                  onClick={() => {
                    setMobileImage(null);
                    form.setValue('mobileCompanyImageUrl', '');
                  }}
                >
                  삭제
                </Button>
              </div>
              <input
                type="file"
                id="mobileBanner"
                className="hidden"
                accept="image/*"
                onChange={(e) => {
                  e.target.validity.valid &&
                    setMobileImage(e.target.files?.item(0));
                }}
              />
            </div>
          </div>
        </div>
        <div className="fixed bottom-0 -ml-8 w-full space-x-4 border-t bg-white py-2 pl-10">
          <Button
            type={'button'}
            text="취소"
            className="outlined-black h-12 px-6"
            onClick={() => goBack()}
          />
          <Button
            type={'submit'}
            text={id ? '수정' : '저장'}
            className="filled-black h-12 px-6"
          />
        </div>
      </form>
      <DeleteBrandModal
        open={isDeleteModalOpen}
        onClose={handleClose}
        brand={data?.data}
        onDelete={handleDelete}
      />
    </>
  );
};
