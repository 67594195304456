import { api } from '../plugins/axios';
import {
  CreateGridCardProductDto,
  GridCardProductDto,
  GridCardProductType,
  UpdateGridCardProductDto,
} from '../types/gridCardProduct';

export const postGridCardProduct = (data: CreateGridCardProductDto) => {
  return api.post<GridCardProductDto>('/grid-card-products', data);
};

export const getGridCardProducts = (
  type: GridCardProductType,
  position: 'MAIN' | 'LIFE'
) => {
  return api.get<GridCardProductDto[]>('/grid-card-products/', {
    params: { type, position },
  });
};

export const getGridCardProduct = (id: number) => {
  return api.get<GridCardProductDto>('/grid-card-products/' + id);
};

export const patchGridCardProduct = (
  id: number,
  data: UpdateGridCardProductDto
) => {
  return api.patch<GridCardProductDto>('/grid-card-products/' + id, data);
};

export const deleteGridCardProduct = (id: number) => {
  return api.delete<void>('/grid-card-products/' + id);
};
