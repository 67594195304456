import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '../../../../components/Button';
import { Card } from '../../../../components/Card';
import { Pagination } from '../../../../components/Pagination';
import { Table } from '../../../../components/Table';
import { TextArea } from '../../../../components/TextArea';
import { TextField } from '../../../../components/TextField';
import { mainGridData } from '../../../../dummies';
import useSections from '../../../../hooks/useSections';
import useGridCards from '../../../../hooks/useGridCards';
import { GridCardProductType } from '../../../../types/gridCardProduct';
import queryString from 'query-string';

export const GridType2 = () => {
  const history = useHistory();
  const query = queryString.parse(history.location.search);
  const { form, onsubmit, goBack, products, addProduct, getData } =
    useSections();

  const { gridCardProducts, setGridCardProducts, onDelete, getList } =
    useGridCards(GridCardProductType.GRID_CARD_SECTION_2);

  return (
    <form onSubmit={form.handleSubmit(onsubmit)}>
      <div className="grid grid-cols-3 gap-5">
        <TextField label="템플릿명" disabled value="GridCardSection 2" />
        <TextField label="타이틀" {...form.register('title')} />
        <TextField
          label="템플릿 노출 우선순위"
          type="number"
          {...form.register('order')}
        />
        <div className="col-span-3">
          <TextArea
            label="상세설명"
            className="h-32"
            {...form.register('description')}
          />
        </div>
      </div>

      <div className="my-6 h-3 w-full bg-gray-50" />

      <Button
        type={'button'}
        text="카드 추가하기"
        className="filled-black mb-4 h-12 w-max"
        to={`/admin/main/contents/5/product/add?type=${query.type}`}
      />
      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>노출 우선순위</Table.Th>
              <Table.Th>타이틀</Table.Th>
              <Table.Th>상세설명</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {gridCardProducts.length === 0 && (
              <Table.Row>
                <Table.Td colSpan={4}>데이터가 없습니다.</Table.Td>
              </Table.Row>
            )}
            {gridCardProducts.map((main) => (
              <Table.Row key={main.id}>
                <Table.Td>{main.order}</Table.Td>
                <Table.Td>{main.title}</Table.Td>
                <Table.Td>{main.description}</Table.Td>

                <Table.Td className="space-x-3 text-right">
                  <button
                    className="rounded-md font-normal text-red-600 hover:text-red-900"
                    type={'button'}
                    onClick={() => onDelete(main.id)}
                  >
                    삭제
                  </button>
                  <Link
                    className="rounded-md font-normal text-indigo-600 hover:text-indigo-900"
                    to={`/admin/main/contents/4/product/${main.id}`}
                  >
                    수정
                  </Link>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Card>

      <div className="fixed bottom-0 -ml-8 w-full space-x-4 border-t bg-white py-2 pl-10">
        <Button
          type={'button'}
          text="취소"
          className="outlined-black h-12 px-6"
          onClick={() => goBack()}
        />
        <Button
          type={'submit'}
          text="저장"
          className="filled-black h-12 px-6"
        />
      </div>

      <div className="p-10" />
    </form>
  );
};
