import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {TextArea} from '../../../components/TextArea';
import {TextField} from '../../../components/TextField';
import {Button} from '../../../components/Button';
import {PostProductAModal} from '../../components/modal/PostProductAModal';
import {useForm} from "react-hook-form";
import {CreatePostingDto, PostingType} from "../../../types/posting";
import {postPosting} from "../../../api/postingAPI";
import {ReactComponent as FileUploadImg} from "../../../assets/svg/file-upload.svg";
import {uploadFile} from "../../../api/fileAPI";
import {FileAbout} from "../../../types/common";
import {toast} from "react-toastify";

export const AtypePostAdd = () => {
    const [pcBanner, setPcBanner] = useState<File | null | undefined>();
    const [mobileBanner, setMobileBanner] = useState<File | null | undefined>();
    const {goBack, push} = useHistory();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const form = useForm<CreatePostingDto>();

    const onSubmit = async (data: CreatePostingDto) => {
        data.type = PostingType.A;
        if (!!pcBanner) data.pcImageUrl = await uploadFile(FileAbout.BANNER_PC, pcBanner);
        if (!!mobileBanner) data.mobileImageUrl = await uploadFile(FileAbout.BANNER_MOBILE, mobileBanner);
        postPosting(data).then(res => {
            toast.success("등록이 완료되었습니다. 상품을 추가해 주세요.");
            push(`/admin/post/${res.data.type.toLowerCase()}/${res.data.id}`);
        })
    };

    return (
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <PostProductAModal open={isOpen} onClose={() => setIsOpen(false)}/>
            <div className="grid grid-cols-3 gap-5">
                <TextField label="타이틀" {...form.register('title', {required: true})}/>
                {/*<TextField label="url" disabled/>*/}
                <div className="col-span-3 col-start-1">
                    <TextArea label="상세설명" className="h-32" {...form.register('description', {required: true})}/>
                </div>

                <div>
                    <label htmlFor="pcBanner">
                        <div className="mb-2 text-sm text-gray-800 ">
                            PC 상단배너 이미지
                        </div>

                        {pcBanner ? (
                            <>
                                <img src={URL.createObjectURL(pcBanner)} alt=""/>
                                <div className="mt-4 w-full">
                                    <Button className="filled-black h-12 py-3">
                                        <label htmlFor="pcBanner" className="w-full cursor-pointer">
                                            수정하기
                                        </label>
                                    </Button>
                                </div>
                            </>
                        ) : !pcBanner ? (
                            <div
                                className="border-grey-5 flex w-full flex-col items-center justify-center space-y-1 border-2 border-dashed py-40">
                                <FileUploadImg/>
                                <div className="w-3/5 text-center text-xs text-gray-500">
                                    상세 이미지 첨부하기
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </label>
                    <input
                        type="file"
                        id="pcBanner"
                        className="hidden"
                        accept="image/*"
                        onChange={(e) => {
                            e.target.validity.valid && setPcBanner(e.target.files?.item(0));
                        }}
                    />
                </div>

                <div>
                    <label htmlFor="mobileBanner">
                        <div className="mb-2 text-sm text-gray-800 ">
                            Mobile 상단배너 이미지
                        </div>

                        {mobileBanner ? (
                            <>
                                <img src={URL.createObjectURL(mobileBanner)} alt=""/>
                                <div className="mt-4 w-full">
                                    <Button className="filled-black h-12 py-3">
                                        <label
                                            htmlFor="mobileBanner"
                                            className="w-full cursor-pointer"
                                        >
                                            수정하기
                                        </label>
                                    </Button>
                                </div>
                            </>
                        ) : !mobileBanner ? (
                            <div
                                className="border-grey-5 flex w-full flex-col items-center justify-center space-y-1 border-2 border-dashed py-40">
                                <FileUploadImg/>
                                <div className="w-3/5 text-center text-xs text-gray-500">
                                    상세 이미지 첨부하기
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </label>
                    <input
                        type="file"
                        id="mobileBanner"
                        className="hidden"
                        accept="image/*"
                        onChange={(e) => {
                            e.target.validity.valid &&
                            setMobileBanner(e.target.files?.item(0));
                        }}
                    />
                </div>
            </div>

            <div className="fixed bottom-0 -ml-8 w-full space-x-4 border-t bg-white py-2 pl-10">
                <Button type={'button'}
                        text="취소"
                        className="outlined-black h-12 px-6"
                        onClick={() => goBack()}
                />
                <Button type={'submit'} text="저장" className="filled-black h-12 px-6"/>
            </div>

            <div className="pt-14"/>
        </form>
    );
};
