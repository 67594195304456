import React, { useEffect } from 'react';
import { KeywordType, UpdateKeywordDto } from '../types/keyword';
import { getKeywords, updateKeywords } from '../api/keywordAPI';
import { toast } from 'react-toastify';

const useKeyword = (type: KeywordType) => {
  const [keywords, setKeywords] = React.useState<UpdateKeywordDto[]>([]);
  const update = () => {
    const data: UpdateKeywordDto[] = [];
    keywords.forEach((keyword, index) => {
      data.push({
        id: keyword.id,
        keyword: keyword.keyword,
        type: type,
        order: index + 1,
      });
    });
    updateKeywords(data)
      .then(() => {
        if (type === KeywordType.SEARCH) {
          toast.success('저장되었습니다.');
        }
        getItems();
      })
      .catch((err) => console.error(err.response));
  };

  const getItems = () => {
    getKeywords(type)
      .then((res) => {
        while (res.data.length < 30) {
          res.data.push({
            type: KeywordType.SEARCH,
            keyword: '',
            order: res.data.length + 1,
          });
        }
        setKeywords(res.data);
      })
      .catch((err) => console.error(err.response));
  };

  useEffect(() => {
    getItems();
  }, []);

  return {
    keywords,
    setKeywords,
    update,
  };
};

export default useKeyword;
