import React from "react";

type InPageTabProps = {
    tabs: [
        { name: string, selected: boolean, onClick: () => void },
        { name: string, selected: boolean, onClick: () => void },
    ]
}

export const InPageTab = (props: InPageTabProps) => {
    return (
        <div className={'flex mb-5'}>
            {props.tabs.map((tab, index) => (
                <div key={index}
                     className={`border-b-2 p-4 text-lg cursor-pointer ${tab.selected ? 'border-slate-900 font-semibold' : 'border-slate-100 font-normal'}`}
                     onClick={tab.onClick}
                >
                    <h3>{tab.name}</h3>
                </div>
            ))}
            <div className={'border-b-2 border-slate-100 flex-1'}/>
        </div>
    )
}