import React, { useEffect } from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Card} from '../../../components/Card';
import {Pagination} from '../../../components/Pagination';
import {Table} from '../../../components/Table';
import {AdminH1} from '../../components/AdminH1';
import { getCurrentOfKey, getKindKeys, getPromotions } from '../../../api/promotionAPI';
import {PromotionDto} from '../../../types/promotion';
import {Paged} from '../../../types/common';
import queryString from 'query-string';
import {MomentFormat, utcToLocalFormat} from '../../../plugins/moment';
import moment from 'moment';

export const PromotionList = () => {
    const history = useHistory();
    const query = queryString.parse(history.location.search);
    const [keyCurrentSet, setKeyCurrentSet] = React.useState<{ [key: string]: PromotionDto | null }>({});
    const [promotions, setPromotions] = React.useState<Paged<PromotionDto>>({
        items: [],
    });

    const promotionStatus = React.useCallback((promotion: PromotionDto) => {
        const currentPromoOfKey = keyCurrentSet[promotion.kindKey];
        const isStarted = moment(promotion.startAt) < moment();
        // const isFinished = moment(promotion.finishAt) < moment();

        if (currentPromoOfKey && promotion.id === currentPromoOfKey.id) {
            return '진행중'; // 서버가 진행중이라고 찍어준건 무조건 진행중.
        } else {
            // 어차피 이 프로모션이 진행중은 아니므로
            // 기간 시작시점을 기준으로 시작시간 이후면 졸료로 판별하고
            // 시작 이전이면 준비중으로 판단합니다.
            return isStarted ? '종료됨' : '준비중';
        }
    }, [keyCurrentSet])

    useEffect(() => {
        getPromotions('order[startAt]=DESC', Number(query.page), Number(query.limit)).then((res) =>
            setPromotions(res.data)
        );
        getKindKeys().then(res => {
            const keys = res.data.items;
            Promise
              .all(keys.map(key => getCurrentOfKey(key).then<[string, PromotionDto]>(res => [key, res.data]).catch<[string, null]>(() => [key, null])))
              .then(key_current_list => {
                const keyCurrentSet = key_current_list.reduce<{ [key: string]: PromotionDto | null }>((set, key_current) => {
                    set[key_current[0]] = key_current[1];
                    return set;
                }, {} as any);
                setKeyCurrentSet(keyCurrentSet);
              })
        });
    }, [history.location]);

    return (
        <>
            <AdminH1>프로모션 관리</AdminH1>
            <div>
                <button type="button" className="button filled-black h-12"
                        onClick={() => history.push('/admin/promotion/add')}
                >
                    <p>프로모션 추가하기</p>
                </button>
            </div>
            <Card>
                <Table>
                    <Table.Head>
                        <Table.Row>
                            <Table.Th>ID</Table.Th>
                            <Table.Th>프로모션명</Table.Th>
                            <Table.Th>유형</Table.Th>
                            <Table.Th>상태</Table.Th>
                            <Table.Th>시작</Table.Th>
                            <Table.Th>종료</Table.Th>
                            <Table.Th></Table.Th>
                        </Table.Row>
                    </Table.Head>
                    <Table.Body>
                        {promotions.items.map((e) => (
                            <Table.Row id={`promotion-${e.id}`} key={e.id}>
                                <Table.Td>{e.id}</Table.Td>
                                <Table.Td>{e.title}</Table.Td>
                                <Table.Td>{e.kindKey}</Table.Td>
                                <Table.Td>{promotionStatus(e)}</Table.Td>
                                <Table.Td>
                                    {e.startAt
                                        ? utcToLocalFormat(
                                            e.startAt.toLocaleString(),
                                            MomentFormat.YYYYMMDDHmm
                                        )
                                        : '-'}
                                </Table.Td>
                                <Table.Td>
                                    {e.finishAt
                                        ? utcToLocalFormat(
                                            e.finishAt.toLocaleString(),
                                            MomentFormat.YYYYMMDDHmm
                                        )
                                        : '-'}
                                </Table.Td>
                                <Table.Td className="text-right">
                                    <Link
                                        className="rounded-md font-normal text-indigo-600 hover:text-indigo-900"
                                        to={`/admin/promotion/${e.id}`}
                                    >
                                        상세보기
                                    </Link>
                                </Table.Td>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>

                <Pagination>
                    <Pagination.Label
                        count={promotions.pagination?.itemsPerPage || 0}
                        total={promotions.pagination?.totalItemCount || 0}
                    />
                    <Pagination.Nav
                        basePath={history.location.pathname}
                        total={promotions.pagination?.totalPage || 0}
                    />
                </Pagination>
            </Card>
        </>
    );
};
