import React from 'react';

interface OrderCategoryProps {
  text: string;
  onClick: () => void;
  active: boolean;
}

export const OrderCategory: React.FC<OrderCategoryProps> = ({
  text,
  onClick,
  active,
}) => {
  return (
    <div
      className={
        active
          ? 'cursor-pointer bg-black px-5 py-3 text-white'
          : 'cursor-pointer border-r px-5 py-3'
      }
      onClick={onClick}
    >
      {text}
    </div>
  );
};
