import React, { useEffect, useState } from 'react';
import { Card } from '../../../components/Card';
import { Table } from '../../../components/Table';
import { AdminH1 } from '../../components/AdminH1';
import { Button } from '../../../components/Button';
import { TextField } from '../../../components/TextField';
import { TextArea } from '../../../components/TextArea';
import {
  ArcProductModal,
  ModalOpen,
} from '../../components/modal/ArcProductModal';
import {
  addShopProduct,
  deleteCurationProduct,
  getShop,
  updateCurationProduct,
  updateShop,
} from '../../../api/ArcnShopAPI';
import { ArcnShopDto } from '../../../types/arcnShop';
import Reorderable from '../../components/Reorderable';
import { toast } from 'react-toastify';

export const ArcProductList = () => {
  const [isOpen, setIsOpen] = useState<ModalOpen>({ open: false });
  const [shop, setShop] = useState<ArcnShopDto>({
    title: '',
    description: '',
    clickUrl: '',
    products: [],
  });

  const getList = () => {
    getShop()
      .then((res) => setShop(res.data))
      .catch((err) => console.error(err.response));
  };

  const saveShopInfo = () => {
    updateShop({
      title: shop.title,
      description: shop.description,
      clickUrl: shop.clickUrl,
    })
      .then((res) => {
        toast.success('정보가 저장되었습니다.');
        setShop(res.data);
      })
      .catch((err) => console.error(err.response));
  };

  const addProduct = (productId: number, order: number) => {
    addShopProduct({ productId, order })
      .then((res) => getList())
      .catch((err) => console.error(err.response));
  };

  const removeProduct = (productId: number) => {
    deleteCurationProduct(productId)
      .then((res) => getList())
      .catch((err) => console.error(err.response));
  };

  useEffect(() => getList(), [isOpen]);

  return (
    <>
      <ArcProductModal
        open={isOpen}
        onClose={() => {
          setIsOpen({ open: false });
        }}
        onSave={addProduct}
        preSelectedItems={shop.products}
      />

      <AdminH1>아크앤샵 노출 상품 관리</AdminH1>

      <form>
        <div className="grid grid-cols-3 gap-5">
          <TextField
            label="타이틀"
            value={shop.title}
            onChange={(e) => setShop({ ...shop, title: e.target.value })}
          />

          <TextField
            label="클릭시 URL"
            value={shop.clickUrl}
            onChange={(e) => setShop({ ...shop, clickUrl: e.target.value })}
          />

          <div className="col-span-3 col-start-1">
            <TextArea
              className="h-32"
              label="상세설명"
              value={shop.description}
              onChange={(e) =>
                setShop({ ...shop, description: e.target.value })
              }
            />
          </div>
        </div>
        <Button
          text="정보 저장"
          className="filled-black my-4 h-12"
          onClick={saveShopInfo}
        />
      </form>

      <div className="h-3 w-full bg-gray-50" />

      <div>
        <div>
          <Button
            text="상품 추가"
            className="filled-black mb-4 h-12"
            onClick={() => setIsOpen({ open: true })}
          />
        </div>

        <Card>
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.Th>노출 우선순위</Table.Th>
                <Table.Th>상품명</Table.Th>
                <Table.Th></Table.Th>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {shop.products.length > 0 ? (
                shop.products
                  .sort((a, b) => a.order - b.order)
                  .map((product, index) => (
                    <Table.Row key={index}>
                      <Table.Td>
                        <Reorderable
                          order={product.order}
                          onReordered={(order) => {
                            updateCurationProduct(product.id, {
                              order,
                              productId: product.product.id,
                            })
                              .then(() => {
                                toast.success('순서가 변경되었습니다');
                                getList();
                              })
                              .catch((err) => console.error(err.response));
                          }}
                        />
                      </Table.Td>
                      <Table.Td>{product.product.name}</Table.Td>
                      <Table.Td className="space-x-3 text-right">
                        <button
                          className="rounded-md font-normal text-red-600 hover:text-red-900"
                          onClick={() => removeProduct(product.id)}
                        >
                          삭제
                        </button>
                        <button
                          className="rounded-md font-normal text-indigo-600 hover:text-indigo-900"
                          onClick={() =>
                            setIsOpen({ open: true, target: product.id })
                          }
                        >
                          수정
                        </button>
                      </Table.Td>
                    </Table.Row>
                  ))
              ) : (
                <Table.Row>
                  <Table.Td colSpan={3}>상품이 없습니다.</Table.Td>
                </Table.Row>
              )}
            </Table.Body>
          </Table>

          {/*<Pagination>*/}
          {/*    <Pagination.Label count={data.items.length} total={data.total}/>*/}
          {/*    <Pagination.Nav basePath="/admin/users" total={data.total}/>*/}
          {/*</Pagination>*/}
        </Card>
      </div>
    </>
  );
};
