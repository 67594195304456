import {Select} from "../../components/Select";
import React, {useEffect, useState} from "react";
import {getCompanies} from "../../api/companyAPI";
import {CompanyDto} from "../../types/product";

export interface CompanySelectorProps {
    onChange: (company: CompanyDto | null) => void;
    selectedCompanyId?: number;
}

const CompanySelector = (props: CompanySelectorProps) => {
    const [companies, setCompanies] = useState<CompanyDto[]>([]);

    useEffect(() => {
        getCompanies(``, 1, 999)
            .then(r => {
                const list = r.data.items.sort((a, b) => a.name.localeCompare(b.name));
                setCompanies(list);
            })
            .catch(e => console.error(e.response));
    }, [])

    useEffect(() => {
        if (!!props.selectedCompanyId) {
            const company = companies.find(c => c.id === props.selectedCompanyId);
            if (!!company) {
                props.onChange(company);
            }
        }
    }, [companies])

    return (
        <Select className="h-12 w-44" label="업체 정보(제조사)"
                value={props.selectedCompanyId ? props.selectedCompanyId : ""}
                onChange={(e) => {
                    const id = Number(e.target.value);
                    if (id) {
                        const company = companies.find(company => company.id === id);
                        props.onChange(company!);
                    } else {
                        props.onChange(null);
                    }
                }}>
            <option hidden value="">업체명</option>
            <option value="">전체</option>
            {companies.map((company, index) => (
                <option key={index} value={company.id}>
                    {company.name}
                </option>
            ))}
        </Select>
    )
}

export default CompanySelector;