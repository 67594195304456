import {TextField} from "../../components/TextField";
import {Button} from "../../components/Button";
import React from "react";
import {useForm} from "react-hook-form";
import {CreateTopMenuDto} from "../../types/topMenu";
import {createMenu} from "../../api/menuAPI";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";

const MenuAddPage = () => {
    const history = useHistory();
    const form = useForm<CreateTopMenuDto>();

    const onSubmit = async (data: CreateTopMenuDto) => {
        createMenu(data)
            .then(() => {
                toast.success('메뉴 추가 성공');
                history.goBack();
            })
            .catch(err => console.error(err.response));
    }

    return (
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="max-w-md space-y-4">
                <TextField label="노출 우선순위"
                           type={'number'}
                           {...form.register('order', {required: true, setValueAs: value => Number(value)})}/>
                <TextField label="카테고리명"
                           {...form.register('name', {required: true})}/>
                <TextField label="클릭시 URL"
                           {...form.register('url', {required: true})}/>
                <Button type={'submit'} text="추가하기" className="filled-black h-12 w-full px-6"/>
                <Button type={'button'}
                        text="취소"
                        className="outlined-black h-12 w-full px-6"
                        onClick={() => history.goBack()}
                />
            </div>
        </form>
    )
}

export default MenuAddPage;