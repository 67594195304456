import {Select} from "../../components/Select";
import {TextField} from "../../components/TextField";
import {Button} from "../../components/Button";
import {Table} from "../../components/Table";
import React, {useEffect} from "react";
import {ShippingLine} from "../../types/order";
import { couriers } from "../../utils/couriers";

export type ShippingInfo = {
    courier: string,
    waybillCode: string,
}

export type OrderListShippingProps = {
    shippingLine: ShippingLine,
    onSave: (id: number, shippingInfo: ShippingInfo) => void,
}

export const OrderListShipping = (props: OrderListShippingProps) => {
    const [courier, setCourier] = React.useState<string>('');
    const [waybillCode, setWaybillCode] = React.useState<string>('');

    useEffect(() => {
        setCourier(props.shippingLine.courier);
        setWaybillCode(props.shippingLine.waybillCode!);
    }, [props.shippingLine]);

    return (
        <>
            <Select className="h-10 text-sm"
                    defaultValue={props.shippingLine.courier}
                    onChange={(e) => setCourier(e.target.value)}
            >
                <option hidden={true} value={""}>선택하세요</option>
                {couriers.map((courier, index) => (
                    <option key={index} value={courier.code}>{courier.name}</option>
                ))}
            </Select>
            <TextField placeholder="송장번호 입력" className="h-10"
                       defaultValue={props.shippingLine.waybillCode}
                       onChange={(e) => setWaybillCode(e.target.value)}
            />
            <Button
                text="저장"
                className="filled-gray-100 h-10 px-7 text-black"
                onClick={() => props.onSave(props.shippingLine.id, {courier, waybillCode})}
            />
        </>
    )
}