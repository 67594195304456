import React from 'react';
import { Button } from '../../../../components/Button';
import { TextField } from '../../../../components/TextField';
import useSections from '../../../../hooks/useSections';
import useKeyword from '../../../../hooks/useKeyword';
import { KeywordType } from '../../../../types/keyword';
import { useHistory } from 'react-router';
import queryString from 'query-string';

// TODO: 타입이 이게 맞나..
export const KeywordTypePage = () => {
  const history = useHistory();
  const query = queryString.parse(history.location.search);
  const { form, onsubmit, goBack } = useSections();

  const { keywords, setKeywords, update } = useKeyword(
    query.type === 'MINI' ? KeywordType.SECTION : (query.type as KeywordType)
  );

  const saveThis = () => {
    onsubmit(form.watch());
    update();
  };

  return (
    <div>
      <div className="grid grid-cols-3 gap-5">
        <TextField label="템플릿명" value="KeywordSection" disabled />
        <div className="col-start-1">
          <TextField label="타이틀" {...form.register('title')} />
        </div>
        <TextField
          label="템플릿 노출 우선순위"
          type="number"
          {...form.register('order')}
        />
      </div>

      <div className="my-5 h-3 w-full bg-gray-50" />

      <div className="grid grid-cols-3 gap-5 pb-20">
        {keywords
          .sort((a, b) => a.order - b.order)
          .slice(0, 30)
          .map((keyword, index) => (
            <TextField
              label={String(keyword.order)}
              key={index}
              defaultValue={keyword.keyword}
              onChange={(e) => {
                const newKeywords = [...keywords];
                newKeywords[index].keyword = e.target.value;
                setKeywords(newKeywords);
              }}
            />
          ))}
      </div>

      <div className="fixed bottom-0 -ml-8 w-full space-x-4 border-t bg-white py-2 pl-10">
        <Button
          type={'button'}
          text="취소"
          className="outlined-black h-12 px-6"
          onClick={() => goBack()}
        />
        <Button
          type={'button'}
          text="저장"
          className="filled-black h-12 px-6"
          onClick={saveThis}
        />
      </div>
    </div>
  );
};
