import React from 'react';
import {AdminH1} from '../../components/AdminH1';
import RecommendedCategoryTemplate from "../../components/templates/RecommendedCategoryTemplate";
import {useParams} from "react-router-dom";

export const RecommendCategoryShow = () => {
    const {id} = useParams<{ id: string }>();
    const numId = Number(id);
    return (
        <>
            <AdminH1>추천 카테고리 상세</AdminH1>
            <RecommendedCategoryTemplate type={'EDIT'} targetId={numId}/>
        </>
    );
};
