import React, { useEffect, useState } from 'react';
import { TextArea } from '../../../components/TextArea';
import { TextField } from '../../../components/TextField';
import { Button } from '../../../components/Button';
import { PostProductBModal } from '../../components/modal/PostProductBModal';
import { useHistory, useParams } from 'react-router-dom';
import { PostingDto } from '../../../types/posting';
import {
  addPostingProduct,
  deletePosting,
  getPosting,
  patchPosting,
} from '../../../api/postingAPI';
import { ProductList } from '../../components/ProductList';
import { toast } from 'react-toastify';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FloalaEditor } from '../../components/FloalaEditor';
import { FileAbout } from '../../../types/common';

export const DTypePostShow = () => {
  const { id } = useParams<{ id: string }>();
  const numId = Number(id);
  const history = useHistory();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [posting, setPosting] = useState<PostingDto>({} as PostingDto);

  const getThis = () => {
    getPosting(numId)
      .then((res) => setPosting(res.data))
      .catch((err) => console.error(err.response));
  };

  const saveThis = () => {
    patchPosting(numId, {
      title: posting.title,
      description: posting.description,
      content: posting.content,
    })
      .then(() => {
        toast.success('저장되었습니다.');
        history.push('/admin/post');
      })
      .catch((err) => console.error(err.response));
  };

  const removeThis = () => {
    const confirm = window.confirm('정말로 게시글을 삭제하시겠습니까?');
    if (confirm) {
      deletePosting(numId)
        .then(() => {
          toast.success('삭제되었습니다.');
          history.push('/admin/post');
        })
        .catch((err) => console.error(err.response));
    }
  };

  const addProduct = (
    productId: number,
    order: number,
    title?: string,
    description?: string
  ) => {
    addPostingProduct(numId, { productId, order, title, description })
      .then(() => {
        toast.success('상품이 추가되었습니다.');
        getThis();
      })
      .catch((err) => console.error(err.response));
  };

  useEffect(() => getThis(), []);

  return (
    <div>
      <PostProductBModal open={isOpen} onClose={() => setIsOpen(false)} />
      <div className="grid grid-cols-3 gap-5">
        <TextField
          label="타이틀"
          defaultValue={posting?.title}
          onChange={(e) => setPosting({ ...posting, title: e.target.value })}
        />
        <TextField
          label="url"
          value={`${process.env.REACT_APP_FRONT_URL}/lists/${posting.id}`}
          disabled
        />
        <CopyToClipboard
          text={`${process.env.REACT_APP_FRONT_URL}/lists/${posting.id}`}
          onCopy={() => toast.info('복사되었습니다.')}
        >
          <Button text="복사" className="filled-black mt-7 h-12 w-20 px-6" />
        </CopyToClipboard>
        <div className="col-span-3 col-start-1">
          <TextArea
            label="상세설명"
            className="h-32"
            defaultValue={posting?.description}
            onChange={(e) =>
              setPosting({ ...posting, description: e.target.value })
            }
          />
        </div>

        <div className="col-span-3 ">
          <div className="col-span-3 mb-2 text-sm text-gray-800">
            에디터 내용
          </div>
          <FloalaEditor
            imageType={FileAbout.POSTING_CONTENT}
            defaultValue={posting.content}
            onChange={(content) => setPosting({ ...posting, content: content })}
          />
        </div>
      </div>

      <div className="my-10 h-3 w-full bg-gray-50" />

      <ProductList
        products={posting.products || []}
        onModalClose={getThis}
        onAddProduct={addProduct}
        isHasOrder={true}
        isDetailedProduct={true}
      />

      <div className="fixed bottom-0 -ml-8 w-full space-x-4 border-t bg-white py-2 pl-10">
        <Button
          text="삭제"
          onClick={removeThis}
          className="outlined-black h-12 px-6"
        />
        <Button
          text="저장"
          onClick={saveThis}
          className="filled-black h-12 px-6"
        />
      </div>

      <div className="pt-14" />
    </div>
  );
};
