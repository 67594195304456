import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AdminH1 } from '../../components/AdminH1';
import { TextField } from '../../../components/TextField';
import { Button } from '../../../components/Button';
import { CategoryLeftBar } from '../../components/CategoryLeftBar';
import { CategoryDto } from '../../../types/categories';
import { patchCategory } from '../../../api/categoryAPI';
import { toast } from 'react-toastify';
import { Tab } from '../../components/Tab';
import useCategories from '../../../hooks/useCategories';
import { CATEGORIES_TABS } from '../../constants/common';
import { TabType } from '../../enum';
import AddCategoryModal from '../../components/modal/AddCategoryModal';
import DeleteCategoryModal from '../../components/modal/DeleteCategoryModal';

/**
 * TODO
 *  - API 추가시 일반 상품 카테고리 추가 (탭적용)
 *    - 대분류, 중분류
 *  - 일반 상품 카테고리 추가 할 수 있도록 UI 추가
 */

export const ProductCategoryPage = () => {
  const history = useHistory();
  const query = queryString.parse(history.location.search);

  const [selectedTab, setSelectedTab] = useState(
    query.tab
      ? CATEGORIES_TABS.find((tab) => tab.id === query.tab) ||
          CATEGORIES_TABS[0]
      : CATEGORIES_TABS[0]
  );
  const [thisCategory, setThisCategory] = useState<CategoryDto | null>(null);
  const [reloadKey, setReloadKey] = useState<number>(0);
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const { categories, subCategories } = useCategories(selectedTab.id);

  const handleClickTab = useCallback((tab) => {
    history.push(`?tab=${tab.id}`);
    setSelectedTab(tab);
  }, []);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (thisCategory === null) return;
      setThisCategory({ ...thisCategory, name: e.target.value });
    },
    [thisCategory]
  );

  const handleDelete = () => {
    setIsDeleteModalOpen(true);
  };
  const handleUpdate = () => {
    if (thisCategory?.name.trim() === '') {
      toast.error('카테고리명을 입력해주세요.');
      return;
    }
    patchCategory(thisCategory!.id, { name: thisCategory?.name }).then(() => {
      toast.success('카테고리 이름이 변경되었습니다.');
      setReloadKey(Math.random());
    });
  };

  const handleAdd = () => {
    setIsAddModalOpen(true);
  };

  useEffect(() => {
    if (query.sub && subCategories) {
      const selectedCategory = subCategories.find(
        (item) => String(item.id) === query.sub
      );
      if (!selectedCategory) return;
      setThisCategory(selectedCategory);
      return;
    }
    if (query.cate && categories) {
      const selectedCategory = categories.find(
        (item) => String(item.id) === query.cate
      );
      if (!selectedCategory) return;
      setThisCategory(selectedCategory);
      return;
    }
    setThisCategory(null);
  }, [categories, subCategories, query.sub, query.cate]);

  return (
    <div>
      <div className={'flex gap-8 pb-6'}>
        {CATEGORIES_TABS.map((tab) => (
          <Tab
            key={tab.id}
            text={tab.title}
            selected={selectedTab.id === tab.id}
            onClick={() => handleClickTab(tab)}
          />
        ))}
      </div>
      <div className={'flex h-full'}>
        <CategoryLeftBar
          isShowUndefinedCategory={false}
          key={reloadKey}
          tabId={selectedTab.id}
          isAddMode={true}
        />

        <div className="mx-4 h-full w-2 bg-gray-50 " />

        <RightComponent
          query={query}
          thisCategory={thisCategory}
          onChange={handleChange}
          onAdd={handleAdd}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
        />
      </div>
      <AddCategoryModal
        open={isAddModalOpen}
        key={'addCategoryModal'}
        thisCategory={thisCategory}
        onClose={() => setIsAddModalOpen(false)}
      />
      <DeleteCategoryModal
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        thisCategory={thisCategory}
      />
    </div>
  );
};

interface RightComponentProps {
  query: any;
  thisCategory: CategoryDto | null;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onAdd?: () => void;
  onUpdate?: () => void;
  onDelete?: () => void;
}

const RightComponent = ({
  query,
  thisCategory,
  onChange,
  onUpdate,
  onAdd,
  onDelete,
}: RightComponentProps) => {
  const { sub, tab } = query;
  if (tab === TabType.LIFE) {
    // 분류 선택 시 (대분류, 중분류)
    // 대분류 선택 시 중분류를 생성 할 수 있는 버튼, 모달 추가
    if (thisCategory !== null) {
      return (
        <div className="w-full space-y-5">
          <div className={'flex justify-between'}>
            <AdminH1>카테고리명 관리</AdminH1>
            {!sub && (
              <Button
                text="생성"
                className="filled-black h-12 px-6"
                onClick={onAdd}
                disabled={false}
              />
            )}
          </div>
          <TextField
            label="카테고리명"
            autoFocus={true}
            value={thisCategory.name}
            onChange={onChange}
          />
          <div className={'flex gap-2'}>
            <Button
              text="저장"
              className="filled-black h-12 px-6"
              onClick={onUpdate}
              disabled={false}
            />
            <Button
              text="삭제"
              className="filled-black h-12 px-6"
              onClick={onDelete}
              disabled={false}
            />
          </div>
        </div>
      );
    }
    return (
      <div className="w-full space-y-5 ">
        <AdminH1>대분류 카테고리 생성</AdminH1>
        <Button
          text="생성"
          className="filled-black h-12 px-6"
          onClick={onAdd}
          disabled={false}
        />
      </div>
    );
  } else {
    // 타입이 책일 경우
    if (thisCategory === null) return <></>;
    return (
      <div className="w-full space-y-5">
        <div className={'flex justify-between'}>
          <AdminH1>카테고리명 관리</AdminH1>
        </div>
        <TextField
          label="카테고리명"
          autoFocus={true}
          value={thisCategory.name}
          onChange={onChange}
        />
        <div className={'flex gap-2'}>
          <Button
            text="저장"
            className="filled-black h-12 px-6"
            onClick={onUpdate}
            disabled={false}
          />
        </div>
      </div>
    );
  }
};
