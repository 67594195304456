import React, { ButtonHTMLAttributes, FC } from 'react';

interface TabProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  selected?: boolean;
  text: string;
}

export const Tab: FC<TabProps> = ({ selected, text, ...props }) => {
  return (
    <button
      {...props}
      className={`${
        selected
          ? 'border-b-2 border-brand-2 font-semibold text-gray-900'
          : 'text-gray-500'
      } pb-3`}
    >
      {text}
    </button>
  );
};
