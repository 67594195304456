import { TabType } from '../enum';
import { ProductType } from '../../types/product';

const CATEGORIES_TABS = [
  {
    id: TabType.BOOK,
    title: '도서 상품',
  },
  {
    id: TabType.LIFE,
    title: '일반 상품',
  },
];

const productTypeMapping: { [key: string]: ProductType } = {
  MAIN: ProductType.BOOK,
  LIFE: ProductType.GENERAL,
};

export { CATEGORIES_TABS, productTypeMapping };
