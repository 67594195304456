import React from 'react';
import { Route, Switch, useLocation } from 'react-router';
import { Sidebar } from '../components/Sidebar';
import { AdminHeader } from './components/AdminHeader';
import { AdminMain } from './components/AdminMain';
import { KeywordTypePage } from './pages/contents/main/KeywordTypePage';
import { GridType1 } from './pages/contents/main/GridType1';
import { ArcBannerList } from './pages/arcShop/ArcBannerList';
import { ArcBannerAdd } from './pages/arcShop/ArcBannerAdd';
import { ArcBannerShow } from './pages/arcShop/ArcBannerShow';
import { ArcProductList } from './pages/arcShop/ArcProductList';
import { OrderMain } from './pages/order/OrderMain';
import { CancelOrderMain } from './pages/order/CancelOrderMain';
import { ExchangeOrderMain } from './pages/order/ExchangeOrderMain';
import { ReturnOrderMain } from './pages/order/ReturnOrderMain';
import { BooksProductAdd } from './pages/product/BooksProductAdd';
import { BooksProductList } from './pages/product/BooksProductList';
import { BooksProductShow } from './pages/product/BooksProductShow';
import { NormalProductList } from './pages/product/NormalProductList';
import { NormalProductShow } from './pages/product/NormalProductShow';
import { NormalProductAdd } from './pages/product/NormalProductAdd';
import { NormalProductBrandList } from './pages/product/NormalProductBrandList';
import { NormalBrandShow } from './pages/product/NormalBrandShow';
import { ProductTagList } from './pages/product/ProductTagList';
import { ProductTagShow } from './pages/product/ProductTagShow';
import { ProductTagAdd } from './pages/product/ProductTagAdd';
import { GridCardProductType } from '../types/gridCardProduct';
import { ProductCategoryPage } from './pages/product/ProductCategoryPage';
import { PickupOrderList } from './pages/order/pickup/PickupOrderList';
import { useAuth } from '../hooks';
import { AdminLevel } from '../types/user';
import { PromotionList } from './pages/contents/PromotionList';
import { PromotionDetail } from './pages/contents/PromotionDetail';
import { KeywordType } from '../types/keyword';
import { MultiType2 } from './pages/contents/main/MultiType2';
import MenuShow from '../pages/menus/MenuShow';
import { SingleType } from './pages/contents/main/SingleType';
import { UserAdd } from './pages/user/UserAdd';
import { AtypePostAdd } from './pages/contents/AtypePostAdd';
import { CategoryShow } from './pages/contents/CategoryShow';
import { PostList } from './pages/contents/PostList';
import { GridTypeProductAdd } from './pages/contents/main/GridTypeProductAdd';
import { BannerTypeProductAdd } from './pages/contents/main/BannerTypeProductAdd';
import { ArcSections } from './pages/arcShop/ArcSections';
import { BtypePostAdd } from './pages/contents/BtypePostAdd';
import { BannerList } from './pages/contents/BannerList';
import { AdminUserAdd } from './pages/user/AdminUserAdd';
import { UserList } from './pages/user/UserList';
import { AdminUserList } from './pages/user/AdminUserList';
import { GridType2 } from './pages/contents/main/GridType2';
import { BtypePostShow } from './pages/contents/BtypePostShow';
import MenuList from '../pages/menus/MenuList';
import { MultiTypeProductAdd } from './pages/contents/main/MultiTypeProductAdd';
import { BannerAdd } from './pages/contents/BannerAdd';
import { CTypePostAdd } from './pages/contents/CTypePostAdd';
import { MultiType1 } from './pages/contents/main/MultiType1';
import { RecommendCategoryAdd } from './pages/contents/RecommendCategoryAdd';
import { Redirect } from 'react-router-dom';
import { AtypePostShow } from './pages/contents/AtypePostShow';
import { CategoryAdd } from './pages/contents/CategoryAdd';
import { RecommendCategory } from './pages/contents/RecommendCategory';
import { SingleProductAdd } from './pages/contents/main/SingleProductAdd';
import { CategoryList } from './pages/contents/CategoryList';
import { MainContents } from './pages/contents/main/MainContents';
import { GridTypeProductShow } from './pages/contents/main/GridTypeProductShow';
import { MultiTypeProductShow } from './pages/contents/main/MultiTypeProductShow';
import { KeywordPage } from './pages/contents/KeywordPage';
import { BannerShow } from './pages/contents/BannerShow';
import { RecommendCategoryShow } from './pages/contents/RecommendCategoryShow';
import { CTypePostShow } from './pages/contents/CTypePostShow';
import { BannerTypePage } from './pages/contents/main/BannerTypePage';
import MenuAdd from '../pages/menus/MenuAdd';
import { UserShow } from './pages/user/UserShow';
import { AdminUserShow } from './pages/user/AdminUserShow';
import { LoginPage } from './pages/LoginPage';
import { SingleProductShow } from './pages/contents/main/SingleProductShow';
import { PromotionAdd } from './pages/contents/PromotionAdd';
import { ReviewList } from './pages/contents/ReviewList';
import PostCommentList from './pages/contents/PostCommentList';
import { DTypePostShow } from './pages/contents/DTypePostShow';
import { DTypePostAdd } from './pages/contents/DTypePostAdd';
import { specialAllowAdminList } from '../utils/specialAllowAdminList';
import { PageEditMainPage } from './pages/partnership/page/PageEditMainPage';
import { PartnershipFormPage } from './pages/partnership/PartnershipFormPage';
import SectionAddPage from './pages/contents/SectionAddPage';
import { NormalBrand } from './pages/product/NormalBrand';

export const Admin = () => {
  const { pathname } = useLocation();
  const { currentUser } = useAuth();

  const allowLevels = (...levels: AdminLevel[]): boolean => {
    levels.push(AdminLevel.ROOT_ADMIN); // root allowed all
    return !!currentUser && levels.includes(currentUser.adminLevel);
  };

  // 각 지점 관리자가 특정 메뉴에 접근 가능하도록 함
  const isSpecialAllow =
    !allowLevels() &&
    !!specialAllowAdminList.find((admin) => admin === currentUser?.email);

  return (
    <div className="flex h-screen">
      {pathname !== '/admin/login' && (
        <Sidebar>
          <Sidebar.Title>Admin {process.env.REACT_APP_ENV}</Sidebar.Title>
          <Sidebar.Menu>
            {isSpecialAllow && (
              <>
                <div className="font-semibolds pt-6 pb-2">회원 관리</div>
                <Sidebar.Menu.Item text="회원 관리" to="/admin/users" />
              </>
            )}

            {allowLevels() && (
              <>
                <div className="font-semibolds pt-6 pb-2">회원 관리</div>
                <Sidebar.Menu.Item text="회원 관리" to="/admin/users" />
                <Sidebar.Menu.Item
                  text="관리자 계정 관리"
                  to="/admin/adminUsers"
                />
              </>
            )}

            <div className="font-semibolds pt-6 pb-2">상품 발주 관리</div>
            {allowLevels() && (
              <Sidebar.Menu.Item text="주문 ∙ 배송 관리" to="/admin/order" />
            )}
            {allowLevels(AdminLevel.BRANCH_MANAGER) && (
              <Sidebar.Menu.Item
                text="매장픽업 관리"
                to="/admin/pickup/order"
              />
            )}
            {allowLevels() && (
              <>
                <Sidebar.Menu.Item text="취소 관리" to="/admin/cancel/order" />
                <Sidebar.Menu.Item
                  text="교환 관리"
                  to="/admin/exchange/order"
                />
                <Sidebar.Menu.Item text="반품 관리" to="/admin/return/order" />
              </>
            )}

            {isSpecialAllow && (
              <>
                <div className="font-semibolds pt-6 pb-2">상품 관리</div>
                <Sidebar.Menu.Item
                  text="도서 상품 관리"
                  to="/admin/books/product"
                />
              </>
            )}

            {allowLevels() && (
              <>
                <div className="font-semibolds pt-6 pb-2">상품 관리</div>
                <Sidebar.Menu.Item
                  text="도서 상품 관리"
                  to="/admin/books/product"
                />
                <Sidebar.Menu.Item
                  text="일반 상품 관리"
                  to="/admin/normal/product"
                />
                <Sidebar.Menu.Item
                  text="일반 상품 업체 관리"
                  to="/admin/normal/brand"
                />
                <Sidebar.Menu.Item
                  text="상품 태그 관리"
                  to="/admin/product/tag"
                />
                <Sidebar.Menu.Item
                  text="카테고리명 관리"
                  to="/admin/product/category"
                />

                <div className="font-semibolds pt-6 pb-2">컨텐츠 관리</div>
                <Sidebar.Menu.Item
                  text="메인 배너 관리"
                  to="/admin/mainbanner"
                />
                <Sidebar.Menu.Item
                  text="메인 관리 / 노출 순위 관리"
                  to="/admin/main/contents"
                />
                <Sidebar.Menu.Item text="상단 메뉴 관리" to="/admin/menu" />
                <Sidebar.Menu.Item text="게시글 관리" to="/admin/post" />
                <Sidebar.Menu.Item text="검색어 관리" to="/admin/keyword" />
                <Sidebar.Menu.Item
                  text="추천 카테고리 관리"
                  to="/admin/recommend/category"
                />
                <Sidebar.Menu.Item
                  text="카테고리 링크 생성"
                  to="/admin/category"
                />
                <Sidebar.Menu.Item text="프로모션 관리" to="/admin/promotion" />
                <Sidebar.Menu.Item text="리뷰 관리" to="/admin/reviews" />

                <div className="font-semibolds pt-6 pb-2">제휴 관리</div>
                <Sidebar.Menu.Item
                  text="제휴 페이지 관리"
                  to="/admin/partnership/page"
                />
                <Sidebar.Menu.Item
                  text="제휴 요청 관리"
                  to="/admin/partnership/form"
                />

                <div className="font-semibolds pt-6 pb-2">아크앤샵 관리</div>
                <Sidebar.Menu.Item
                  text="메인 배너 관리"
                  to="/admin/arc/banner"
                />
                {/*<Sidebar.Menu.Item*/}
                {/*  text="아크앤샵 관리 / 노출 순위 관리"*/}
                {/*  to="/admin/arc/product"*/}
                {/*/>*/}
                <Sidebar.Menu.Item
                  text="아크앤샵 메인관리"
                  to="/admin/life/contents"
                />
              </>
            )}
          </Sidebar.Menu>
        </Sidebar>
      )}

      <div className="flex min-w-0 flex-1 flex-col overflow-hidden">
        {pathname !== '/admin/login' && <AdminHeader />}
        <AdminMain>
          <Switch>
            <Route
              path={'/admin/promotion/product/:id'}
              component={NormalProductShow}
            />
            <Route path={'/admin/promotion/add'} component={PromotionAdd} />
            <Route path={'/admin/promotion/:id'} component={PromotionDetail} />
            <Route path={'/admin/promotion'} component={PromotionList} />

            <Route path={'/admin/reviews'} component={ReviewList} />

            <Route path={'/admin/product/tag/add'} component={ProductTagAdd} />
            <Route path={'/admin/product/tag/:id'} component={ProductTagShow} />
            <Route path={'/admin/product/tag'} component={ProductTagList} />
            <Route
              path={'/admin/product/category'}
              component={ProductCategoryPage}
            />
            <Route path={'/admin/normal/brand/add'} component={NormalBrand} />
            <Route path={'/admin/normal/brand/:id'} component={NormalBrand} />
            <Route
              path={'/admin/normal/brand'}
              component={NormalProductBrandList}
            />
            <Route
              path={'/admin/normal/product/add'}
              component={NormalProductAdd}
            />
            <Route
              path={'/admin/normal/product/:id'}
              component={NormalProductShow}
            />
            <Route
              path={'/admin/normal/product'}
              component={NormalProductList}
            />
            <Route
              path={'/admin/books/product/add'}
              component={BooksProductAdd}
            />
            <Route
              path={'/admin/books/product/:id'}
              component={BooksProductShow}
            />
            <Route path={'/admin/books/product'} component={BooksProductList} />
            <Route path={'/admin/pickup/order'} component={PickupOrderList} />
            <Route path={'/admin/return/order'} component={ReturnOrderMain} />
            <Route
              path={'/admin/exchange/order'}
              component={ExchangeOrderMain}
            />
            <Route path={'/admin/banner/add'} component={ArcBannerAdd} />
            <Route path={'/admin/banner/:id'} component={ArcBannerShow} />
            <Route path={'/admin/cancel/order'} component={CancelOrderMain} />
            <Route path={'/admin/order'} component={OrderMain} />
            <Route path={'/admin/arc/product'} component={ArcProductList} />
            <Route path={'/admin/arc/banner'} component={ArcBannerList} />

            <Route
              path="/admin/section/add"
              component={() => <SectionAddPage />}
            />

            <Route
              path="/admin/life/contents/GRID_CARD_SECTION_3/:id"
              component={() => (
                <GridType1 type={GridCardProductType.GRID_CARD_SECTION_3} />
              )}
            />

            <Route path={'/admin/life/contents'} component={ArcSections} />

            <Route path={'/admin/category/add'} component={CategoryAdd} />
            <Route path={'/admin/category/:id'} component={CategoryShow} />
            <Route path={'/admin/category'} component={CategoryList} />
            <Route
              path={'/admin/recommend/category/add'}
              component={RecommendCategoryAdd}
            />
            <Route
              path={'/admin/recommend/category/:id'}
              component={RecommendCategoryShow}
            />
            <Route
              path={'/admin/recommend/category'}
              component={RecommendCategory}
            />
            <Route path={'/admin/menu/add'} component={MenuAdd} />
            <Route path={'/admin/menu/:id'} component={MenuShow} />
            <Route path={'/admin/menu'} component={MenuList} />
            <Route path={'/admin/keyword'} component={KeywordPage} />
            <Route path={'/admin/post/d/add'} component={DTypePostAdd} />
            <Route path={'/admin/post/d/:id'} component={DTypePostShow} />
            <Route path={'/admin/post/c/add'} component={CTypePostAdd} />
            <Route path={'/admin/post/c/:id'} component={CTypePostShow} />
            <Route path={'/admin/post/b/add'} component={BtypePostAdd} />
            <Route
              path={'/admin/post/b/:id/comments'}
              component={PostCommentList}
            />
            <Route path={'/admin/post/b/:id'} component={BtypePostShow} />
            <Route path={'/admin/post/a/add'} component={AtypePostAdd} />
            <Route
              path={'/admin/post/a/:id/comments'}
              component={PostCommentList}
            />
            <Route path={'/admin/post/a/:id'} component={AtypePostShow} />
            <Route path={'/admin/post'} component={PostList} />
            <Route
              path={'/admin/main/contents/7/product/add'}
              component={BannerTypeProductAdd}
            />
            <Route
              path={'/admin/main/contents/6/product/add'}
              component={SingleProductAdd}
            />
            <Route
              path={'/admin/main/contents/6/product/:id'}
              component={SingleProductShow}
            />
            <Route
              path={['/admin/main/contents/4/product/add']}
              component={() => (
                <GridTypeProductAdd
                  gridCardProductType={GridCardProductType.GRID_CARD_SECTION_1}
                />
              )}
            />
            <Route
              path={['/admin/main/contents/8/product/add']}
              component={() => (
                <GridTypeProductAdd
                  gridCardProductType={GridCardProductType.GRID_CARD_SECTION_3}
                />
              )}
            />
            <Route
              path={['/admin/main/contents/5/product/add']}
              component={() => (
                <GridTypeProductAdd
                  gridCardProductType={GridCardProductType.GRID_CARD_SECTION_2}
                />
              )}
            />
            <Route
              path={[
                '/admin/main/contents/4/product/:id',
                '/admin/main/contents/5/product/:id',
              ]}
              component={GridTypeProductShow}
            />
            <Route
              path={[
                '/admin/main/contents/1/product/add',
                '/admin/main/contents/2/product/add',
              ]}
              component={MultiTypeProductAdd}
            />
            <Route
              path={[
                '/admin/main/contents/1/product/:id',
                '/admin/main/contents/2/product/:id',
              ]}
              component={MultiTypeProductShow}
            />

            <Route
              path="/admin/contents/BANNER_SECTION/:id"
              component={BannerTypePage}
            />
            <Route
              path="/admin/contents/SINGLE_CARD_SECTION/:id"
              component={SingleType}
            />
            <Route
              path="/admin/contents/GRID_CARD_SECTION_2/:id"
              component={GridType2}
            />
            <Route
              path="/admin/contents/GRID_CARD_SECTION_1/:id"
              component={() => (
                <GridType1 type={GridCardProductType.GRID_CARD_SECTION_1} />
              )}
            />
            <Route path="/admin/contents/KEYWORD_SECTION/:id">
              <KeywordTypePage />
            </Route>
            <Route
              path="/admin/contents/MULTI_CARD_SECTION_2/:id"
              component={MultiType2}
            />
            <Route
              path="/admin/contents/MULTI_CARD_SECTION_1/:id"
              component={MultiType1}
            />
            <Route path="/admin/main/contents" component={MainContents} />
            <Route path="/admin/banner/add" component={BannerAdd} />
            <Route path="/admin/banner/:id" component={BannerShow} />
            <Route path="/admin/mainbanner" component={BannerList} />
            <Route path="/admin/users/add" component={UserAdd} />
            <Route path="/admin/users/:id" component={UserShow} />
            <Route path="/admin/users" component={UserList} />
            <Route path="/admin/adminUsers/add" component={AdminUserAdd} />
            <Route path="/admin/adminUsers/:id" component={AdminUserShow} />
            <Route path="/admin/adminUsers" component={AdminUserList} />
            <Route
              path="/admin/partnership/page"
              component={PageEditMainPage}
            />
            <Route
              path="/admin/partnership/form"
              component={PartnershipFormPage}
            />
            <Route path="/admin/login" component={LoginPage} />
            <Route path="/">
              <Redirect to="/admin/login" />
            </Route>
          </Switch>
        </AdminMain>
      </div>
    </div>
  );
};
