import React from 'react';
import {Button} from '../../../../components/Button';
import {TextArea} from '../../../../components/TextArea';
import {TextField} from '../../../../components/TextField';
import useSections from "../../../../hooks/useSections";
import {ProductList} from "../../../components/ProductList";

export const SingleType = () => {
    const {
        form,
        onsubmit,
        goBack,
        products,
        addProduct,
        getData,
    } = useSections();

    return (
        <form onSubmit={form.handleSubmit(onsubmit)}>
            <div className="grid grid-cols-3 gap-5">
                <TextField label="템플릿명" disabled value="SingleCardSection"/>
                <div className="col-start-1">
                    <TextField label="타이틀" {...form.register('title')}/>
                </div>
                <TextField label="템플릿 노출 우선순위" type="number" {...form.register('order')}/>
                <TextField label="클릭시 이동 URL" {...form.register('clickUrl')}/>
                <div className="col-span-3">
                    <TextArea label="상세설명" className="h-32" {...form.register('description')}/>
                </div>
            </div>

            <div className="h-3 w-full bg-gray-50 my-6"/>

            <ProductList products={products} onModalClose={getData} onAddProduct={addProduct} isHasOrder={true}
                         isNoTitle={true}
                         isDetailedProduct={true}/>

            <div className="fixed bottom-0 -ml-8 w-full space-x-4 border-t bg-white py-2 pl-10">
                <Button type={'button'}
                        text="취소"
                        className="outlined-black h-12 px-6"
                        onClick={() => goBack()}
                />
                <Button type={'submit'} text="저장" className="filled-black h-12 px-6"/>
            </div>

            <div className="pt-14"/>
        </form>
    );
};
