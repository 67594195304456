import { api } from '../plugins/axios';
import { Paged } from '../types/common';
import {
  PartnershipForm,
  UpdatePartnershipFormDto,
} from '../types/partnershipForm';

export const getPartnershipForms = (
  query?: string,
  page?: number,
  itemsPerPage?: number
) => {
  const params = {
    page: page || 1,
    itemsPerPage: itemsPerPage || 30,
  };
  return api.get<Paged<PartnershipForm>>(`/admin/partnership-forms?${query}`, {
    params,
  });
};

export const getPartnershipForm = (id: number) => {
  return api.get<PartnershipForm>(`/admin/partnership-forms/${id}`);
};

export const patchPartnershipForm = (
  id: number,
  data: Partial<UpdatePartnershipFormDto>
) => {
  return api.patch<PartnershipForm>(`/admin/partnership-forms/${id}`, data);
};
