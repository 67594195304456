import { Table } from '../../components/Table';
import queryString from 'query-string';
import { Card } from '../../components/Card';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useCategories from '../../hooks/useCategories';
import { CategoryDto } from '../../types/categories';
import { TabType } from '../enum';

interface CategoryLeftBarProps {
  isShowUndefinedCategory?: boolean;
  tabId: TabType;
  isAddMode?: boolean;
}

// TODO: 일반 상품 카테고리 없는 경우 DB 추가되면 수정필요
export const CategoryLeftBar = (props: CategoryLeftBarProps) => {
  const { isShowUndefinedCategory = false, tabId, isAddMode } = props;
  const location = useLocation();
  const history = useHistory();
  const query = queryString.parse(history.location.search);
  const { categories, subCategories, undefinedCategoryId } =
    useCategories(tabId);

  return (
    <div className="no-scrollbar h-full w-80 flex-shrink-0 overflow-y-scroll">
      <Card>
        <div className="flex w-full">
          <div className="max-w-[50%] flex-1">
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Th>대분류</Table.Th>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {isShowUndefinedCategory && (
                  <Table.Row>
                    <Table.Td
                      className={`${
                        query.cate === undefinedCategoryId?.toString()
                          ? 'bg-blue-100'
                          : 'hover:bg-blue-50'
                      } cursor-pointer text-red-500`}
                      onClick={() => {
                        query.cate = undefinedCategoryId?.toString() || null;
                        query.sub = null;
                        history.push(`?${queryString.stringify(query)}`);
                      }}
                    >
                      카테고리 필요
                    </Table.Td>
                  </Table.Row>
                )}
                {categories.map((item) => (
                  <Table.Row key={item.id}>
                    <Table.Td
                      className={`${
                        query.cate === String(item.id)
                          ? 'bg-blue-100'
                          : 'hover:bg-blue-50'
                      } cursor-pointer whitespace-normal`}
                      onClick={() => {
                        if (String(item.id) === query.cate && !query.sub) {
                          const queryParams = new URLSearchParams(
                            location.search
                          );
                          queryParams.delete('cate');
                          queryParams.delete('sub');
                          history.replace({
                            search: queryParams.toString(),
                          });
                          return;
                        }
                        query.cate = item.id.toString();
                        query.sub = null;
                        history.push(`?${queryString.stringify(query)}`);
                      }}
                    >
                      {item.name}
                    </Table.Td>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>

          <div className="flex-1">
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Th>중분류</Table.Th>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {subCategories?.map((item: CategoryDto) => (
                  <Table.Row key={item.id}>
                    <Table.Td
                      className={`${
                        query.sub === String(item.id)
                          ? 'bg-blue-100'
                          : 'hover:bg-blue-50'
                      } cursor-pointer whitespace-normal`}
                      onClick={() => {
                        query.sub = item.id.toString();
                        history.push(`?${queryString.stringify(query)}`);
                      }}
                    >
                      {item.name}
                    </Table.Td>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        </div>
      </Card>
    </div>
  );
};
