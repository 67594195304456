import { useMemo, useState } from 'react';
import { getCategories, getCategory } from '../api/categoryAPI';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useQuery } from 'react-query';
import { TabType } from '../admin/enum';

// TODO: getCategories 에서 useCategories 에서 tab값에 맞춰서 Book, Product 타입을 넘겨서 데이터를 불러오도록 API 수정 시 만들어짐
// TODO: SubCategory api getCategory 적용
const useCategories = (tabId: TabType) => {
  const history = useHistory();
  const query = queryString.parse(history.location.search);

  const [undefinedCategoryId, setUndefinedCategoryId] = useState<number>(0);
  const { data: parentCategory, isLoading } = useQuery(
    ['categories', tabId],
    () => getCategories(1, 0, tabId)
  );

  const categories = useMemo(() => {
    if (parentCategory === undefined) return [];
    setUndefinedCategoryId(
      parentCategory.items.find((item) => item.name === '기본')?.id || 0
    );
    return parentCategory.items.filter(
      (item) => item.categoryId === null && item.name !== '기본'
    );
  }, [parentCategory]);

  const { data: subCategories, isLoading: subLoading } = useQuery(
    ['categories', tabId, query.cate],
    () => getCategory(Number(query.cate) || 0),
    {
      enabled: query.cate !== undefined,
    }
  );

  return {
    categories,
    subCategories,
    undefinedCategoryId,
  };
};

export default useCategories;
