import React, {useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Card} from '../../../components/Card';
import {Pagination} from '../../../components/Pagination';
import {Table} from '../../../components/Table';
import {AdminH1} from '../../components/AdminH1';
import {MomentFormat, utcToLocalFormat} from '../../../plugins/moment';
import {Search} from '../../../components/Search';
import {Button} from '../../../components/Button';
import {getAdminUsers} from "../../../api/adminUserAPI";
import {Paged} from "../../../types/common";
import { AdminDto, getAdminLevelText } from "../../../types/user";
import queryString from "query-string";
import {useLoading} from "../../../hooks/useLoading";
import {Select} from "../../../components/Select";

export const AdminUserList = () => {
  const history = useHistory();
  const [users, setUsers] = React.useState<Paged<AdminDto>>({items: []});
  const [userDate, setUserDate] = React.useState<{ from: string, to: string }>({
    from: '2020-01-01',
    to: '2099-12-31'
  });
  const query = queryString.parse(history.location.search);
  const {setIsLoading, LoadingView} = useLoading();

  const getList = () => {
    let nQuery = ``;
    if (query.find) nQuery += `&${query.searchType || 'name'}=${query.find}`;
    if (query.from) nQuery += `&where[createdAt][from]=${query.from}&where[createdAt][to]=${query.to}`;
    nQuery += `&order[id]=DESC`;
    getAdminUsers(nQuery, Number(query.page), Number(query.limit))
      .then(res => setUsers(res.data))
      .catch(e => console.error(e.response))
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    getList();
  }, [history.location.search]);

  return (
    <>
      <LoadingView/>
      <AdminH1>관리자 계정 관리</AdminH1>
      <div className="flex justify-end">
        <Select className={'h-12 text-sm mr-4'}
                value={query.searchType?.toString() || 'code'}
                onChange={(e) => {
                  query.searchType = e.target.value;
                  history.push(`?${queryString.stringify(query)}`);
                }}
        >
          <option value="name">이름</option>
          <option value="email">이메일</option>
        </Select>
        <Search className="w-52"
                defaultValue={query.find?.toString()}
                onSearch={(keyword) => {
                  query.find = keyword;
                  history.push(`?${queryString.stringify(query)}`);
                }}/>
      </div>
      {/*<div className="flex justify-end">*/}
      {/*    <Search defaultValue={query.name?.toString()}*/}
      {/*            onSearch={keyword => {*/}
      {/*                query.name = keyword;*/}
      {/*                history.push(`?${queryString.stringify(query)}`);*/}
      {/*            }}/>*/}
      {/*</div>*/}
      <div className="flex items-center justify-between">
        <Button
          text="관리자 계정 추가"
          className="filled-black h-12"
          to="/admin/adminUsers/add"
        />
        <div className="flex items-center space-x-2 rounded-lg bg-gray-50 pl-4">
          <input
            type="date"
            className="rounded-md border-none bg-gray-50 text-gray-600 focus:ring-gray-500"
            defaultValue={query.from?.slice(0, 10).toString() || undefined}
            onChange={e => setUserDate({...userDate, from: e.target.value + ' 00:00:00'})}
          />
          <p className="font-semibold">부터</p>
          <input
            type="date"
            className="rounded-md border-none bg-gray-50 text-gray-600 focus:ring-gray-500"
            defaultValue={query.to?.slice(0, 10).toString() || undefined}
            onChange={e => setUserDate({...userDate, to: e.target.value + ' 23:59:59'})}
          />
          <Button text="검색" className="filled-black h-12 px-5"
                  onClick={() => {
                    query.from = userDate.from;
                    query.to = userDate.to;
                    history.push(`?${queryString.stringify(query)}`);
                  }}/>
        </div>
      </div>

      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>ID</Table.Th>
              <Table.Th>구분</Table.Th>
              <Table.Th>회원명</Table.Th>
              <Table.Th>아이디</Table.Th>
              <Table.Th>생성일자</Table.Th>
              <Table.Th>메모</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {users.items.length === 0 && (
              <Table.Row>
                <Table.Td>데이터가 없습니다.</Table.Td>
              </Table.Row>
            )}
            {users.items.map((user) => (
              <Table.Row key={user.id}>
                <Table.Td>{user.id}</Table.Td>
                <Table.Td>{getAdminLevelText(user.adminLevel)}</Table.Td>
                <Table.Td>{user.name}</Table.Td>
                <Table.Td>{user.email}</Table.Td>
                <Table.Td>
                  {utcToLocalFormat(
                    user.createdAt.toLocaleString(),
                    MomentFormat.YYYYMMDDHmm
                  )}
                </Table.Td>
                <Table.Td>{user.memo || '-'}</Table.Td>
                <Table.Td className="text-right">
                  <Link
                    className="rounded-md font-normal text-indigo-600 hover:text-indigo-900"
                    to={`/admin/adminUsers/${user.id}`}
                  >
                    상세보기
                  </Link>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination>
          <Pagination.Label count={users.items.length} total={users.pagination?.totalItemCount!}/>
          <Pagination.Nav basePath={history.location.pathname} total={users.pagination?.totalItemCount!}/>
        </Pagination>
      </Card>
    </>
  );
};
