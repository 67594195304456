import React, { FC } from 'react';
import { FileAbout } from '../../../../types/common';
import { FloalaEditor } from '../../../components/FloalaEditor';
import { UseFormReturn } from 'react-hook-form';
import { UpdatePartnershipDto } from '../../../../types/partnersip';

interface PcPageEditProps {
  form: UseFormReturn<UpdatePartnershipDto>;
}

export const PcPageEdit: FC<PcPageEditProps> = ({ form }) => {
  return (
    <div className="">
      <FloalaEditor
        imageType={FileAbout.PARTNERSHIP_PC}
        defaultValue={form.watch('pcContents')}
        onChange={(content) => form.setValue('pcContents', content)}
      />
    </div>
  );
};
