import React from 'react';
import {Button} from '../../components/Button';
import {TextField} from '../../components/TextField';
import {useForm} from "react-hook-form";
import { AdminLevel, LoginForm } from "../../types/user";
import {setUserToken} from "../../api/sessionAPI";
import { useHistory } from 'react-router-dom';
import { useAuth } from "../../hooks";

export const LoginPage = () => {
    const form = useForm<LoginForm>();
    const history = useHistory();
    const { currentUser } = useAuth();

    const login = (data: LoginForm) => {
        setUserToken(data).catch(err => console.error(err.response));
    }

    if (currentUser) {
        switch (currentUser.adminLevel) {
            case AdminLevel.BRANCH_MANAGER:
                history.push('/admin/pickup/order');
                break;
            default:
                history.push('/admin/users');
                break;
        }
    }

    return (
        <form onSubmit={form.handleSubmit(login)}>
            <div className="mx-auto w-full max-w-sm space-y-10">
                <h3 className="text-2xl font-semibold">Admin 로그인</h3>
                <div className="space-y-3">
                    <TextField label="아이디" placeholder="아이디를 입력해주세요."
                               helper={form.formState.errors.email?.message}
                               {...form.register('email', {required: '이메일을 입력하세요'})}/>
                    <TextField
                        label="비밀번호"
                        placeholder="비밀번호를 입력해주세요."
                        type="password"
                        helper={form.formState.errors.password?.message}
                        {...form.register('password', {required: '비밀번호를 입력하세요'})}/>
                </div>
                <Button type={'submit'} text="로그인" className="filled-black w-full font-normal"/>
            </div>
        </form>
    );
};
