import {api} from "../plugins/axios";
import {LoginForm, Token, UserDto} from "../types/user";
import {tokenState} from "../plugins/ridge";

export const setUserToken = async (form: LoginForm) => {
    const {data} = await api.post<Token>('/user/session', form);
    if (data) {
        await tokenState.set(data.token);
    }
    api.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
}

export const updateToken = async () => {
    const {data} = await api.put<Token>('/user/session');
    localStorage.setItem('token', data.token);
    api.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
}

export const getUserSession = () => {
    return api.get<UserDto>('/user/session');
}