import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Card } from '../../../components/Card';
import { Pagination } from '../../../components/Pagination';
import { Search } from '../../../components/Search';
import { Table } from '../../../components/Table';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';
import { AdminH1 } from '../../components/AdminH1';
import queryString from 'query-string';
import ProductTabBar from '../../components/ProductTabBar';
import { productStatus } from '../../../utils/productStatus';
import { Select } from '../../../components/Select';
import { useLoading } from '../../../hooks/useLoading';
import { Button } from '../../../components/Button';
import { Icon } from '../../../components/Icon';
import { UpsertBookByIsbnModal } from '../../components/modal/UpsertBookByIsbnModal';
import { CategoryLeftBar } from '../../components/CategoryLeftBar';
import useGetProducts from '../../../hooks/useGetProducts';
import { TabType } from '../../enum';
import { Checkbox } from '../../../components/Checkbox';
import { useUpdateBookProduct } from '../../../hooks/useUpdateProduct';
import { toast } from 'react-toastify';
import { ProductType } from '../../../types/product';

export const BooksProductList = () => {
  const history = useHistory();
  const query = queryString.parse(history.location.search);
  const { setIsLoading, LoadingView } = useLoading();
  const [isIsbnModalOpened, setIsbnModalOpened] = useState<boolean>(false);

  const { data, isLoading } = useGetProducts(ProductType.BOOK);

  useEffect(() => {}, []);
  const updateBookProduct = useUpdateBookProduct({
    type: ProductType.BOOK,
    onSuccess: () => {
      toast('상품 노출 여부가 업데이트 되었습니다.', {
        type: 'success',
      });
    },
    onError: () => {
      toast('상품 노출 여부 업데이트 도중 오류가 발생하였습니다.', {
        type: 'error',
      });
    },
  });

  const updateProductInfo = (id: number, checked: boolean) => {
    updateBookProduct(id, { isDisplayHidden: checked });
  };

  const handleUpdate = () => {
    setIsbnModalOpened(true);
  };

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);
  // // Q. 카테고리 필요 도서 값을 페이지 들어오자마자 설정하고 있음 < 필요?
  // useEffect(() => {
  //   if (!!undefinedCategoryId && !query.cate) {
  //     query.cate = undefinedCategoryId.toString();
  //     history.push(`?${queryString.stringify(query)}`);
  //   }
  // }, [undefinedCategoryId]);

  return (
    <>
      <div className={'mt-5'}>
        <LoadingView />
        <div className="flex h-full gap-10">
          <CategoryLeftBar
            isShowUndefinedCategory={true}
            tabId={TabType.BOOK}
          />

          <div className="w-full space-y-5 relative">
            <AdminH1>도서상품 관리</AdminH1>
            <ProductTabBar products={data} />
            <div className="flex items-center justify-between">
              <div className="flex space-x-3">
                {/*<Button*/}
                {/*    text="상품추가"*/}
                {/*    className="filled-black h-10 text-sm"*/}
                {/*    to="/admin/books/product/add"*/}
                {/*/>*/}
                <Button
                  className="filled-black flex h-10 items-center space-x-2 text-sm"
                  onClick={handleUpdate}
                >
                  <Icon.RefreshCCW className="wh-4" />
                  <p>수동 업데이트하기</p>
                </Button>
                {/*<label htmlFor={'exel_bulk'}>*/}
                {/*    <div className="button filled-black flex h-10 items-center space-x-2 text-sm cursor-pointer">*/}
                {/*        <Icon.Upload className="wh-4"/>*/}
                {/*        <p>대량 업로드</p>*/}
                {/*    </div>*/}
                {/*</label>*/}
                {/*<input type="file"*/}
                {/*       id="exel_bulk"*/}
                {/*       className="hidden"*/}
                {/*       accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"*/}
                {/*       onChange={(e) => {*/}
                {/*           e.target.validity.valid && postBooksenUpdates(e.target.files?.item(0)!).then(r => getList())*/}
                {/*       }}*/}
                {/*/>*/}
              </div>
              <div className={'flex'}>
                <Search
                  className="h-10"
                  defaultValue={query.name?.toString()}
                  onSearch={(keyword) => {
                    query.name = keyword;
                    history.push(`?${queryString.stringify(query)}`);
                  }}
                />
                <Select
                  className={'ml-3 h-10 text-sm'}
                  defaultValue={'id'}
                  onChange={(e) => {
                    query.order = e.target.value;
                    history.push(`?${queryString.stringify(query)}`);
                  }}
                >
                  <option value="id">최신순 보기</option>
                  <option value="rank">랭킹순 보기</option>
                </Select>
              </div>
            </div>

            <Card>
              <Table>
                <Table.Head>
                  <Table.Row>
                    <Table.Th>노출</Table.Th>
                    <Table.Th>최근 업데이트일자</Table.Th>
                    <Table.Th>ISBN</Table.Th>
                    <Table.Th>상품명</Table.Th>
                    <Table.Th>실판매가</Table.Th>
                    <Table.Th>판매상태</Table.Th>
                    <Table.Th>랭킹</Table.Th>
                    <Table.Th></Table.Th>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {data?.items.length === 0 && (
                    <Table.Row>
                      <Table.Td>상품이 없습니다.</Table.Td>
                    </Table.Row>
                  )}
                  {data?.items.map((book, index) => (
                    <Table.Row key={index}>
                      <Table.Td>
                        <Checkbox
                          checked={!book.isDisplayHidden}
                          onChange={() =>
                            updateProductInfo(book.id, !book.isDisplayHidden)
                          }
                        />
                      </Table.Td>
                      <Table.Td>
                        {utcToLocalFormat(
                          book.updatedAt.toLocaleString(),
                          MomentFormat.YYYYMMDDHmm
                        )}
                      </Table.Td>
                      <Table.Td>{book.barcode}</Table.Td>
                      <Table.Td>{book.name}</Table.Td>
                      <Table.Td>{book.price.toLocaleString()}원</Table.Td>
                      <Table.Td>{productStatus(book)}</Table.Td>
                      <Table.Td>{book.categoryRank}</Table.Td>
                      <Table.Td className="text-right">
                        <Link
                          className="rounded-md font-normal text-indigo-600 hover:text-indigo-900"
                          to={`/admin/books/product/${book.id}`}
                        >
                          상세보기
                        </Link>
                      </Table.Td>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>

              <Pagination>
                <Pagination.Label
                  count={data?.items.length!}
                  total={data?.pagination?.totalItemCount!}
                />
                <Pagination.Nav
                  basePath={history.location.pathname}
                  total={data?.pagination?.totalItemCount!}
                />
              </Pagination>
            </Card>
          </div>
        </div>
      </div>

      <UpsertBookByIsbnModal
        open={isIsbnModalOpened}
        onClose={() => setIsbnModalOpened(false)}
      />
    </>
  );
};
