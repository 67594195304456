import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import {Admin} from './admin/Admin';
import {ScrollToTop} from './components/ScrollToTop';
import 'react-toastify/dist/ReactToastify.css';

function App() {
    return (
        <BrowserRouter>
            <ScrollToTop/>
            <Switch>
                <Route path="/" component={Admin}/>
            </Switch>
            <ToastContainer position="top-right"
                            autoClose={2000}
                            theme={'dark'}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover/>
        </BrowserRouter>
    );
}

export default App;
