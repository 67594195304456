export enum KeywordType {
    SEARCH = 'SEARCH',
    SECTION = 'SECTION',
    LIFE = 'LIFE',
}

export interface Keyword {
    id?: number
    type: KeywordType
    keyword: string
    order: number
}

export interface UpdateKeywordDto extends Keyword {
    id?: number
}