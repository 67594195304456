import {Checkbox} from "../../components/Checkbox";
import React, {useEffect} from "react";
import {getEventTags} from "../../api/eventTagAPI";
import {EventTagDto} from "../../types/product";
import {deleteProductEventTags, getProductEventTags, postProductEventTags} from "../../api/productAPI";
import {toast} from "react-toastify";

type EventTagSelectorProps = {
    productId: number;
}

const EventTagSelector = (props: EventTagSelectorProps) => {
    const [eventTags, setEventTags] = React.useState<EventTagDto[]>([]);
    const [selectedEventTags, setSelectedEventTags] = React.useState<EventTagDto[]>([]);

    const checkAction = (tagId: number, willChecked: boolean) => {
        if (willChecked === true) {
            postProductEventTags(props.productId, [tagId])
                .then(() => {
                    toast.success('태그 추가됨', {autoClose: 300});
                    getSelected();
                })
                .catch(e => console.error(e.response));
        } else {
            deleteProductEventTags(props.productId, [tagId])
                .then(() => {
                    toast.success('태그 삭제됨', {autoClose: 300});
                    getSelected();
                })
                .catch(e => console.error(e.response));
        }
    }

    const getSelected = () => {
        getProductEventTags(props.productId)
            .then(r => setSelectedEventTags(r.data))
            .catch(e => console.error(e.response));
    }

    useEffect(() => {
        getEventTags(``, 1, 999)
            .then(r => setEventTags(r.data.items))
            .catch(e => console.error(e.response));
        getSelected();
    }, [])

    return (
        <div>
            <div className="mb-2 text-lg font-semibold">이벤트 태그 설정</div>
            <div className="grid grid-cols-4 gap-5 pb-32 lg:grid-cols-6 xl:grid-cols-8">
                {eventTags.map((tag, index) => (
                    <Checkbox key={index} label={tag.name}
                              checked={!!selectedEventTags.find(item => item.id === tag.id)}
                              onChange={() => checkAction(tag.id, !selectedEventTags.find(item => item.id === tag.id))}/>
                ))}
            </div>
        </div>
    )
}

export default EventTagSelector