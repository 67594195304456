import axios from 'axios';
import {tokenState} from './ridge';
import {toast} from "react-toastify";
import {updateToken} from "../api/sessionAPI";

const token = tokenState.get();

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use((config) => {
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    } else {
        delete config.headers.Authorization;
    }
    return config;
});

api.interceptors.response.use(undefined, (error) => {
    if (error.response.status === 401) {
        updateToken()
            .then(() => window.location.reload())
            .catch(() => {
                tokenState.reset();
                window.location.assign('/admin/login');
            });
    }
    toast.error(error.response.data.message.toString());
    return Promise.reject(error);
});

export {api};
