import React, { useEffect, useRef, useState } from 'react';
import { Icon } from './Icon';
import { SectionSelectItem } from '../admin/pages/contents/SectionAddPage';
type SelectBoxProps = {
  placeholder: string;
  selectedItem: any;
  onSelect: (selectedItem: any) => void;
  items: SectionSelectItem[];
};
const SelectBox = ({
  placeholder,
  selectedItem,
  onSelect,
  items,
}: SelectBoxProps) => {
  const [isOpen, setOpen] = useState(false);
  const wrapperRef = useRef<any>(null);
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const handleClickTopView = () => {
    setOpen(!isOpen);
  };

  return (
    <div className={'w-full'} ref={wrapperRef}>
      <div
        className={
          'relative flex h-14 w-full cursor-pointer items-center rounded border border-gray-200 px-4'
        }
        onClick={handleClickTopView}
      >
        <span>{selectedItem ? selectedItem.title : placeholder}</span>
        <div className={'absolute right-6'}>
          {!isOpen ? <Icon.ChevronDown /> : <Icon.ChevronUp />}
        </div>
        {isOpen && (
          <div
            className={
              'bg-white-100 absolute top-[64px] left-0 z-10 w-full shadow'
            }
          >
            <div>
              {items.map((item: SectionSelectItem) => {
                return (
                  <div className={'relative'}>
                    <div
                      onClick={() => !item.disabled && onSelect(item)}
                      className={`relative flex cursor-pointer items-center justify-between gap-1 bg-white p-2 hover:bg-gray-100
                      ${item.disabled && 'cursor-auto hover:bg-white'}
                      `}
                      key={item.id}
                    >
                      {item.disabled && (
                        <div
                          className={
                            'absolute top-0 left-0 z-50 h-full w-full bg-white opacity-80'
                          }
                        />
                      )}
                      <span
                        className={`${
                          item === selectedItem
                            ? 'text-primary-500 font-pretendB'
                            : 'text-black'
                        } text-md`}
                      >
                        {item.title}
                      </span>
                      <div className={'h-14 w-auto'}>
                        <img
                          alt={item.title}
                          src={item.imgSrc}
                          className={'h-full w-full shadow'}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectBox;
