import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { Checkbox } from '../../../components/Checkbox';
import { Icon } from '../../../components/Icon';
import { TextArea } from '../../../components/TextArea';
import { TextField } from '../../../components/TextField';
import { FloalaEditor } from '../../components/FloalaEditor';
import { FileAbout } from '../../../types/common';
import { useForm } from 'react-hook-form';
import { CreateProductDto, DiscountType } from '../../../types/product';
import { postGeneralProduct } from '../../../api/productAPI';
import { toast } from 'react-toastify';
import CompanySelector from '../../components/CompanySelector';
import { uploadFile } from '../../../api/fileAPI';
import { Select } from '../../../components/Select';
import { useQuery } from 'react-query';
import { TabType } from '../../enum';
import { getCategory } from '../../../api/categoryAPI';
import useCategories from '../../../hooks/useCategories';

export const NormalProductAdd = () => {
  const history = useHistory();
  const form = useForm<CreateProductDto>();
  const { categories } = useCategories(TabType.LIFE);

  const [selectedCategory, setSelectedCategory] = useState<any>({
    parent: null,
    children: null,
  });
  const { data: subCategories } = useQuery(
    ['categories', TabType.BOOK, selectedCategory.parent?.id],
    () => getCategory(Number(selectedCategory.parent?.id)),
    {
      enabled: !!selectedCategory.parent,
    }
  );
  const handleParentSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const matchedCategory = categories.find(
      (category) => category.id === Number(e.target.value)
    );
    setSelectedCategory({ parent: matchedCategory, children: null });
  };

  const handleChildSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const matchedCategory = subCategories?.find(
      (category) => category.id === Number(e.target.value)
    );
    setSelectedCategory({ ...selectedCategory, children: matchedCategory });
  };
  const onSubmit = (data: CreateProductDto) => {
    if (!form.watch('companyId')) {
      toast.warn('업체를 선택해주세요');
    } else {
      const result = {
        ...data,
        categoryId: selectedCategory.children
          ? selectedCategory.children.id
          : selectedCategory.parent.id,
      };
      postGeneralProduct(result)
        .then((r) => {
          toast.success('상품이 생성되었습니다. 태그를 선택하세요.');
          history.replace(`/admin/normal/product/${r.data.id}`);
        })
        .catch((e) => console.error(e.response));
    }
  };

  useEffect(() => {
    // default values
    form.setValue('discountAmount', 0);
    form.setValue('discountType', DiscountType.PRICE);
    form.setValue('isSoldOut', false);
    form.setValue('isDisplayHidden', false);
    form.setValue('isNew', false);
    form.setValue('images', []);
  }, []);

  useEffect(() => {
    if (form.watch('discountType') === DiscountType.RATE) {
      const rPrice = form.watch('regularPrice')!;
      const rate = form.watch('discountAmount')!;
      if (rate > 100) {
        form.setValue('discountAmount', 100);
      }
      form.setValue('price', rPrice - (rPrice * rate) / 100);
    } else {
      const rPrice = form.watch('regularPrice')!;
      const discount = form.watch('discountAmount')!;
      if (discount > rPrice) {
        form.setValue('discountAmount', rPrice);
      }
      form.setValue('price', rPrice - discount);
    }
  }, [form.watch('discountAmount'), form.watch('regularPrice')]);

  return (
    <>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="space-y-4">
          <div className="flex space-x-5">
            <Checkbox
              label="상품 숨김처리하기"
              checked={form.watch('isDisplayHidden')}
              onChange={() =>
                form.setValue('isDisplayHidden', !form.watch('isDisplayHidden'))
              }
            />
            <Checkbox
              label="상품 품절처리하기"
              checked={form.watch('isSoldOut')}
              onChange={() =>
                form.setValue('isSoldOut', !form.watch('isSoldOut'))
              }
            />
          </div>
          <div className="flex space-x-5">
            {/*<Checkbox label="성인 인증 상품" checked={form.watch('isAdultOnly')}*/}
            {/*          onChange={() => form.setValue('isAdultOnly', !form.watch('isAdultOnly'))}/>*/}
            <Checkbox
              label="신상품으로 설정(NEW)"
              checked={form.watch('isNew')}
              onChange={() => form.setValue('isNew', !form.watch('isNew'))}
            />
          </div>
        </div>

        <div className="my-6 w-full bg-gray-50 py-2" />

        <div className="grid grid-cols-3 gap-5">
          <CompanySelector
            selectedCompanyId={form.watch('companyId')}
            onChange={(company) => form.setValue('companyId', company?.id!)}
          />

          <div className="col-span-3 text-lg font-semibold">상품 이미지</div>

          {(form.watch('images') || []).map((image, index) => (
            <div key={index}>
              <div className="relative aspect-[1/1] overflow-hidden rounded border">
                <img
                  className="absolute h-full w-full object-cover"
                  src={image}
                  alt=""
                />
              </div>
              <div className="mt-4 flex w-full">
                <Button className="filled-black w-full">
                  <label htmlFor={`imgChange_${index}`}>수정하기</label>
                  <input
                    type="file"
                    id={`imgChange_${index}`}
                    className="hidden"
                    accept="image/*"
                    onChange={(e) => {
                      e.target.validity.valid &&
                        uploadFile(
                          FileAbout.PRODUCT_GENERAL,
                          e.target.files?.item(0)!
                        )
                          .then((r) => form.setValue(`images.${index}`, r))
                          .catch((e) => console.error(e.response));
                    }}
                  />
                </Button>
                <Button
                  text={'삭제하기'}
                  className={'outlined-black ml-4 w-32'}
                  onClick={() => {
                    form.setValue(
                      `images`,
                      form.watch('images')?.filter((_, i) => i !== index)
                    );
                  }}
                />
              </div>
            </div>
          ))}
          <label htmlFor="imgPath">
            <div className="grid aspect-[1/1] place-items-center rounded border text-gray-500">
              <div className="flex flex-col items-center space-y-2 text-xs">
                <Icon.FileUpload />
                <p>이미지를 업로드 해주세요.</p>
              </div>
            </div>
            <input
              type="file"
              id="imgPath"
              className="hidden"
              accept="image/*"
              onChange={(e) => {
                e.target.validity.valid &&
                  uploadFile(
                    FileAbout.PRODUCT_GENERAL,
                    e.target.files?.item(0)!
                  )
                    .then((r) => {
                      form.setValue('images', [...form.watch('images')!, r]);
                    })
                    .catch((e) => console.error(e.response));
              }}
            />
          </label>
        </div>

        <div className="grid grid-cols-3 gap-5 pt-5">
          <TextField
            label="바코드"
            {...form.register('barcode', {
              required: '바코드를 입력하세요',
              setValueAs: (value) => value.trim(),
            })}
            helper={form.formState.errors.barcode?.message}
          />
          <TextField
            label="상품명"
            {...form.register('name', { required: '상품명을 입력하세요' })}
            helper={form.formState.errors.name?.message}
          />
          <div className="col-start-1">
            <TextField
              label="정가(원)"
              type={'number'}
              {...form.register('regularPrice', {
                required: '상품 가격을 입력하세요',
                setValueAs: (value) => Number(value),
              })}
              helper={form.formState.errors.regularPrice?.message}
            />
          </div>
          <div>
            {form.watch('discountType') === DiscountType.RATE ? (
              <TextField
                label="할인율(%)"
                type={'number'}
                defaultValue={10}
                {...form.register('discountAmount', {
                  required: '할인율을 입력하세요',
                  setValueAs: (value) => Number(value),
                })}
                helper={form.formState.errors.discountAmount?.message}
              />
            ) : (
              <TextField
                label="할인금액(원)"
                type={'number'}
                defaultValue={0}
                {...form.register('discountAmount', {
                  required: '할인율을 입력하세요',
                  setValueAs: (value) => Number(value),
                })}
                helper={form.formState.errors.discountAmount?.message}
              />
            )}
            <button
              type={'button'}
              className={'label text-blue-500'}
              onClick={() => {
                form.setValue(
                  'discountType',
                  form.watch('discountType') === DiscountType.RATE
                    ? DiscountType.PRICE
                    : DiscountType.RATE
                );
                form.setValue('discountAmount', 0);
              }}
            >
              전환 {'→'}{' '}
              {form.watch('discountType') === DiscountType.RATE ? '₩' : '%'}
            </button>
          </div>
          <TextField
            label="할인율 적용시 할인가(실판매가)"
            type={'number'}
            value={form.watch('price')}
            disabled={true}
          />
          <Select
            label={'대분류'}
            value={selectedCategory.parent?.id.toString()}
            onChange={handleParentSelect}
          >
            <option value={''}>선택하세요</option>
            {categories.map((category, index) => (
              <option key={index} value={category.id}>
                {category.name}
              </option>
            ))}
          </Select>
          <Select
            label={'중분류'}
            value={selectedCategory.children?.id.toString()}
            onChange={handleChildSelect}
          >
            <option hidden={true} value={''}>
              선택하세요
            </option>
            <option value={''}>전체</option>
            {subCategories?.map((category, index) => (
              <option key={index} value={category.id}>
                {category.name}
              </option>
            ))}
          </Select>
          <div className="col-span-3 ">
            <TextArea
              label="상세설명"
              className="h-32"
              {...form.register('contentText')}
            />
          </div>
          <div className="col-span-3 ">
            <div className="col-span-3 mb-2 text-lg font-semibold">
              상품 상세이미지
            </div>
            <FloalaEditor
              imageType={FileAbout.PRODUCT_GENERAL}
              onChange={(content) => form.setValue('contentHtml', content)}
            />
          </div>
        </div>

        <div className="w-full py-20" />

        <div className="fixed bottom-0 z-10 -ml-8 w-full space-x-4 border-t bg-white py-2 pl-10">
          <Button
            text="취소"
            className="outlined-black h-12 px-6"
            onClick={() => history.goBack()}
          />
          <Button
            type={'submit'}
            text="저장"
            className="filled-black h-12 px-6"
            disabled={form.watch('barcode') === ''}
          />
        </div>
      </form>
    </>
  );
};
