import {api} from "../plugins/axios";
import {FileAbout} from "../types/common";

export const uploadFile = (about: FileAbout, file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('file', file);
        api.post(`/files?about=${about}`, formData,
            {headers: {'Content-Type': 'multipart/form-data'}})
            .then(res => {
                if (res.status === 201) {
                    resolve(res.data.url);
                } else {
                    reject(res.data.message);
                }
            }).catch(err => {
            reject(err);
        });
    });
};