import { CurationProductDto } from './arcnShop';
import { TabType } from '../admin/enum';

export enum GridCardProductType {
  GRID_CARD_SECTION_1 = 'GRID_CARD_SECTION_1',
  GRID_CARD_SECTION_2 = 'GRID_CARD_SECTION_2',
  GRID_CARD_SECTION_3 = 'GRID_CARD_SECTION_3',
}

export interface GridCardProductDto {
  id: number;
  type: GridCardProductType;
  title: string;
  description: string;
  order: number;
  clickUrl: string;
  curationProducts: CurationProductDto[];
}

export interface CreateGridCardProductDto {
  type: GridCardProductType;
  title: string;
  description: string;
  order: number;
  clickUrl: string;
  productIds: number[];
  position: 'MAIN' | 'LIFE';
}

export interface UpdateGridCardProductDto {
  type?: GridCardProductType;
  title?: string;
  description?: string;
  order?: number;
  clickUrl?: string;
  productIds?: number[];
}
