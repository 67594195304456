import {Dialog, Transition} from '@headlessui/react';
import React, {Fragment, useEffect} from 'react';
import {Button} from '../../../components/Button';
import {Icon} from '../../../components/Icon';
import {Select} from '../../../components/Select';
import {getPromotions, setPromotion} from "../../../api/promotionAPI";
import {PromotionDto} from "../../../types/promotion";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import moment from "moment";

interface PromotionProps {
    open: boolean;
    onClose: () => void;
    productId: number;
}

export const Promotion: React.FC<PromotionProps> = (props: PromotionProps) => {
    const history = useHistory();
    const {open, onClose} = props;
    const [promotions, setPromotions] = React.useState<PromotionDto[]>([]);
    const [promotionId, setPromotionId] = React.useState<number | undefined>(undefined);

    useEffect(() => {
        getPromotions('', 1, 1000).then(res => {
            const list = res.data.items;
            setPromotions(list.filter(item => moment(item.startAt) > moment()));
        })
    }, [])

    const onSavePromotion = () => {
        !!promotionId && setPromotion(promotionId, props.productId)
            .then(() => {
                toast.success('상품이 등록되었습니다.');
                onClose();
            });
    }

    if (!open) return <></>;
    return (
        <>
            <Transition appear show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-10 overflow-y-auto bg-gray-500 bg-opacity-75 transition-opacity"
                    onClose={() => {
                        onClose();
                    }}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0"/>
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
              &#8203;
            </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div
                                className="my-8 inline-block w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <div className="flex items-center justify-between">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        프로모션 선택
                                    </Dialog.Title>
                                    <Icon.X onClick={onClose} className="cursor-pointer"/>
                                </div>

                                <div className="my-6 space-y-4">
                                    <Select onChange={e => setPromotionId(Number(e.target.value))}>
                                        <option hidden value={''} >{promotions.length === 0 ? '준비중인 프로모션이 없습니다' : '선택하세요'}</option>
                                        {promotions.map(promotion => (
                                            <option key={promotion.id} value={promotion.id}>{promotion.title}</option>
                                        ))}
                                    </Select>
                                </div>

                                <div className="mt-4 space-y-2">
                                    <Button disabled={!promotionId}
                                            text="저장하기"
                                            className="filled-black h-12 w-full text-sm"
                                            onClick={onSavePromotion}
                                    />
                                    {/*<Button*/}
                                    {/*  text="생성하기"*/}
                                    {/*  className="filled-gray-200 h-12 w-full text-sm text-gray-800"*/}
                                    {/*/>*/}
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};
