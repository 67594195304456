import React from 'react';
import {BannerType} from "../../../types/banner";
import BannerListTemplate from "../../components/templates/BannerListTemplate";
import {AdminH1} from "../../components/AdminH1";

export const ArcBannerList = () =>
    <>
        <AdminH1>아크앤샵 메인 배너 관리</AdminH1>
        <BannerListTemplate type={BannerType.SHOP}/>
    </>
