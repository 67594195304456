import { ProductDto } from './product';
import { CurationProductDto, UpdateCurationProductDto } from './arcnShop';
import { MainBannerDto } from './banner';

export enum PostingType {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
}

export interface PostingDto {
  id: number;
  createdAt: string;
  updatedAt: string;
  type: PostingType;
  title: string;
  description: string;
  content?: string;
  pcImageUrl?: string;
  mobileImageUrl?: string;
  products: CurationProductDto[];
  banners: MainBannerDto[];
}

export interface CreatePostingDto {
  type: PostingType;
  title: string;
  description: string;
  content?: string;
  pcImageUrl?: string;
  mobileImageUrl?: string;
  products: ProductDto[];
}

export interface UpdatePostingDto {
  type?: PostingType;
  title?: string;
  description?: string;
  content?: string;
  pcImageUrl?: string;
  mobileImageUrl?: string;
  products?: UpdateCurationProductDto[];
}
