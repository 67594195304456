import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';
import { Button } from '../../../components/Button';
import { Icon } from '../../../components/Icon';
import { TextField } from '../../../components/TextField';
import {
  getCurationProduct,
  updateCurationProduct,
} from '../../../api/ArcnShopAPI';
import { TextArea } from '../../../components/TextArea';
import { ProductSearch } from '../templates/ProductSearch';
import { ProductDto } from '../../../types/product';
import { CurationProductDto } from '../../../types/arcnShop';

export interface ModalOpen {
  open: boolean;
  target?: number | null;
}

interface ArcProductModalProps {
  open: ModalOpen;
  onClose: () => void;
  onSave: (
    productId: number,
    order: number,
    title?: string,
    description?: string
  ) => void;
  isDetailedProduct?: boolean;
  isHasOrder?: boolean;
  isNoTitle?: boolean;
  preSelectedItems: CurationProductDto[];
}

export const ArcProductModal: React.FC<ArcProductModalProps> = (
  props: ArcProductModalProps
) => {
  const {
    open,
    onClose,
    onSave,
    isDetailedProduct = false,
    isHasOrder = true,
    isNoTitle = false,
  } = props;
  const [keyword, setKeyword] = useState<string>('');
  const [selectedProduct, setSelectedProduct] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const [order, setOrder] = useState<number>(0);
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const resetModal = () => {
    setKeyword('');
    setSelectedProduct(null);
    setOrder(0);
    setTitle('');
    setDescription('');
  };

  const saveOrModify = () => {
    if (open.target) {
      updateCurationProduct(open.target, {
        productId: selectedProduct!.id,
        order: order,
        title: title,
        description: description,
      })
        .then((res) => onClose())
        .catch((err) => console.error(err.response));
    } else {
      onSave(selectedProduct!.id, order, title, description);
      onClose();
    }
    resetModal();
  };

  useEffect(() => {
    if (open.target) {
      getCurationProduct(open.target)
        .then((res) => {
          setSelectedProduct({
            id: res.data.product.id,
            name: res.data.product.name,
          });
          setOrder(res.data.order);
          setTitle(res.data.title);
          setDescription(res.data.description);
        })
        .catch((err) => console.error(err.response));
    }
  }, [open]);

  if (!open) return <></>;
  return (
    <>
      <Transition appear show={open.open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto bg-gray-500 bg-opacity-75 transition-opacity"
          onClose={() => {
            resetModal();
            onClose();
          }}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="my-8 inline-block w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex items-center justify-between">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    상품 추가 / 수정
                  </Dialog.Title>
                  <Icon.X onClick={onClose} className="cursor-pointer" />
                </div>

                <div className="my-6 space-y-4">
                  {isHasOrder && (
                    <TextField
                      label="노출 우선순위"
                      className="h-12"
                      type={'number'}
                      min={0}
                      value={order > 0 ? order : ''}
                      onChange={(e) => setOrder(Number(e.target.value))}
                    />
                  )}
                  <ProductSearch
                    preSelectedItems={props.preSelectedItems}
                    onSelect={(product) => {
                      setSelectedProduct({
                        id: product.id,
                        name: product.name,
                      });
                    }}
                    selectedProducts={selectedProduct ? [selectedProduct] : []}
                    onDelete={() => setSelectedProduct(null)}
                  />
                  {isDetailedProduct && (
                    <>
                      {!isNoTitle && (
                        <TextField
                          label="타이틀"
                          className="h-12"
                          type={'text'}
                          min={0}
                          value={title || ''}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      )}
                      <TextArea
                        label="상세설명"
                        className="h-32"
                        defaultValue={description || ''}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </>
                  )}
                </div>

                <div className="mt-4 space-y-2">
                  <Button
                    disabled={!selectedProduct}
                    onClick={saveOrModify}
                    text="저장하기"
                    className="filled-black h-12 w-full text-sm"
                  />
                  <Button
                    text="취소하기"
                    className="filled-gray-200 h-12 w-full text-sm text-gray-800"
                    onClick={() => {
                      resetModal();
                      onClose();
                    }}
                  />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
