import React, {useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Card} from '../../../components/Card';
import {Pagination} from '../../../components/Pagination';
import {Table} from '../../../components/Table';
import {AdminH1} from '../../components/AdminH1';
import {Button} from '../../../components/Button';
import {getCurations} from "../../../api/curationAPI";
import {CurationDto} from "../../../types/curation";
import {Paged} from "../../../types/common";
import queryString from "query-string";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {toast} from "react-toastify";
import {Search} from "../../../components/Search";

export const CategoryList = () => {
    const history = useHistory();
    const query = queryString.parse(history.location.search);
    const [curations, setCurations] = React.useState<Paged<CurationDto>>({items: []});

    const getList = () => {
        let nQuery = ``;
        if (query.keyword) {
            nQuery += `&keyword=${query.keyword}`;
        }
        getCurations(`${nQuery}&order[id]=DESC`, Number(query.page), Number(query.limit)).then(res => {
            setCurations(res.data);
        }).catch(err => console.error(err.response));
    }

    useEffect(() => {
        getList();
    }, [history.location.search]);

    return (
        <>
            <AdminH1>카테고리 생성</AdminH1>
            <div className={'flex justify-between'}>
                <Button
                    text="신규 카테고리 추가"
                    className="filled-black h-12"
                    to="/admin/category/add"
                />
                <Search className="h-10"
                        defaultValue={query.keyword?.toString()}
                        onSearch={(keyword) => {
                            query.keyword = keyword;
                            history.push(`?${queryString.stringify(query)}`);
                        }}/>
            </div>

            <Card>
                <Table>
                    <Table.Head>
                        <Table.Row>
                            <Table.Th>ID</Table.Th>
                            <Table.Th>카테고리명</Table.Th>
                            <Table.Th>클릭시 URL</Table.Th>
                            <Table.Th></Table.Th>
                        </Table.Row>
                    </Table.Head>
                    <Table.Body>
                        {curations.items.length === 0 && (
                            <Table.Row>
                                <Table.Td>데이터가 없습니다.</Table.Td>
                            </Table.Row>
                        )}
                        {curations.items.map((category) => (
                            <Table.Row key={category.id}>
                                <Table.Td>{category.id}</Table.Td>
                                <Table.Td>{category.name}</Table.Td>
                                <Table.Td>
                                    <CopyToClipboard text={`${process.env.REACT_APP_FRONT_URL}/curations/${category.id}`} onCopy={() => toast.info('복사되었습니다.')}>
                                        <span className={'text-blue-500 underline cursor-pointer'}>{`${process.env.REACT_APP_FRONT_URL}/curations/${category.id}`}</span>
                                    </CopyToClipboard>
                                </Table.Td>
                                <Table.Td className="text-right">
                                    <Link
                                        className="rounded-md font-normal text-indigo-600 hover:text-indigo-900"
                                        to={`/admin/category/${category.id}`}
                                    >
                                        상세보기
                                    </Link>
                                </Table.Td>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>

                <Pagination>
                    <Pagination.Label count={curations.items.length} total={curations.pagination?.totalItemCount!}/>
                    <Pagination.Nav basePath={history.location.pathname} total={curations.pagination?.totalItemCount!}/>
                </Pagination>
            </Card>
        </>
    );
};
