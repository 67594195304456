import { Icon } from '../../../components/Icon';
import { Search } from '../../../components/Search';
import React, { useState } from 'react';
import { ProductDto, ProductType } from '../../../types/product';
import { getProducts } from '../../../api/productAPI';
import { toast } from 'react-toastify';
import { CurationProductDto } from '../../../types/arcnShop';
import ReactDragListView from 'react-drag-listview';
import { getProductQueryString } from '../../../utils/getProductQueryString';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { PageQuery } from '../../../types/common';
import { productTypeMapping } from '../../constants/common';

export interface ProductSearchProps {
  onSelect: (product: ProductDto) => void;
  onDelete: (deletedProduct: { id: number; name: string }) => void;
  selectedProducts: { id: number; name: string }[];
  extraText?: string;
  preSelectedItems?: CurationProductDto[];
  onDragEnd?: (fromIndex: number, toIndex: number) => void;
}

export const ProductSearch = (props: ProductSearchProps) => {
  const history = useHistory();
  const queryValue = queryString.parse(history.location.search);
  const [products, setProducts] = useState<ProductDto[]>([]);

  const [isLoading, setLoading] = useState(false);
  const params: PageQuery = {
    page: Number(queryValue.page) || 1,
    itemsPerPage: Number(queryValue.limit) || 30,
  } ;
  const onSearch = (name: string) => {
    setLoading(true);
    let query = '';
    if (/^-?\d+$/.test(name)) {
      query = `where[barcode]=${name}`;
    } else {
      query = `name=${name}`;
    }
    if (queryValue.type && typeof queryValue.type === 'string' && queryValue.type in productTypeMapping) {
      query += `&type=${productTypeMapping[queryValue.type]}`;
    }

    getProducts(query, params)
      .then((res) => {
        const items = res.data.items;
        if (items.length === 0) {
          toast.info('검색한 상품이 없습니다');
        } else {
          setProducts(items);
        }
      })
      .catch((err) => console.error(err.response))
      .finally(() => setLoading(false));
  };

  const dragProps = {
    onDragEnd: (fromIndex: number, toIndex: number) => {
      !!props.onDragEnd && props.onDragEnd(fromIndex, toIndex);
    },
    nodeSelector: 'div',
    handleSelector: 'div.dragger',
  };

  return (
    <>
      <div>
        <div className="label">상품 검색 {props.extraText}</div>

        <Search
          height={14}
          onSearch={(name) => onSearch(name)}
          isLoading={isLoading}
        />
        {products.length > 0 && (
          <div
            className={
              'mt-2 max-h-80 overflow-scroll rounded-xl border border-gray-200 bg-white px-4 py-2'
            }
          >
            {products.map((product, index) => (
              <div className={'py-2'} key={index}>
                <button
                  className={'w-full text-left'}
                  onClick={() => {
                    setProducts([]);
                    props.onSelect(product);
                  }}
                >
                  {product.name}
                  {!!props.preSelectedItems &&
                  !!props.preSelectedItems!.find(
                    (item) => item.product.id === product.id
                  ) ? (
                    <span className={'text-red-500'}>
                      (이미 등록된 상품입니다)
                    </span>
                  ) : (
                    ''
                  )}
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
      {props.selectedProducts.length > 0 && (
        <div>
          <div className="label">선택된 상품</div>
          <ReactDragListView {...dragProps}>
            {props.selectedProducts.map((product, index) => (
              <div
                key={index}
                className={
                  'dragger mt-2 flex justify-between rounded-xl border border-gray-200 bg-white px-4 py-3'
                }
              >
                {product.name}
                <Icon.X
                  onClick={() => props.onDelete(product)}
                  className="cursor-pointer"
                />
              </div>
            ))}
          </ReactDragListView>
        </div>
      )}
    </>
  );
};
