import {useEffect, useState} from "react";
import {Select} from "../../components/Select";
import {getKindKeys} from "../../api/promotionAPI";

export interface PromotionKindKeySelectorProps {
    onSelect: (key: string) => void;
    defaultValue?: string;
    disabled?: boolean;
}

export const PromotionKindKeySelector = (props: PromotionKindKeySelectorProps) => {
    const [keys, setKeys] = useState<string[]>([]);
    const [selectedKey, setSelectedKey] = useState<string>(props.defaultValue || '');

    useEffect(() => {
        getKindKeys().then(res => setKeys(res.data.items));
    }, [])

    useEffect(() => {
        props.onSelect(props.defaultValue || keys[0]);
        setSelectedKey(props.defaultValue || keys[0]);
    }, [keys, props.defaultValue])

    return (
        <Select label={'프로모션 유형선택'}
                value={selectedKey}
                onChange={e => props.onSelect(e.target.value)}
                disabled={props.disabled || false}
        >
            {keys.map((key, index) => (
                <option value={key} key={index}>{key}</option>
            ))}
        </Select>
    )
}