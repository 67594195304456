import React from 'react';
import { Button } from '../../../components/Button';
import { TextField } from '../../../components/TextField';
import { AdminH1 } from '../../components/AdminH1';
import { KeywordType } from '../../../types/keyword';
import useKeyword from '../../../hooks/useKeyword';

export const KeywordPage = () => {
  const { keywords, setKeywords, update } = useKeyword(KeywordType.SEARCH);

  return (
    <div className="space-y-4">
      <AdminH1>검색어 관리</AdminH1>
      <div className="grid grid-cols-3 gap-5 pb-20">
        {keywords
          .sort((a, b) => a.order - b.order)
          .slice(0, 30)
          .map((keyword, index) => (
            <TextField
              label={String(keyword.order)}
              key={index}
              defaultValue={keyword.keyword}
              onChange={(e) => {
                const newKeywords = [...keywords];
                newKeywords[index].keyword = e.target.value;
                setKeywords(newKeywords);
              }}
            />
          ))}
      </div>

      <div className="fixed bottom-0 -ml-8 w-full space-x-4 border-t bg-white py-2 pl-10">
        <Button
          text="저장"
          className="filled-black h-12 px-6"
          onClick={() => update()}
        />
      </div>
    </div>
  );
};
