import { ProductType } from '../types/product';

export const getProductQueryString = (query: any, type?: ProductType) => {
  let result = '';
  if (type) {
    result += `where[type]=${type}`;
  }
  if (query.sub) {
    result += `&categoryId=${query.sub}`;
  } else {
    result += `&categoryId=${query.cate}`;
  }
  if (query.name && /^-?\d+$/.test(query.name.toString())) {
    result += `&where[barcode]=${query.name}`;
  } else if (query.name) {
    result += `&name=${query.name}`;
  }
  if (query.company) result += `&where[companyId]=${query.company}`;

  if (query.order === 'rank') {
    result += `&sortBy=rank`;
  } else {
    result += `&sortBy=recent`;
  }
  switch (query.tab) {
    case '0':
      result += ``;
      break;
    case '1':
      result += `&where[isSoldOut]=0&where[isDisplayHidden]=0`;
      break;
    case '2':
      result += `&where[isSoldOut]=1`;
      break;
    case '3':
      result += `&where[isDisplayHidden]=1`;
      break;
  }
  return result;
};
