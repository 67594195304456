import React, { useEffect } from 'react';
import { Paged } from '../../../types/common';
import { BannerType, MainBannerDto } from '../../../types/banner';
import { getBanners, updateBanner } from '../../../api/bannerAPI';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { Table } from '../../../components/Table';
import { Checkbox } from '../../../components/Checkbox';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';
import { Link, useHistory } from 'react-router-dom';
import { Pagination } from '../../../components/Pagination';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Reorderable from '../Reorderable';

export interface BannerListTemplateProps {
  type: BannerType;
  paged?: boolean;
  curationId?: number;
  postingId?: number;
  fallBannerList?: MainBannerDto[];
}

const BannerListTemplate = (props: BannerListTemplateProps) => {
  const { type, paged = true, curationId, postingId } = props;

  const { location } = useHistory();
  const { page, limit } = queryString.parse(location.search);
  const [banners, setBanners] = React.useState<Paged<MainBannerDto>>({
    items: [],
  });

  const updateBannerShow = (id: number, isShow: boolean) => {
    updateBanner(id, { isShow })
      .then(() => getList())
      .catch((err) => console.error(err.response));
  };

  const getList = () => {
    let query = '';
    if (!!curationId) query += `where[curationId]=${curationId}&`;
    if (!!postingId) query += `where[postingId]=${postingId}&`;
    query += `where[type]=${type}&order[isShow]=DESC&order[order]=ASC`;
    getBanners(query, paged ? Number(page) : 0, paged ? Number(limit) : 999)
      .then((res) => setBanners(res.data))
      .catch((err) => console.error(err.response));
  };

  useEffect(() => {
    !!props.fallBannerList
      ? setBanners({ items: props.fallBannerList })
      : getList();
  }, [location.search, props.fallBannerList]);

  return (
    <>
      <div>
        <Button
          type={'button'}
          text="배너추가"
          className="filled-black mb-4 h-12"
          to={
            `/admin/banner/add?banner=${type}` +
            `${curationId ? `&curationId=${curationId}` : ''}` +
            `${postingId ? `&postingId=${postingId}` : ''}`
          }
        />
      </div>

      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>노출여부</Table.Th>
              <Table.Th>노출 우선순위</Table.Th>
              <Table.Th>등록일자</Table.Th>
              <Table.Th>배너명</Table.Th>
              <Table.Th>URL</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {banners.items.length === 0 && (
              <Table.Row>
                <Table.Td colSpan={7}>배너가 없습니다.</Table.Td>
              </Table.Row>
            )}
            {banners.items
              .sort((a, b) => a.order! - b.order!)
              .map((banner) => (
                <Table.Row key={banner.id}>
                  <Table.Td>
                    <Checkbox
                      checked={banner.isShow}
                      onChange={() =>
                        updateBannerShow(banner.id!, !banner.isShow)
                      }
                    />
                  </Table.Td>
                  <Table.Td>
                    <Reorderable
                      order={banner.order!}
                      onReordered={(order) => {
                        updateBanner(banner.id!, {
                          isShow: banner.isShow,
                          order,
                        })
                          .then(() => {
                            toast.success('순서가 변경되었습니다.');
                            getList();
                          })
                          .catch((err) => console.error(err.response));
                      }}
                    />
                  </Table.Td>
                  <Table.Td>
                    {utcToLocalFormat(
                      banner.createdAt!.toLocaleString(),
                      MomentFormat.YYYYMMDDHmm
                    )}
                  </Table.Td>
                  <Table.Td>{banner.name}</Table.Td>
                  <Table.Td>
                    <CopyToClipboard
                      text={banner.url!}
                      onCopy={() => toast.info('복사되었습니다.')}
                    >
                      <span
                        className={'cursor-pointer text-blue-500 underline'}
                      >
                        {banner.url}
                      </span>
                    </CopyToClipboard>
                  </Table.Td>
                  <Table.Td className="text-right">
                    <Link
                      className="rounded-md font-normal text-indigo-600 hover:text-indigo-900"
                      to={`/admin/banner/${banner.id}`}
                    >
                      상세보기
                    </Link>
                  </Table.Td>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>

        {paged && (
          <Pagination>
            <Pagination.Label
              count={banners.items.length}
              total={banners.pagination?.totalItemCount!}
            />
            <Pagination.Nav
              basePath={location.pathname}
              total={banners.pagination?.totalItemCount!}
            />
          </Pagination>
        )}
      </Card>
    </>
  );
};

export default BannerListTemplate;
