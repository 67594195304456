import React, {Fragment, useEffect, useState} from "react";
import {getOriginCoupons, postUsersCoupon} from "../../../api/couponsAPI";
import {Select} from "../../../components/Select";
import {CouponDto} from "../../../types/coupons.type";
import {Dialog, Transition} from "@headlessui/react";
import {Icon} from "../../../components/Icon";
import {Button} from "../../../components/Button";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";

type CouponModalProps = {
    open: boolean;
    onClose: () => void;
    onComplete: () => void;
}

export const CouponModal = (props: CouponModalProps) => {
    const [coupons, setCoupons] = useState<CouponDto[]>([]);
    const [selected, setSelected] = useState<number | null>(null);
    const history = useHistory();
    const userId = Number(history.location.pathname.split("/")[3]);

    const submit = () => {
        if (selected) {
            postUsersCoupon(userId, selected).then(() => {
                props.onComplete();
            });
        } else {
            toast('쿠폰을 선택해 주세요');
        }
    }

    useEffect(() => {
        getOriginCoupons().then(res => setCoupons(res.data.items));
    }, [])

    if (!props.open) return <></>;
    return (
        <>
            <Transition appear show={props.open} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-10 overflow-y-auto bg-gray-500 bg-opacity-75 transition-opacity"
                    onClose={props.onClose}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0"/>
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
              &#8203;
            </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div
                                className="my-8 inline-block w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <div className="flex items-center justify-between mb-5">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        쿠폰 수동 발급하기
                                    </Dialog.Title>
                                    <Icon.X onClick={props.onClose} className="cursor-pointer"/>
                                </div>

                                <Select className={'h-12 text-sm mr-4'}
                                        value={selected?.toString()}
                                        onChange={(e) => {
                                            const id = Number(e.target.value);
                                            setSelected(id);
                                        }}
                                >
                                    <option hidden value={''}>쿠폰을 선택해 주세요</option>
                                    {coupons.map((item, index) => {
                                        return (
                                            <option key={index} value={item.id}>{item.title} {item.description}</option>
                                        )
                                    })}
                                </Select>

                                <div className="mt-4">
                                    <Button onClick={submit}
                                            text="저장하기"
                                            className="filled-black h-12 text-sm"
                                    />
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
