import {
  GridCardProductDto,
  GridCardProductType,
} from '../types/gridCardProduct';
import React, { useEffect } from 'react';
import {
  deleteGridCardProduct,
  getGridCardProducts,
} from '../api/gridCardProductAPI';
import { useHistory } from 'react-router';
import queryString from 'query-string';

const useGridCards = (type: GridCardProductType) => {
  const history = useHistory();
  const query = queryString.parse(history.location.search);
  const [gridCardProducts, setGridCardProducts] = React.useState<
    GridCardProductDto[]
  >([]);

  const getList = () => {
    getGridCardProducts(type, query.type === 'MINI' ? 'MAIN' : 'LIFE')
      .then((res) => {
        const list = res.data.sort((a, b) => a.order - b.order);
        setGridCardProducts(list);
      })
      .catch((err) => console.error(err.response));
  };

  const onDelete = (id: number) => {
    const confirm = window.confirm('정말 삭제하시겠습니까?');
    if (confirm) {
      deleteGridCardProduct(id)
        .then(() => getList())
        .catch((err) => console.error(err.response));
    }
  };

  useEffect(() => getList(), []);

  return {
    gridCardProducts,
    setGridCardProducts,
    onDelete,
    getList,
  };
};

export default useGridCards;
