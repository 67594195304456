import {AdminH1} from "../../components/AdminH1";
import React, {useEffect, useState} from "react";
import queryString from "query-string";
import {Search} from "../../../components/Search";
import {useHistory} from "react-router-dom";
import {Button} from "../../../components/Button";
import {Card} from "../../../components/Card";
import {Table} from "../../../components/Table";
import {downloadReviewsExcel, getReviews} from "../../../api/reviewsAPI";
import {Pagination} from "../../../components/Pagination";
import {ReviewDto} from "../../../types/review";
import {Paged} from "../../../types/common";
import {Checkbox} from "../../../components/Checkbox";
import {ReviewModal} from "../../components/ReviewModal";
import {MomentFormat, utcToLocalFormat} from "../../../plugins/moment";

export const ReviewList = () => {
    const history = useHistory();
    const query = queryString.parse(history.location.search);
    const [reviews, setReviews] = useState<Paged<ReviewDto>>({items: []});
    const [selectedIds, setSelectedIds] = useState<number[]>([]);
    const [modal, setModal] = useState<{ isOpen: boolean, content?: ReviewDto }>({isOpen: false});

    const excelDownload = () => {
        if (selectedIds.length === 0) {
            alert('다운로드 받을 내용이 없습니다.');
        } else {
            downloadReviewsExcel(selectedIds);
        }
    }

    const allOrNothing = () => {
        if (selectedIds.length === reviews.items.length) {
            setSelectedIds([]);
        } else {
            setSelectedIds(reviews.items.map(item => item.id));
        }
    }

    const getList = () => {
        const keyword = query.keyword ? query.keyword as string : '';
        getReviews(keyword, Number(query.page), Number(query.limit)).then(res => {
            const list = res.data.items.sort((a, b) => b.id - a.id);
            setReviews({items: list, pagination: res.data.pagination});
        });
    }

    useEffect(() => {
        getList();
    }, [history.location])

    return (
        <>
            <AdminH1>리뷰 관리</AdminH1>
            <div className={'flex justify-between'}>
                <Button
                    text="Excel export"
                    className="filled-black h-12"
                    onClick={excelDownload}
                />
                <Search className="h-10"
                        defaultValue={query.name?.toString()}
                        onSearch={(keyword) => {
                            query.keyword = keyword;
                            history.push(`?${queryString.stringify(query)}`);
                        }}/>
            </div>
            <Card>
                <Table>
                    <Table.Head>
                        <Table.Row>
                            <Table.Th>
                                <Checkbox checked={selectedIds.length === reviews.items.length}
                                          onChange={allOrNothing}
                                />
                            </Table.Th>
                            <Table.Th>등록일자</Table.Th>
                            <Table.Th>상품명</Table.Th>
                            <Table.Th>ISBN</Table.Th>
                            <Table.Th>회원명</Table.Th>
                            <Table.Th>별점</Table.Th>
                            <Table.Th>노출여부</Table.Th>
                            <Table.Th>내용</Table.Th>
                            <Table.Th></Table.Th>
                        </Table.Row>
                    </Table.Head>
                    <Table.Body>
                        {reviews.items.length === 0 && (
                            <Table.Row>
                                <Table.Td colSpan={8}>리뷰가 없습니다.</Table.Td>
                            </Table.Row>
                        )}
                        {reviews.items.map((review, index) => (
                            <Table.Row key={index}>
                                <Table.Td>
                                    <Checkbox checked={selectedIds.find(id => id === review.id) !== undefined}
                                              onChange={() => {
                                                  if (selectedIds.find(id => id === review.id) !== undefined) {
                                                      setSelectedIds(selectedIds.filter(id => id !== review.id));
                                                  } else {
                                                      setSelectedIds([...selectedIds, review.id]);
                                                  }
                                              }}
                                    />
                                </Table.Td>
                                <Table.Td>
                                    {utcToLocalFormat(
                                        review.createdAt.toLocaleString(),
                                        MomentFormat.YYYYMMDDHmm
                                    )}
                                </Table.Td>
                                <Table.Td>{review.product.name}</Table.Td>
                                <Table.Td>{review.product.barcode}</Table.Td>
                                <Table.Td>{review.user?.name || '탈퇴한 유저'}</Table.Td>
                                <Table.Td>{review.score}</Table.Td>
                                <Table.Td>{review.isHidden ? '숨김' : '노출'}</Table.Td>
                                <Table.Td>{review.content}</Table.Td>
                                <Table.Td className="text-right">
                                    <button className="rounded-md font-normal text-indigo-600 hover:text-indigo-900"
                                            onClick={() => setModal({isOpen: true, content: review})}>
                                        상세보기
                                    </button>
                                </Table.Td>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
                <Pagination>
                    <Pagination.Label count={reviews.items.length} total={reviews.pagination?.totalItemCount!}/>
                    <Pagination.Nav basePath={history.location.pathname} total={reviews.pagination?.totalItemCount!}/>
                </Pagination>
            </Card>
            <ReviewModal title={'코멘트 정보'}
                         content={modal.content!}
                         open={modal.isOpen}
                         onClose={() => {
                             setModal({isOpen: false});
                             getList();
                         }}
            />
        </>
    )
}