import {api} from "../plugins/axios";
import {Paged} from "../types/common";
import {CreateMainBannerDto, MainBannerDto, UpdateMainBannerDto} from "../types/banner";

export const makeBanner = (data: CreateMainBannerDto) => {
    return api.post<MainBannerDto>('/main-banners', data);
}

export const getBanners = (query?: string, page?: number, itemsPerPage?: number) => {
    const params = {
        page: page || 1,
        itemsPerPage: itemsPerPage || 30,
    };
    return api.get<Paged<MainBannerDto>>(`/main-banners?${query}`, {params});
}

export const getBanner = (id: number) => {
    return api.get<MainBannerDto>(`/main-banners/${id}`);
}

export const updateBanner = (id: number, data: UpdateMainBannerDto) => {
    return api.patch<MainBannerDto>(`/main-banners/${id}`, data);
}

export const deleteBanner = (id: number) => {
    return api.delete<void>(`/main-banners/${id}`);
}