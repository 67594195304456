import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../../components/Button';
import { TextArea } from '../../../../components/TextArea';
import { TextField } from '../../../../components/TextField';
import { AdminH1 } from '../../../components/AdminH1';
import { useForm } from 'react-hook-form';
import {
  CreateGridCardProductDto,
  GridCardProductType,
} from '../../../../types/gridCardProduct';
import { postGridCardProduct } from '../../../../api/gridCardProductAPI';
import { ProductSearch } from '../../../components/templates/ProductSearch';
import { toast } from 'react-toastify';
import queryString from 'query-string';

export interface GridTypeProductAddProps {
  gridCardProductType: GridCardProductType;
}

export const GridTypeProductAdd = (props: GridTypeProductAddProps) => {
  const history = useHistory();
  const query = queryString.parse(history.location.search);
  const { goBack } = useHistory();
  const form = useForm<CreateGridCardProductDto>();
  const [products, setProducts] = React.useState<
    { id: number; name: string }[]
  >([]);

  const onSubmit = (data: CreateGridCardProductDto) => {
    data.type = props.gridCardProductType;
    data.productIds = products.map((product) => product.id);
    data.position = query.type === 'MINI' ? 'MAIN' : 'LIFE';
    if (data.productIds.length === 5) {
      postGridCardProduct(data)
        .then(() => {
          toast.success('저장되었습니다.');
          goBack();
        })
        .catch((err) => console.error(err.response));
    } else {
      toast.warn('상품을 5개 선택해주세요.');
    }
  };

  return (
    <div className="max-w-md space-y-4">
      <AdminH1>카드 추가</AdminH1>
      <ProductSearch
        extraText={'(5개 선택 필수)'}
        selectedProducts={products}
        onSelect={(product) => {
          if (products.length < 5) {
            setProducts([
              ...products,
              {
                id: product.id,
                name: product.name,
              },
            ]);
          }
        }}
        onDragEnd={(fromIndex: number, toIndex: number) => {
          const data = [...products];
          const item = data.splice(fromIndex, 1)[0];
          data.splice(toIndex, 0, item);
          setProducts(data);
        }}
        onDelete={(deletedProduct) => {
          let nProducts = [...products].filter(
            (product) => product.id !== deletedProduct.id
          );
          setProducts([...nProducts]);
        }}
      />
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className={'space-y-4'}>
          <TextField
            label="노출 우선순위"
            className="h-12"
            {...form.register('order')}
          />
          <TextField label="타이틀" {...form.register('title')} />
          <TextArea
            label="상세설명"
            className="h-52"
            {...form.register('description')}
          />
          <TextField label="버튼 클릭시 url" {...form.register('clickUrl')} />
          <Button
            type={'submit'}
            text="저장하기"
            className="filled-black w-full"
          />
          <Button
            type={'button'}
            text="취소하기"
            className="filled-gray-200 w-full text-gray-800"
            onClick={() => goBack()}
          />
        </div>
      </form>
    </div>
  );
};
