import {api} from "../plugins/axios";
import {CreateCurationDto, CurationDto, UpdateCurationDto} from "../types/curation";
import {Paged} from "../types/common";
import {CreateCurationProductDto} from "../types/arcnShop";
import {CreateMainBannerDto} from "../types/banner";

export const postCuration = (data: CreateCurationDto) => {
    return api.post('/curations', data);
}

export const getCurations = (query?: string, page?: number, itemsPerPage?: number) => {
    const params = {
        page: page || 1,
        itemsPerPage: itemsPerPage || 30,
    };
    return api.get<Paged<CurationDto>>(`/curations?${query}`, {params});
}

export const getCurationItem = (id: number) => {
    return api.get<CurationDto>(`/curations/${id}`);
}

export const updateCurationItem = (id: number, data: UpdateCurationDto) => {
    return api.patch<CurationDto>(`/curations/${id}`, data);
}

export const deleteCurationItem = (id: number) => {
    return api.delete<void>(`/curations/${id}`);
}

export const addCurationProduct = (id: number, data: CreateCurationProductDto) => {
    return api.post<CurationDto>(`/curations/${id}/products`, data);
}

export const addCurationBanner = (id: number, data: CreateMainBannerDto) => {
    return api.post<CurationDto>(`/curations/${id}/banners`, data);
}