import {api} from "../plugins/axios";
import {Paged} from "../types/common";
import {CreateEventTagDto} from "../types/eventTag";
import {EventTagDto} from "../types/product";

export const getEventTags = (query: string, page?: number, itemsPerPage?: number) => {
    const params = {
        page: page || 1,
        itemsPerPage: itemsPerPage || 30,
    };
    return api.get<Paged<EventTagDto>>('/event-tags?' + query, {params});
};

export const postEventTag = (data: CreateEventTagDto) => {
    return api.post<EventTagDto>('/event-tags', data);
}

export const getEventTag = (id: number) => {
    return api.get<EventTagDto>(`/event-tags/${id}`);
}

export const patchEventTag = (id: number, data: Partial<CreateEventTagDto>) => {
    return api.patch<EventTagDto>(`/event-tags/${id}`, data);
}

export const deleteEventTag = (id: number) => {
    return api.delete<void>(`/event-tags/${id}`);
}