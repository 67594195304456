import {api} from "../plugins/axios";
import { Paged } from "../types/common";
import {ReviewDto, UpdateReviewByAdminDto} from "../types/review";

export const getReviews = (keyword: string, page?: number, itemsPerPage?: number) => {
    const params = {
        keyword,
        page: page || 1,
        itemsPerPage: itemsPerPage || 30,
    };
    return api.get<Paged<ReviewDto>>(`/admin/reviews/search`, {params});
}

export const downloadReviewsExcel = (ids: number[]) => {
    window.open(`${process.env.REACT_APP_API_URL}/admin/reviews/excel?ids=${ids.join(',')}`);
}

export const patchReview = (id: number, data: UpdateReviewByAdminDto) => {
    return api.patch<ReviewDto>(`/admin/reviews/${id}`, data);
}