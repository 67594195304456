import {BannerType, CreateMainBannerDto, UpdateMainBannerDto} from "../../../types/banner";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {deleteBanner, getBanner, makeBanner, updateBanner} from "../../../api/bannerAPI";
import {AdminH1} from "../AdminH1";
import {TextField} from "../../../components/TextField";
import {Button} from "../../../components/Button";
import {ReactComponent as FileUploadImg} from "../../../assets/svg/file-upload.svg";
import {uploadFile} from "../../../api/fileAPI";
import {FileAbout} from "../../../types/common";
import {addCurationBanner} from "../../../api/curationAPI";
import {toast} from "react-toastify";
import {addPostingBanners} from "../../../api/postingAPI";

export interface BannerTemplateProps {
    type: 'ADD' | 'EDIT';
    targetId?: number;
    bannerType?: BannerType;
    curationId?: number;
    postingId?: number;
}

const BannerTemplate = (props: BannerTemplateProps) => {
    const {type, targetId, bannerType, curationId, postingId} = props;
    const [pcBanner, setPcBanner] = useState<File | null | undefined>();
    const [mobileBanner, setMobileBanner] = useState<File | null | undefined>();
    const {goBack} = useHistory();
    const form = useForm<CreateMainBannerDto | UpdateMainBannerDto>();

    const deleteThis = (id: number) => {
        const confirm = window.confirm("정말 삭제하시겠습니까?");
        if (confirm) {
            deleteBanner(id).then(() => {
                toast.success('삭제되었습니다.');
                goBack();
            }).catch(err => console.error(err.response));
        }
    }

    const makeThis = (data: CreateMainBannerDto) => {
        makeBanner(data).then(() => {
            toast.success('저장되었습니다.');
            goBack();
        }).catch(err => console.error(err.response));
    }

    const makeCurationBanner = (data: CreateMainBannerDto) => {
        addCurationBanner(curationId!, data).then(() => {
            toast.success('저장되었습니다.');
            goBack();
        }).catch(err => console.error(err.response));
    }

    const makePostingBanner = (data: CreateMainBannerDto) => {
        addPostingBanners(postingId!, data).then(() => {
            toast.success('저장되었습니다.');
            goBack();
        }).catch(err => console.error(err.response));
    }

    const changeThis = (data: UpdateMainBannerDto) => {
        updateBanner(targetId!, data).then(() => {
            toast.success('저장되었습니다.');
            goBack();
        }).catch(err => console.error(err.response));
    }

    const submit = async (data: CreateMainBannerDto) => {
        data.type = bannerType!;
        if (mobileBanner) {
            data.mobileBannerImageUrl = await uploadFile(FileAbout.BANNER_MOBILE, mobileBanner);
        }
        if (pcBanner) {
            data.pcBannerImageUrl = await uploadFile(FileAbout.BANNER_PC, pcBanner);
        }
        data.order = Number(data.order);
        if (type === 'ADD') {
            if (curationId) {
                makeCurationBanner(data);
            } else if (postingId) {
                makePostingBanner(data);
            } else {
                makeThis(data);
            }
        } else {
            changeThis(data);
        }
    }

    useEffect(() => {
        targetId && getBanner(targetId)
            .then((res) => {
                const {data} = res;
                form.setValue('name', data.name!);
                form.setValue('url', data.url!);
                form.setValue('order', data.order!);
                form.setValue('mobileBannerImageUrl', data.mobileBannerImageUrl!);
                form.setValue('pcBannerImageUrl', data.pcBannerImageUrl!);
                form.setValue('isShow', data.isShow! as never);
            })
            .catch((err) => console.error(err.response));
    }, [targetId])

    return (
        <>
            <AdminH1>배너 추가</AdminH1>

            <form onSubmit={form.handleSubmit(submit)}>
                <div className="max-w-md space-y-4">
                    <TextField label="노출 우선순위"
                               type={'number'}
                               {...form.register('order')}
                    />
                    <TextField label="배너명"
                               {...form.register('name')}
                    />
                    <TextField label="url"
                               {...form.register('url')}
                    />
                    <div>
                        <label htmlFor="pcBanner">
                            <div className="mb-2 text-sm text-gray-800 ">PC 배너</div>

                            {pcBanner || form.watch('pcBannerImageUrl') ? (
                                <>
                                    <img src={pcBanner ? URL.createObjectURL(pcBanner) : form.watch('pcBannerImageUrl')}
                                         alt=""/>
                                    <div className="mt-4 w-full">
                                        <Button type={'button'} className="filled-black h-12 py-3">
                                            <label htmlFor="pcBanner" className="w-full cursor-pointer">
                                                수정하기
                                            </label>
                                        </Button>
                                    </div>
                                </>
                            ) : !pcBanner ? (
                                <div
                                    className="border-grey-5 flex w-full flex-col items-center justify-center space-y-1 border-2 border-dashed py-10">
                                    <FileUploadImg/>
                                    <div className="w-3/5 text-center text-xs text-gray-500">
                                        상세 이미지 첨부하기
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}
                        </label>
                        <input type="file"
                               id="pcBanner"
                               className="hidden"
                               accept="image/*"
                               onChange={(e) => {
                                   e.target.validity.valid && setPcBanner(e.target.files?.item(0));
                               }}
                        />
                    </div>

                    <div>
                        <label htmlFor="mobileBanner">
                            <div className="mb-2 text-sm text-gray-800 ">Mobile 배너</div>

                            {mobileBanner || form.watch('mobileBannerImageUrl') ? (
                                <>
                                    <img
                                        src={mobileBanner ? URL.createObjectURL(mobileBanner) : form.watch('mobileBannerImageUrl')}
                                        alt=""/>
                                    <div className="mt-4 w-full">
                                        <Button type={'button'} className="filled-black h-12 py-3">
                                            <label
                                                htmlFor="mobileBanner"
                                                className="w-full cursor-pointer"
                                            >
                                                수정하기
                                            </label>
                                        </Button>
                                    </div>
                                </>
                            ) : !mobileBanner ? (
                                <div
                                    className="border-grey-5 flex w-full flex-col items-center justify-center space-y-1 border-2 border-dashed py-10">
                                    <FileUploadImg/>
                                    <div className="w-3/5 text-center text-xs text-gray-500">
                                        상세 이미지 첨부하기
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}
                        </label>
                        <input type="file"
                               id="mobileBanner"
                               className="hidden"
                               accept="image/*"
                               onChange={(e) => {
                                   e.target.validity.valid &&
                                   setMobileBanner(e.target.files?.item(0));
                               }}
                        />
                    </div>
                </div>
                {type === 'ADD' ? (
                    <div className="fixed bottom-0 -ml-8 w-full space-x-4 border-t bg-white py-2 pl-10">
                        <Button type={'button'}
                                text="취소"
                                className="outlined-black h-12 px-6 "
                                onClick={() => goBack()}
                        />
                        <Button type={'submit'} text="추가하기" className="filled-black h-12 px-6"/>
                    </div>
                ) : (
                    <div className="fixed bottom-0 -ml-8 w-full space-x-4 border-t bg-white py-2 pl-10">
                        <Button type={'button'}
                                text="삭제"
                                className="outlined-black h-12 px-6 "
                                onClick={() => deleteThis(targetId!)}
                        />
                        <Button type={'submit'} text="저장" className="filled-black h-12 px-6"/>
                    </div>
                )}

                <div className="p-14"/>
            </form>
        </>
    );
}

export default BannerTemplate