import React from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { Tab } from '../../components/Tab';
import { CancelAllOrderPage } from './cancel/CancelAllOrderPage';
import { CancelBooksOrderPage } from './cancel/CancelBooksOrderPage';
import { CancelProductsOrderPage } from './cancel/CancelProductsOrderPage';

export const CancelOrderMain = () => {
  const { pathname } = useLocation();
  const { push } = useHistory();
  return (
    <>
      <div className="flex space-x-4 border-b">
        <Tab
          text="전체"
          selected={pathname === '/admin/cancel/order/all'}
          onClick={() => push('/admin/cancel/order/all')}
        />
        <Tab
          text="도서"
          selected={pathname === '/admin/cancel/order/books'}
          onClick={() => push('/admin/cancel/order/books')}
        />
        <Tab
          text="일반상품"
          selected={pathname === '/admin/cancel/order/products'}
          onClick={() => push('/admin/cancel/order/products')}
        />
      </div>
      <Switch>
        <Route component={CancelAllOrderPage} path="/admin/cancel/order/all" />
        <Route
          component={CancelProductsOrderPage}
          path="/admin/cancel/order/products"
        />
        <Route
          component={CancelBooksOrderPage}
          path="/admin/cancel/order/books"
        />
        <Route path="/admin/cancel/order">
          <Redirect to="/admin/cancel/order/all" />
        </Route>
      </Switch>
    </>
  );
};
