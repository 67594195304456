import React from 'react';
import {AdminH1} from '../../components/AdminH1';
import RecommendedCategoryTemplate from "../../components/templates/RecommendedCategoryTemplate";

export const RecommendCategoryAdd = () => (
    <>
        <AdminH1>추천 카테고리 추가</AdminH1>
        <RecommendedCategoryTemplate type={'ADD'}/>
    </>
);
