import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Icon } from '../../../components/Icon';
import { Button } from '../../../components/Button';
import { TextField } from '../../../components/TextField';
import { CategoryDto } from '../../../types/categories';
import { toast } from 'react-toastify';
import { postCategories } from '../../../api/categoryAPI';
import { useMutation, useQueryClient } from 'react-query';
import { TabType } from '../../enum';
import { AxiosError } from 'axios';

type AddCategoryModalProps = {
  open: boolean;
  onClose: () => void;
  thisCategory: CategoryDto | null;
};

const AddCategoryModal = (props: AddCategoryModalProps) => {
  const queryClient = useQueryClient();
  const [newCategory, setNewCategory] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const addCategory = useMutation(postCategories, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['categories']);
      toast.success('카테고리가 추가되었습니다.');
      setIsSubmitting(false);
      setNewCategory('');
      props.onClose();
    },
    onError: (error: AxiosError) => {
      toast.error(
        error.response?.data.message || '카테고리 추가에 실패했습니다.'
      );
    },
  });
  const handleAddCategory = (e: React.MouseEvent) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);
    if (newCategory.trim() === '') {
      toast.error('카테고리명을 입력해주세요.');
      return;
    }
    addCategory.mutate({
      name: newCategory,
      categoryId: props.thisCategory?.id,
      type: TabType.LIFE,
    });
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewCategory(e.target.value);
  };

  return (
    <Transition appear show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto bg-gray-500 bg-opacity-75 transition-opacity"
        onClose={props.onClose}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="my-8 inline-block w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              <div className="mb-5 flex items-center justify-between">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {props.thisCategory?.id
                    ? '중분류 카테고리 생성'
                    : '대분류 카테고리 생성'}
                </Dialog.Title>
                <Icon.X onClick={props.onClose} className="cursor-pointer" />
              </div>
              <div>
                <TextField
                  label="카테고리명"
                  autoFocus={true}
                  value={newCategory}
                  onChange={handleChange}
                />
              </div>

              <div className="mt-4">
                <Button
                  disabled={isSubmitting}
                  onClick={handleAddCategory}
                  text="저장하기"
                  className="filled-black h-12 text-sm"
                />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AddCategoryModal;
