import { PostProductBModal } from '../../components/modal/PostProductBModal';
import { TextField } from '../../../components/TextField';
import { TextArea } from '../../../components/TextArea';
import { Button } from '../../../components/Button';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { CreatePostingDto, PostingType } from '../../../types/posting';
import { postPosting } from '../../../api/postingAPI';
import { toast } from 'react-toastify';
import { FileAbout } from '../../../types/common';
import { FloalaEditor } from '../../components/FloalaEditor';

export const DTypePostAdd = () => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const form = useForm<CreatePostingDto>();

  const onSubmit = (data: CreatePostingDto) => {
    data.type = PostingType.D;
    data.products = [];
    postPosting(data)
      .then((res) => {
        toast.success('저장되었습니다. 상품을 추가해 주세요.');
        history.replace(
          `/admin/post/${res.data.type.toLowerCase()}/${res.data.id}`
        );
      })
      .catch((err) => console.error(err.response));
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <PostProductBModal open={isOpen} onClose={() => setIsOpen(false)} />
      <div className="grid grid-cols-3 gap-5">
        <TextField
          label="타이틀"
          {...form.register('title', { required: true })}
        />
        {/*<TextField label="url" disabled/>*/}
        <div className="col-span-3 col-start-1">
          <TextArea
            label="상세설명"
            className="h-32"
            {...form.register('description', { required: true })}
          />
        </div>
        <div className="col-span-3 col-start-1">
          <div className="col-span-3 mb-2 text-sm text-gray-800">
            에디터 내용
          </div>
          <FloalaEditor
            imageType={FileAbout.POSTING_CONTENT}
            onChange={(content) => form.setValue('content', content)}
          />
        </div>
      </div>

      <div className="fixed bottom-0 -ml-8 w-full space-x-4 border-t bg-white py-2 pl-10">
        <Button
          type={'button'}
          text="취소"
          className="outlined-black h-12 px-6"
          onClick={() => history.goBack()}
        />
        <Button
          type={'submit'}
          text="저장"
          className="filled-black h-12 px-6"
        />
      </div>

      <div className="p-14" />
    </form>
  );
};
