import queryString from "query-string";
import {Select} from "../../../../components/Select";
import {Search} from "../../../../components/Search";
import {Order, ShippingLine, ShippingStatus} from "../../../../types/order";
import {Button} from "../../../../components/Button";
import {Card} from "../../../../components/Card";
import {OrderCategory} from "../../../components/OrderCategory";
import {Table} from "../../../../components/Table";
import {Checkbox} from "../../../../components/Checkbox";
import React, {useEffect, useState} from "react";
import {MomentFormat, utcToLocalFormat} from "../../../../plugins/moment";
import {orderStatusTxt, paymentTypeTxt, productTypeTxt} from "../../../../utils/textMatcher";
import {ShippingInfo} from "../../../components/OrderListShipping";
import {OrderListMemo} from "../../../components/OrderListMemo";
import {Pagination} from "../../../../components/Pagination";
import {useHistory} from "react-router-dom";
import {Paged} from "../../../../types/common";
import {useLoading} from "../../../../hooks/useLoading";
import {exelDownload, getOrders, patchShippingLines, sendOrdersToBooksen} from "../../../../api/ordersAPI";
import {toast} from "react-toastify";
import {BranchSelector} from "../../../../components/BranchSelector";
import {Icon} from "../../../../components/Icon";
import {getProductTypeText} from "../../../../utils/productTypeText";

const tabs = [
    {name: '결제완료', value: '0'},
    {name: '매장픽업 상품 준비중', value: '1'},
    {name: '매장픽업 상품 준비완료', value: '2'},
    {name: '매장픽업 완료', value: '3'},
    {name: '매장픽업 불가', value: '4'},
    {name: '주문 취소', value: '5'},
];

export const PickupOrderList = () => {
    const history = useHistory();
    const query = queryString.parse(history.location.search);
    const [orders, setOrders] = useState<Paged<Order>>({items: []});
    const [userDate, setUserDate] = React.useState<{ from: string, to: string }>({
        from: '2020-01-01',
        to: '2099-12-31'
    });
    const [selected, setSelected] = useState<number[]>([]);
    const {setIsLoading, LoadingView} = useLoading();

    const innerHeight = document.getElementById('orders') ? window.innerHeight - document.getElementById('orders')!.getBoundingClientRect().y : 0;

    const changeBulk = (shippingStatus: ShippingStatus) => {
        patchShippingLines(selected, {shippingStatus})
            .then(() => {
                toast.success('상태가 변경되었습니다.')
                setSelected([]);
                getLists();
            });
    }

    const updateMemo = (id: number, memo: string) => {
        patchShippingLines([id], {adminMemo: memo})
            .then(() => toast.success('메모가 저장되었습니다.'));
    }

    const updateShippingInfo = (id: number, shippingInfo: ShippingInfo) => {
        patchShippingLines([id], {courier: shippingInfo.courier, waybillCode: shippingInfo.waybillCode})
            .then(() => toast.success('배송정보가 저장되었습니다.'));
    }

    const sendOrders = async () => {
        const shippingLines: ShippingLine[] = [];
        orders.items.forEach(order => order.shippingLines.forEach(shippingLine => shippingLines.push(shippingLine)));

        if (selected.length === 0) {
            toast.info('전송하실 주문건을 선택해 주세요.');
        } else {
            let i = 0;
            for (const id of selected) {
                const orderCode = shippingLines.find(o => o.id === id)?.orderCode;
                const res = await sendOrdersToBooksen(orderCode!);
                if (res.status === 201) i += 1;
            }
            if (i === selected.length) {
                toast.success('전송되었습니다.');
                getLists();
            }
        }

    }

    const getLists = () => {
        setIsLoading(true);
        setOrders({items: []});
        let nQuery = ``;
        if (query.branch) nQuery += `&where[branchCode]=${query.branch}`;
        if (query.company) nQuery += `&where[companyId]=${query.company}`;
        if (query.find) nQuery += `&where[${query.searchType || 'code'}]=${query.find}`;
        if (query.from) nQuery += `&where[orderedAt][from]=${query.from}&where[orderedAt][to]=${query.to}`;
        switch (query.tab) {
            case '0':
                nQuery += `&where[shippingStatus]=${ShippingStatus.INIT}`;
                break;
            case '1':
                nQuery += `&where[shippingStatus]=${ShippingStatus.READY}`;
                break;
            case '2':
                nQuery += `&where[shippingStatus]=${ShippingStatus.IN_PROGRESS}`;
                break;
            case '3':
                nQuery += `&where[shippingStatus]=${ShippingStatus.FINISH}`;
                break;
            case '4':
                nQuery += `&where[shippingStatus]=${ShippingStatus.REJECT}`;
                break;
            case '5':
                nQuery += `&where[shippingStatus]=${ShippingStatus.CANCEL}`;
                break;
            default:
                nQuery += `&where[shippingStatus]=${ShippingStatus.INIT}`;
        }

        query.branch && getOrders(nQuery, Number(query.page), Number(query.limit))
            .then((res) => setOrders(res.data))
            .catch((e) => console.error(e.response))
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        getLists();
    }, [history.location.search])

    // checkbox control
    const allIds: number[] = [];
    orders.items.forEach(order => order.shippingLines.forEach(line => allIds.push(line.id)));

    const onDownload = () => {
        if (orders.items.length > 0) {
            selected.length > 0 ? exelDownload(selected) : exelDownload(allIds);
        } else {
            toast.info('다운로드 받을 주문건이 없습니다.');
        }
    };

    const onUpload = (file: File) => {

    };

    return (
        <div className="space-y-3">
            <LoadingView/>

            <BranchSelector selectedBranchCode={query.branch ? query.branch.toString() : ''}
                            onChange={(branch) => {
                                query.branch = branch ? branch.code : '';
                                history.push(`?${queryString.stringify(query)}`);
                            }}/>

            <div className="flex justify-end">
                <Select className={'h-12 text-sm mr-4'}
                        value={query.searchType?.toString() || 'code'}
                        onChange={(e) => {
                            query.searchType = e.target.value;
                            history.push(`?${queryString.stringify(query)}`);
                        }}
                >
                    <option value="code">주문번호</option>
                    <option value="productName">주문상품</option>
                    <option value="barcode">isbn</option>
                    <option value="receiverName">구매자명</option>
                    <option value="receiverPhone">구매자핸드폰번호</option>
                    <option value="userName">주문자명</option>
                    <option value="userPhone">주문자핸드폰번호</option>
                    <option value="adminMemo">관리자메모</option>
                </Select>
                <Search className="w-52"
                        defaultValue={query.find?.toString()}
                        onSearch={(keyword) => {
                            query.find = keyword;
                            history.push(`?${queryString.stringify(query)}`);
                        }}/>
            </div>
            <div className="flex justify-between">
                <Select className="h-12 text-sm"
                        value={""}
                        onChange={(e) => changeBulk(e.target.value as ShippingStatus)}>
                    <option hidden value="">상태 수동 변경</option>
                    <option value={ShippingStatus.INIT}>결제완료</option>
                    <option value={ShippingStatus.READY}>매장픽업 상품준비중</option>
                    <option value={ShippingStatus.IN_PROGRESS}>매장픽업 상품준비완료</option>
                    <option value={ShippingStatus.FINISH}>매장픽업 완료</option>
                    <option value={ShippingStatus.REJECT}>매장픽업 불가</option>
                    <option value={ShippingStatus.CANCEL}>주문취소</option>
                </Select>

                <div className="flex space-x-3">
                    <Button className="outlined-black flex h-12 items-center space-x-2 text-sm"
                            onClick={onDownload}
                    >
                        <p>엑셀 내보내기</p>
                        <Icon.Download className=" rotate-180 transform"/>
                    </Button>
                    <div className="textfield flex h-12 items-center bg-[#F2F4F6] text-sm">
                        <input
                            type="date"
                            className="focus-border-0 border-0 bg-[#F2F4F6] focus:border-none focus:ring-0"
                            defaultValue={query.from?.slice(0, 10).toString() || undefined}
                            onChange={e => setUserDate({
                                ...userDate,
                                from: e.target.value ? e.target.value + ' 00:00:00' : ''
                            })}
                        />
                        <p className="flex-shrink-0">부터</p>
                        <input
                            type="date"
                            className="focus-border-0 border-0 bg-[#F2F4F6] focus:border-none focus:ring-0"
                            defaultValue={query.to?.slice(0, 10).toString() || undefined}
                            onChange={e => setUserDate({
                                ...userDate,
                                to: e.target.value ? e.target.value + ' 23:59:59' : ''
                            })}
                        />
                    </div>
                    <Button
                        text="검색"
                        className="filled-black h-12 flex-shrink-0 text-sm"
                        onClick={() => {
                            query.from = userDate.from;
                            query.to = userDate.to;
                            history.push(`?${queryString.stringify(query)}`);
                        }}
                    />
                </div>
            </div>

            <Card id={'orders'} style={{height: innerHeight, overflow: 'scroll'}}>
                <div className="flex w-full border-b border-gray-100 justify-between">
                    <div className={'flex'}>
                        {tabs.map((item, index) => (
                            <OrderCategory key={index}
                                           text={`${item.name}${((!query.tab && item.value === '0') || query.tab === item.value) ? `(${orders.pagination?.totalItemCount || '0'})` : ``}`}
                                           active={query.tab === item.value || (!query.tab && index === 0)}
                                           onClick={() => {
                                               query.tab = item.value;
                                               history.push(`?${queryString.stringify(query)}`);
                                           }}
                            />
                        ))}
                    </div>
                </div>

                <Table>
                    <Table.Head>
                        <Table.Row>
                            <Table.Th>
                                <Checkbox className="bg-white"
                                          checked={allIds.length === selected.length}
                                          onChange={() => {
                                              if (allIds.length === selected.length) {
                                                  setSelected([]);
                                              } else {
                                                  setSelected(allIds);
                                              }
                                          }}/>
                            </Table.Th>
                            <Table.Th>주문번호/주문일자</Table.Th>
                            <Table.Th>주문상품 /ISBN</Table.Th>
                            <Table.Th>수량</Table.Th>
                            <Table.Th>상태</Table.Th>
                            <Table.Th>상품타입</Table.Th>
                            <Table.Th>업체명</Table.Th>
                            <Table.Th>상품금액</Table.Th>
                            <Table.Th>픽업정보</Table.Th>
                            <Table.Th>결제정보</Table.Th>
                            <Table.Th>관리자메모</Table.Th>
                        </Table.Row>
                    </Table.Head>
                    <Table.Body>
                        {orders.items.length === 0 && (
                            <Table.Row>
                                <Table.Td>데이터가 없습니다.</Table.Td>
                            </Table.Row>
                        )}
                        {orders.items.map((order, index) => (
                            <React.Fragment key={index}>
                                {order.shippingLines.map((shippingLine, index) => (
                                    <Table.Row key={index}>
                                        <Table.Td>
                                            <Checkbox checked={!!selected.find(id => id === shippingLine.id)}
                                                      onChange={() => {
                                                          if (selected.find(id => id === shippingLine.id)) {
                                                              setSelected(selected.filter(id => id !== shippingLine.id));
                                                          } else {
                                                              setSelected([...selected, shippingLine.id]);
                                                          }
                                                      }}/>
                                        </Table.Td>
                                        <Table.Td>
                                            <p className="text-black">{order.code}</p>
                                            <p>
                                                {utcToLocalFormat(
                                                    order.createdAt.toLocaleString(),
                                                    MomentFormat.YYYYMMDDHmm
                                                )}
                                            </p>
                                        </Table.Td>
                                        <Table.Td className="space-y-3">
                                            {shippingLine.items.map((product, index) => (
                                                <div className="h-10" key={index}>
                                                    <p className="text-black">{product.productName}</p>
                                                    <p>{product.barcode}</p>
                                                </div>
                                            ))}
                                        </Table.Td>
                                        <Table.Td className="space-y-3">
                                            {shippingLine.items.map((product, index) => (
                                                <div key={index} className="h-10">{product.quantity}</div>
                                            ))}
                                        </Table.Td>
                                        <Table.Td className="space-y-3">
                                            {shippingLine.items.map((product, index) => (
                                                <div key={index}
                                                     className="h-10">{orderStatusTxt(shippingLine.shippingStatus)}</div>
                                            ))}
                                        </Table.Td>
                                        <Table.Td className="space-y-3">
                                            {shippingLine.items.map((product, index) => (
                                                <div key={index}
                                                     className="h-10">{productTypeTxt(product.productType)}</div>
                                            ))}
                                        </Table.Td>
                                        <Table.Td className="space-y-3">
                                            {shippingLine.items.map((product, index) => (
                                                <div key={index} className="h-10">{shippingLine.company.name}</div>
                                            ))}
                                        </Table.Td>
                                        <Table.Td className="space-y-3">
                                            {shippingLine.items.map((product, index) => (
                                                <div className="flex h-10 space-x-2" key={index}>
                                                    {/*<div className="text-black">상품금액</div>*/}
                                                    <div className="">
                                                        {product.unitPrice
                                                            .toString()
                                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        원
                                                    </div>
                                                </div>
                                            ))}
                                        </Table.Td>
                                        <Table.Td className="space-y-0.5">
                                            {[
                                                {label: '지점명', content: query.branch},
                                                {label: '구매자명', content: shippingLine.receiverName},
                                                {
                                                    label: '구매자 핸드폰',
                                                    content: shippingLine.receiverTel
                                                        .toString()
                                                        .replace(
                                                            /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
                                                            '$1-$2-$3'
                                                        ),
                                                },
                                                //TODO
                                                {
                                                    label: '픽업완료시간',
                                                    content: shippingLine.finishedAt ? utcToLocalFormat(
                                                        shippingLine.finishedAt.toLocaleString(),
                                                        MomentFormat.YYYYMMDDHmm
                                                    ) : '-'
                                                },
                                            ].map((shipping, index) => (
                                                <div className="flex space-x-3 justify-between" key={index}>
                                                    <p className="text-black">{shipping.label}</p>
                                                    <p>{shipping.content}</p>
                                                </div>
                                            ))}
                                            <div></div>
                                        </Table.Td>
                                        <Table.Td className="space-y-0.5">
                                            <div className="flex space-x-3 pb-3 font-semibold text-black">
                                                <p>결제번호</p>
                                                <p>{order.payment.receiptId}</p>
                                            </div>
                                            {[
                                                {
                                                    label: '결제일시',
                                                    content: utcToLocalFormat(
                                                        order.payment.createdAt.toLocaleString(),
                                                        MomentFormat.YYYYMMDDHmm
                                                    ),
                                                },
                                                {
                                                    label: '결제방법',
                                                    content: paymentTypeTxt(order.payment.method),
                                                },
                                                {
                                                    label: '주문자명',
                                                    content: <a href={`/admin/users/${order.user.id}`}
                                                                className="text-blue-600">{order.user.name}</a>,
                                                },
                                                {
                                                    label: '총주문금액',
                                                    content: order.payment.totalProductPrice
                                                        .toString()
                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원',
                                                },
                                                {
                                                    label: '총배송비',
                                                    content:
                                                        order.payment.shippingPrice
                                                            .toString()
                                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원',
                                                },
                                                {
                                                    label: '쿠폰할인',
                                                    content:
                                                        (!!order.paidCoupons && order.paidCoupons.length > 0) ? order.payment.totalDiscountAmount : 0
                                                            .toString()
                                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원',
                                                },
                                                {
                                                    label: '사용쿠폰',
                                                    content: (!!order.paidCoupons && order.paidCoupons.length > 0) ? order.paidCoupons[0].title : '',
                                                },
                                                {
                                                    label: '적용상품',
                                                    content: (!!order.paidCoupons && order.paidCoupons.length > 0) ? getProductTypeText(order.paidCoupons[0].allowedProductTypes) : '',
                                                },
                                                {
                                                    label: '적립금할인',
                                                    content:
                                                        order.payment.usedPointAmount
                                                            .toString()
                                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원',
                                                },
                                                {
                                                    label: '총결제금액',
                                                    content:
                                                        order.payment.totalPayAmount
                                                            .toString()
                                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원',
                                                },
                                                {
                                                    label: '구매시 적립금',
                                                    content:
                                                        order.payment.receivablePointAmount
                                                            .toString()
                                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원',
                                                },
                                            ].map((payment, index) => (
                                                <div className="flex space-x-3 justify-between" key={index}>
                                                    <p className="text-black">{payment.label}</p>
                                                    <p>{payment.content}</p>
                                                </div>
                                            ))}
                                            <div></div>
                                        </Table.Td>
                                        <Table.Td className="space-y-1">
                                            <OrderListMemo shippingLine={shippingLine} onSaveMemo={updateMemo}/>
                                        </Table.Td>
                                    </Table.Row>
                                ))}
                            </React.Fragment>
                        ))}
                    </Table.Body>
                </Table>

                <Pagination>
                    {/* 전체 숫자를 주문 갯수로 조회하므로 카운트가 안맞을 수 있음.. ㅠㅠ */}
                    <Pagination.Label count={allIds.length} total={orders.pagination?.totalItemCount!}/>
                    <Pagination.Nav basePath={history.location.pathname} total={orders.pagination?.totalItemCount!}/>
                </Pagination>
            </Card>
        </div>
    )
}
