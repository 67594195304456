import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Icon } from '../../../components/Icon';
import { Button } from '../../../components/Button';
import { CategoryDto } from '../../../types/categories';
import { useMutation, useQueryClient } from 'react-query';
import { deleteCategory } from '../../../api/categoryAPI';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

interface DeleteCategoryModalProps {
  open: boolean;
  onClose: () => void;
  thisCategory: CategoryDto | null;
}

/**
 * TODO: 삭제 완료 시
 *  - 중분류 삭제 완료 시 > 대분류로 이동
 *  - 대분류 삭제 완료 시 > 기본 카테고리로 이동
 *    ISSUE> 대분류 삭제 완료 시 링크 이동 후 invalidateQueries 실행 필요
 */
const DeleteCategoryModal = (props: DeleteCategoryModalProps) => {
  const history = useHistory();
  const query = queryString.parse(history.location.search);
  const location = useLocation();
  const queryClient = useQueryClient();
  const deleteMutation = useMutation(deleteCategory, {
    onSuccess: async () => {
      toast.success('카테고리가 삭제되었습니다.');
      if (!!query.sub) {
        const queryParams = new URLSearchParams(location.search);
        queryParams.delete('sub');
        history.replace({
          search: queryParams.toString(),
        });
      } else {
        history.push(`?tab=LIFE`);
      }
      setTimeout(() => {
        queryClient.invalidateQueries(['categories']);
      }, 500);

      props.onClose();
    },
  });
  const handleDelete = (e: React.MouseEvent) => {
    e.preventDefault();
    if (props.thisCategory === null) return;
    deleteMutation.mutate(props.thisCategory?.id);
  };
  return (
    <Transition appear show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto bg-gray-500 bg-opacity-75 transition-opacity"
        onClose={props.onClose}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="my-8 inline-block w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              <div className="mb-5 flex items-center justify-between">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  카테고리 삭제
                </Dialog.Title>
                <Icon.X onClick={props.onClose} className="cursor-pointer" />
              </div>
              <div>
                <p className=" text-gray-500">
                  <span className={'font-bold text-gray-700'}>
                    {props.thisCategory?.name}{' '}
                  </span>
                  카테고리를 삭제하시겠습니까?
                </p>
              </div>

              <div className="mt-4">
                <Button
                  disabled={false}
                  onClick={handleDelete}
                  text="삭제"
                  className="filled-black h-12 text-sm"
                />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default DeleteCategoryModal;
