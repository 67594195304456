import React from 'react';
import {useHistory} from 'react-router-dom';
import {Button} from '../../../components/Button';
import {TextArea} from '../../../components/TextArea';
import {TextField} from '../../../components/TextField';
import {AdminH1} from '../../components/AdminH1';
import {useForm} from "react-hook-form";
import {CreateUserManageDto} from "../../../types/user";
import {postUser} from "../../../api/userAPI";
import {toast} from "react-toastify";
import PostcodeModal from "../../components/PostcodeModal";
import {phoneNumber} from '../../../utils/phoneNumber';
import {Checkbox} from '../../../components/Checkbox';

export const UserAdd = () => {
    const history = useHistory();
    const form = useForm<CreateUserManageDto>();
    const [postcodeModal, setPostcodeModal] = React.useState(false);

    const onSubmit = (data: CreateUserManageDto) => {
        postUser(data).then(() => {
            toast.success('회원이 추가되었습니다.');
            history.push('/admin/users');
        });
    }

    return (
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <AdminH1>회원 추가</AdminH1>

            <div className="grid grid-cols-3 gap-5 my-5">
                <TextField label="고객명"
                           {...form.register('name', {required: '고객명을 입력하세요', setValueAs: value => value.trim()})}
                           helper={form.formState.errors.name?.message}
                />
                <TextField label="핸드폰번호"
                           value={form.watch('phone')}
                           {...form.register('phone', {
                               required: '핸드폰번호를 입력하세요',
                               setValueAs: value => phoneNumber(value)
                           })}
                           helper={form.formState.errors.phone?.message}
                />
                {/*<TextField label="성인인증여부" disabled/>*/}
            </div>
            <div className="grid grid-cols-3 gap-5">
                <TextField label="아이디"
                           {...form.register('email', {required: '아이디를 입력하세요'})}
                           helper={form.formState.errors.email?.message}
                />
                <TextField label="비밀번호"
                           {...form.register('password', {required: '비밀번호를 입력하세요'})}
                           helper={form.formState.errors.password?.message}
                />
            </div>

            <div className="space-y-5 py-10">
                <h3 className="text-lg font-semibold">기본배송지</h3>
                <TextField label="주소" value={form.watch('address')} onClick={() => setPostcodeModal(true)}/>
                <TextField label="상세주소" {...form.register('addressDetail')}/>
                <TextArea label="관리자 메모" className="h-24" {...form.register('memo')}/>
                <Checkbox label="마케팅 수신여부" checked={form.watch('isMarketingUsageAgreed')}
                          onChange={() => form.setValue('isMarketingUsageAgreed', !form.watch('isMarketingUsageAgreed'))}/>
            </div>

            <div className="fixed bottom-0 -ml-8 w-full space-x-4 border-t bg-white py-2 pl-10">
                <Button text="취소" className="outlined-black h-12 px-6" onClick={() => history.goBack()}/>
                <Button type={'submit'} text="추가하기" className="filled-black h-12 px-6"/>
            </div>
            <div className="pt-14"/>
            <PostcodeModal open={postcodeModal}
                           onComplete={(data) => {
                               form.setValue('zipcode', data.zonecode);
                               form.setValue('address', data.address);
                               setPostcodeModal(false);
                           }}
                           onClose={() => setPostcodeModal(false)}/>
        </form>
    );
};
