import React from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { Tab } from '../../components/Tab';
import { AllOrderPage } from './normal/AllOrderPage';
import { BooksOrderPage } from './normal/BooksOrderPage';
import { ProductsOrderPage } from './normal/ProductsOrderPage';

export const OrderMain = () => {
  const { pathname } = useLocation();
  const { push } = useHistory();
  return (
    <>
      <div className="flex space-x-4 border-b">
        <Tab
          text="전체"
          selected={pathname === '/admin/order/all'}
          onClick={() => push('/admin/order/all')}
        />
        <Tab
          text="도서"
          selected={pathname === '/admin/order/books'}
          onClick={() => push('/admin/order/books')}
        />
        <Tab
          text="일반상품"
          selected={pathname === '/admin/order/products'}
          onClick={() => push('/admin/order/products')}
        />
      </div>
      <Switch>
        <Route component={AllOrderPage} path="/admin/order/all" />
        <Route component={ProductsOrderPage} path="/admin/order/products" />
        <Route component={BooksOrderPage} path="/admin/order/books" />
        <Route path="/admin/order">
          <Redirect to="/admin/order/all" />
        </Route>
      </Switch>
    </>
  );
};
