import { useMutation, useQueryClient } from 'react-query';
import {
  copyGeneralProduct,
  patchBookProduct,
  patchGeneralProduct,
} from '../api/productAPI';
import {
  CreateBookDto,
  CreateGeneralDto,
  ProductDto,
  ProductType,
} from '../types/product';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { getProductQueryString } from '../utils/getProductQueryString';
import { Paged } from '../types/common';
import { toast } from 'react-toastify';

interface UpdateProductProps {
  type: ProductType;
  onSuccess?: () => void;
  onError?: () => void;
}

const useUpdateBookProduct = ({
  type,
  onSuccess,
  onError,
}: UpdateProductProps) => {
  const history = useHistory();
  const query = queryString.parse(history.location.search);
  const queryClient = useQueryClient();
  const { mutate } = useMutation(patchBookProduct, {
    onMutate: async (variables) => {
      await queryClient.cancelQueries([
        'products',
        getProductQueryString(query, type),
      ]);
      const previousData: Paged<ProductDto> | null =
        queryClient.getQueryData([
          'products',
          getProductQueryString(query, type),
        ]) || null;

      if (previousData) {
        queryClient.setQueryData(
          ['products', getProductQueryString(query, type)],
          () => ({
            items: previousData.items.map((item) =>
              item.id === variables.id ? { ...item, ...variables.data } : item
            ),
            pagination: previousData.pagination,
          })
        );
      }

      return { previousData };
    },
  });
  return (id: number, data: Partial<CreateBookDto>) => {
    mutate(
      { id, data },
      {
        onSuccess: async ({ data }, _, context) => {
          const previousData = context?.previousData;

          if (previousData) {
            queryClient.setQueryData(
              ['products', getProductQueryString(query, type)],
              () => ({
                items: previousData.items.map((item) =>
                  item.id === id ? { ...item, ...data } : item
                ),
                pagination: previousData.pagination,
              })
            );
          }
          onSuccess && onSuccess();
        },
        onError: (error, variables, context) => {
          const hasContextAndPreviousData = context && context.previousData;
          if (hasContextAndPreviousData && context) {
            queryClient.setQueryData(
              ['products', getProductQueryString(query, type)],
              context.previousData
            );
          }
          onError && onError();
        },
        onSettled: async () => {
          await queryClient.invalidateQueries([
            'products',
            getProductQueryString(query, type),
          ]);
        },
      }
    );
  };
};

const useUpdateGeneralProduct = ({
  type,
  onSuccess,
  onError,
}: UpdateProductProps) => {
  const history = useHistory();
  const query = queryString.parse(history.location.search);
  const queryClient = useQueryClient();
  const { mutate } = useMutation(patchGeneralProduct, {
    onMutate: async (variables) => {
      await queryClient.cancelQueries([
        'products',
        getProductQueryString(query, type),
      ]);
      const previousData: Paged<ProductDto> | null =
        queryClient.getQueryData([
          'products',
          getProductQueryString(query, type),
        ]) || null;

      if (previousData) {
        queryClient.setQueryData(
          ['products', getProductQueryString(query, type)],
          () => ({
            items: previousData.items.map((item) =>
              item.id === variables.id ? { ...item, ...variables.data } : item
            ),
            pagination: previousData.pagination,
          })
        );
      }

      return { previousData };
    },
  });
  return (id: number, data: Partial<CreateGeneralDto>) => {
    mutate(
      { id, data },
      {
        onSuccess: async ({ data }, _, context) => {
          const previousData = context?.previousData;

          if (previousData) {
            queryClient.setQueryData(
              ['products', getProductQueryString(query, type)],
              () => ({
                items: previousData.items.map((item) =>
                  item.id === id ? { ...item, ...data } : item
                ),
                pagination: previousData.pagination,
              })
            );
          }
          onSuccess && onSuccess();
        },
        onError: (error, variables, context) => {
          const hasContextAndPreviousData = context && context.previousData;
          if (hasContextAndPreviousData && context) {
            queryClient.setQueryData(
              ['products', getProductQueryString(query, type)],
              context.previousData
            );
          }
          onError && onError();
        },
        onSettled: async () => {
          await queryClient.invalidateQueries([
            'products',
            getProductQueryString(query, type),
          ]);
        },
      }
    );
  };
};

const useCopyGeneralProduct = () => {
  const history = useHistory();
  const query = queryString.parse(history.location.search);
  const queryClient = useQueryClient();

  const { mutate } = useMutation(copyGeneralProduct);
  return (body: { id: number; count: number }) => {
    mutate(body, {
      onSuccess: () => {
        toast('성공적으로 복제되었습니다.', {
          type: 'success',
        });
        queryClient.invalidateQueries([
          'products',
          getProductQueryString(query),
        ]);
      },
    });
  };
};

export { useUpdateBookProduct, useUpdateGeneralProduct, useCopyGeneralProduct };
