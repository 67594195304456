import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Icon } from '../../../components/Icon';
import { TextField } from '../../../components/TextField';
import { ProductSearch } from '../templates/ProductSearch';
import { TextArea } from '../../../components/TextArea';
import { Button } from '../../../components/Button';
import { putBooksenUpdateISBN } from '../../../api/productAPI';
import { toast } from 'react-toastify';
import { ProductDto } from '../../../types/product';

interface UpsertBookByIsbnModalProps {
  open: boolean;
  onClose: () => void;
}

export const UpsertBookByIsbnModal: React.FC<UpsertBookByIsbnModalProps> = (
  props
) => {
  const { open, onClose } = props;
  const [isbnInputText, setISBNInputText] = useState<string>('');
  const [resultItems, setResultItems] = useState<ProductDto[]>([]);

  const isbnList = isbnInputText
    .replace(/\s/g, '')
    .split(',')
    .filter((e) => e);

  const resetModal = () => {
    setISBNInputText('');
    setResultItems([]);
  };

  const handleUpdate = () => {
    console.log(isbnList);
    putBooksenUpdateISBN(isbnList)
      .then(({ data }) => {
        toast.success('업데이트 완료');
        setResultItems(data.books);
      })
      .catch((err) => console.error(err.response));
  };

  if (!open) return <></>;
  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto bg-gray-500 bg-opacity-75 transition-opacity"
          onClose={() => {
            resetModal();
            onClose();
          }}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="my-8 inline-block w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex items-center justify-between">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    ISBN으로 수동 패치하기
                  </Dialog.Title>
                  <Icon.X onClick={onClose} className="cursor-pointer" />
                </div>

                <div className="my-6 space-y-4">
                  <TextField
                    label="업데이트하실 상품의 ISBN을 입력하세요."
                    helper="여러개일 시 ,로 구분"
                    className="h-12"
                    type="text"
                    value={isbnInputText}
                    onChange={(e) => setISBNInputText(e.target.value)}
                  />
                </div>

                <div className="my-6">
                  {resultItems.length > 0 ? (
                    <p>업데이트 결과 ({resultItems.length})</p>
                  ) : (
                    ''
                  )}
                  <div>
                    {resultItems.map((book) => {
                      const isNew = book.createdAt === book.updatedAt;
                      const href = `/admin/books/product/${book.id}`;
                      return (
                        <div className="my-3 flex items-center border px-3 py-1.5">
                          <div className="flex-none">
                            <img
                              src={book.images[0]}
                              alt={book.name}
                              width={36}
                            />
                          </div>
                          <div className="mx-2 grow">
                            <a
                              className="text-sm text-blue-600 visited:text-purple-600 hover:underline"
                              href={href}
                              style={{ lineHeight: 1.1 }}
                            >
                              {book.name}
                            </a>
                            <p className="text-gray-700">{book.barcode}</p>
                          </div>
                          <div className="flex-none">
                            {isNew ? '추가됨' : '수정됨'}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="mt-4 space-y-2">
                  <Button
                    disabled={isbnList.length === 0}
                    onClick={handleUpdate}
                    text="실행"
                    className="filled-black h-12 w-full text-sm"
                  />
                  <Button
                    text="완료"
                    className="filled-gray-200 h-12 w-full text-sm text-gray-800"
                    onClick={() => {
                      resetModal();
                      onClose();
                    }}
                  />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
