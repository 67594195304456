export const couriers = [
    {code: 'CJGLS', name: 'CJ대한통운'},
    {code: 'HYUNDAI', name: '롯데택배'},
    {code: 'HANJIN', name: '한진택배'},
    {code: 'EPOST', name: '우체국'},
    {code: 'KGB', name: '로젠택배'},
    // 드림택배
    // 하나로택배
    {code: 'HANDEX', name: '한덱스'},
    {code: 'ILYANG', name: '일양택배'},
    // 이노지스
    {code: 'TNT', name: 'TNT'},
    {code: 'KDEXP', name: '경동택배'},
    {code: 'CHUNIL', name: '천일특송'},
    {code: 'DAESIN', name: '대신택배'},
    {code: 'HDEXP', name: '합동택배'},
    {code: 'HONAM', name: '호남택배'},
    {code: 'KUNYOUNG', name: '건영택배'},
    {code: 'DIRECT', name: '업체직송'},
    {code: 'DHL', name: 'DHL'},

    // 업체 추가로 필요할 경우 살리기
    // {code: 'AJOU', name: '아주택배'},
    // {code: 'CSLOGIS', name: 'SC로지스'},
    // {code: 'CVS', name: 'CVS택배'},
    // {code: 'HDEXP', name: '합동택배'},
    // {code: 'UPS', name: 'UPS'},
    // {code: 'FEDEX', name: 'FEDEX'},
    // {code: 'REGISTPOST', name: '우편등기'},
    // {code: 'EMS', name: '우체국 EMS'},
    // {code: 'USPS', name: 'USPS'},
    // {code: 'IPARCEL', name: 'i-parcel'},
    // {code: 'GSMNTON', name: 'GSM NtoN'},
    // {code: 'SWGEXP', name: '성원글로벌'},
    // {code: 'PANTOS', name: '범한판토스'},
    // {code: 'ACIEXPRESS', name: 'ACI Express'},
    // {code: 'DAEWOON', name: '대운글로벌'},
    // {code: 'AIRBOY', name: '에어보이익스프레스'},
    // {code: 'KGLNET', name: 'KGL네트웍스'},
    // {code: 'SLX', name: 'SLX택배'},
    // {code: 'LINEEXPRESS', name: 'LineExpress'},
    // {code: 'TWOFASTEXP', name: '2FastsExpress'},
    // {code: 'HPL', name: '한의사랑택배'},
    // {code: 'GOODSTOLUCK', name: '굿투럭'},
    // {code: 'KOREXG', name: 'CJ대한통운특'},
    // {code: 'BGF', name: 'BGF'},
    // {code: 'ECMS', name: 'ECMS익스프레스'},
    // {code: 'WONDERS', name: '원더스퀵'},
    // {code: 'YONGMA', name: '용마로지스'},
    // {code: 'SEBANG', name: '세방택배'},
    // {code: 'NHLOGIS', name: '농협택배'},
    // {code: 'LOTTEGLOBAL', name: '롯데글로벌'},
    // {code: 'GSIEXPRESS', name: 'GSI익스프레스'},
    // {code: 'EFS', name: 'EFS'},
    // {code: 'DHLGLOBALMAIL', name: 'DHL GlobalMail'},
    // {code: 'GPSLOGIX', name: 'GPS로직'},
    // {code: 'CRLX', name: '시알로지텍'},
    // {code: 'BRIDGE', name: '브리지로지스'},
    // {code: 'HOMEINNOV', name: '홈이노베이션로지스'},
    // {code: 'CWAY', name: '씨웨이'},
    // {code: 'GNETWORK', name: '자이언트'},
    // {code: 'ACEEXP', name: 'ACE Express'},
    // {code: 'WEVILL', name: '우리동네택배'},
    // {code: 'FOREVERPS', name: '퍼레버택배'},
    // {code: 'WARPEX', name: '워펙스'},
    // {code: 'QXPRESS', name: '큐익스프레스'},
    // {code: 'SMARTLOGIS', name: '스마트로지스'},
    // {code: 'HOMEPICK', name: '홈픽택배'},
    // {code: 'GTSLOGIS', name: 'GTS로지스'},
    // {code: 'ESTHER', name: '에스더쉬핑'},
    // {code: 'INTRAS', name: '로토스'},
    // {code: 'EUNHA', name: '은하쉬핑'},
    // {code: 'UFREIGHT', name: '유프레이트 코리아'},
    // {code: 'LSERVICE', name: '엘서비스'},
    // {code: 'TPMLOGIS', name: '로지스밸리'},
    // {code: 'ZENIELSYSTEM', name: '제니엘시스템'},
    // {code: 'ANYTRACK', name: '애니트랙'},
    // {code: 'JLOGIST', name: '제이로지스트'},
    // {code: 'CHAINLOGIS', name: '두발히어로(4시간당일택배)'},
    // {code: 'QRUN', name: '큐런'},
    // {code: 'FRESHSOLUTIONS', name: '프레시솔루션'},
    // {code: 'HIVECITY', name: '하이브시티'},
    // {code: 'HANSSEM', name: '한샘'},
    // {code: 'SFC', name: 'SFC(Santai)'},
    // {code: 'JNET', name: 'J-NET'},
    // {code: 'GENIEGO', name: '지니고'},
    // {code: 'PANASIA', name: '판아시아'},
    // {code: 'ELIAN', name: 'elianpost'},
    // {code: 'LOTTECHILSUNG', name: '롯데칠성'},
    // {code: 'SBGLS', name: 'SBGLS'},
    // {code: 'ALLTAKOREA', name: '올타코리아'},
    // {code: 'YUNDA', name: 'yunda express'},
    // {code: 'VALEX', name: '발렉스'},
    // {code: 'KOKUSAI', name: '국제익스프레스'},
    // {code: 'XINPATEK', name: '윈핸드해운항공'},
    // {code: 'HEREWEGO', name: '탱고앤고'},
    // {code: 'WOONGJI', name: '웅지익스프레스'},
    // {code: 'PINGPONG', name: '핑퐁'},
    // {code: 'YDH', name: 'YDH'},
    // {code: 'CARGOPLEASE', name: '화물부탁해'},
    // {code: 'LOGISPOT', name: '로지스팟'},
    // {code: 'FRESHMATES', name: '프레시메이트'},
    // {code: 'VROONG', name: '부릉'},
    // {code: 'NKLS', name: 'NK로지솔루션'},
    // {code: 'DODOFLEX', name: '도도플렉스'},
    // {code: 'ETOMARS', name: '이투마스'},
    // {code: 'SHIPNERGY', name: '배송하기좋은날'},
    // {code: 'VENDORPIA', name: '에이스물류'},
    // {code: 'COSHIP', name: '캐나다쉬핑'},
    // {code: 'GDAKOREA', name: '지디에이코리아'},
    // {code: 'BABABA', name: '바바바로지스'},
    // {code: 'TEAMFRESH', name: '팀프레시'},
]