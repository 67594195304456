export interface Paged<T> {
  items: T[];
  pagination?: {
    currentItemCount: number;
    currentPage: number;
    itemsPerPage: number;
    totalItemCount: number;
    totalPage: number;
  };
}

export interface PageQuery {
  page: number;
  itemsPerPage: number;
}

export enum FileAbout {
  BANNER_PC = 'banner/pc',
  BANNER_MOBILE = 'banner/mobile',
  PRODUCT_BOOK = 'product/book',
  PRODUCT_GENERAL = 'product/general',
  RECOMMENDED_CATEGORY = 'category/recommended',
  POSTING_CONTENT = 'posting/content',
  PARTNERSHIP_PC = 'partnership/pc',
  PARTNERSHIP_MOBILE = 'partnership/mobile',
  COMPANY_PC = 'company/pc',
  COMPANY_MOBILE = 'company/mobile',
}
