import { api } from '../plugins/axios';
import {
  CreateBookDto,
  CreateGeneralDto,
  CreateProductDto,
  DetailDtoType,
  EventTagDto,
  ProductDto,
} from '../types/product';
import { Paged, PageQuery } from '../types/common';
import moment from 'moment';

export const getProducts = (
  query: any,
  pageParams?: PageQuery
) => {
  return api.get<Paged<ProductDto>>(`/products?${query}`, { params: pageParams });
};

export const getProduct = (id: number) => {
  return api.get<ProductDto>(`/products/${id}`);
};

export const postProduct = (data: CreateProductDto) => {
  return api.post<ProductDto>(`/products`, data);
};

export const patchProduct = (id: number, data: Partial<CreateProductDto>) => {
  return api.patch<ProductDto>(`/products/${id}`, data);
};

export const deleteProduct = (id: number) => {
  return api.delete<ProductDto>(`/products/${id}`);
};

export const postBookProduct = (data: CreateBookDto) => {
  return api.post<ProductDto>(`/products/book`, data);
};

export const patchBookProduct = ({
  id,
  data,
}: {
  id: number;
  data: Partial<CreateBookDto>;
}) => {
  return api.patch<ProductDto>(`/products/book/${id}`, data);
};

export const deleteBookProduct = (id: number) => {
  return api.delete<void>(`/products/book/${id}`);
};

export const postGeneralProduct = (data: CreateGeneralDto) => {
  return api.post<ProductDto>(`/products/general`, data);
};

export const patchGeneralProduct = ({
  id,
  data,
}: {
  id: number;
  data: Partial<CreateGeneralDto>;
}) => {
  return api.patch<ProductDto>(`/products/general/${id}`, data);
};

export const deleteGeneralProduct = (id: number) => {
  return api.delete<void>(`/products/general/${id}`);
};

export const getProductEventTags = (id: number) => {
  return api.get<EventTagDto[]>(`/products/${id}/eventTags`);
};

export const postProductEventTags = (id: number, eventTagIds: number[]) => {
  return api.post<EventTagDto[]>(`/products/${id}/eventTags/${eventTagIds}`);
};

export const deleteProductEventTags = (id: number, eventTagIds: number[]) => {
  return api.delete<EventTagDto[]>(`/products/${id}/eventTags/${eventTagIds}`);
};

export const getBooksenProduct = (isbn: string) => {
  const params = {
    format: DetailDtoType.PRODUCT,
  };
  return api.get<ProductDto>(`/booksen/products/${isbn}`, { params });
};

export const getBooksenUpdates = () => {
  const params = {
    yyyymmdd: moment(new Date()).format('YYYYMMDD'),
  };
  return api.get(`/booksen/updates`, { params });
};

export const postBooksenUpdates = (file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  return api.post(`/booksen/updates`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const copyGeneralProduct = ({
  id,
  count,
}: {
  id: number;
  count: number;
}) => {
  return api.post(`/products/general/copy/${id}`, {
    count,
  });
};

export const putBooksenUpdates = () => {
  return api.put(`/booksen/updates`);
};

export const putBooksenUpdateISBN = (isbn: string[]) => {
  const data = { codes: isbn };
  return api.put<{ books: ProductDto[]; codes: string[]; total: number }>(
    `/booksen/books/isbn`,
    data
  );
};
