import {api} from "../plugins/axios";
import {Paged} from "../types/common";
import {CreateMileageHistoryDto, MileageHistoryDto} from "../types/mileageHistory";

export const getMileageHistories = (userId: number, page?: number, itemsPerPage?: number) => {
    const params = {
        page: page || 1,
        itemsPerPage: itemsPerPage || 30,
    };
    return api.get<Paged<MileageHistoryDto>>(`/mileage-histories/${userId}`, {params});
}

export const postMileageHistory = (userId: number, data: CreateMileageHistoryDto) => {
    return api.post<MileageHistoryDto>(`/mileage-histories/${userId}`, data);
}