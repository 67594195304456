export enum BannerType {
    MAIN = 'MAIN',
    SHOP = 'SHOP',
    MINI = 'MINI',
    CURATION = 'CURATION',
    POSTING = 'POSTING',
}

export interface CreateMainBannerDto {
    type: BannerType;
    name: string;
    url: string;
    pcBannerImageUrl: string;
    mobileBannerImageUrl: string;
    order: number;
}

export interface MainBannerDto {
    id?: number;
    createdAt?: Date;
    isShow?: boolean;
    type?: BannerType;
    name?: string;
    url?: string;
    pcBannerImageUrl?: string;
    mobileBannerImageUrl?: string;
    order?: number;
}

export interface UpdateMainBannerDto {
    isShow?: boolean;
    type?: BannerType;
    name?: string;
    url?: string;
    pcBannerImageUrl?: string;
    mobileBannerImageUrl?: string;
    order?: number;
}