import {Paged} from "../types/common"
import {CommentDto, UpdateCommentByAdminDto} from "../types/comment";
import {api} from "../plugins/axios";

// 고객의 comment: where[userId] 게시글의 comment: where[postingId]
export const getComments = (query: string, page?: number, itemsPerPage?: number) => {
    const params = {page, itemsPerPage};
    return api.get<Paged<CommentDto>>(`/admin/comments?${query}`, {params});
}

export const patchComment = (id: number, data: UpdateCommentByAdminDto) => {
    return api.patch<CommentDto>(`/admin/comments/${id}`, data);
}