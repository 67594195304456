import React, {useEffect, useState} from "react";
import {getBranches} from "../api/branchesAPI";
import {BranchDto} from "../types/branch";
import {Select} from "./Select";

export interface BranchSelectorProps {
    onChange: (branch: BranchDto | null) => void;
    selectedBranchCode?: string;
}

export const BranchSelector = (props: BranchSelectorProps) => {
    const [branches, setBranches] = useState<BranchDto[]>([]);

    useEffect(() => {
        getBranches()
            .then(r => {
                const list = r.data.sort((a, b) => a.name.localeCompare(b.name));
                setBranches(list);
            })
            .catch(e => console.error(e.response));
    }, []);

    useEffect(() => {
        if (!!props.selectedBranchCode) {
            const branch = branches.find(c => c.code === props.selectedBranchCode);
            if (!!branch) {
                props.onChange(branch);
            }
        } else {
            props.onChange(branches[0]);
        }
    }, [branches, window.location.search])

    return (
        <Select className="h-12 w-44" label="지점 선택"
                value={props.selectedBranchCode ? props.selectedBranchCode : ""}
                onChange={(e) => {
                    const code = e.target.value;
                    if (code) {
                        const branch = branches.find(branch => branch.code === code);
                        props.onChange(branch!);
                    } else {
                        props.onChange(null);
                    }
                }}>
            <option hidden value="">지점명</option>
            {/*<option value="">전체</option>*/}
            {branches.map((branch, index) => (
                <option key={index} value={branch.code}>
                    {branch.name}
                </option>
            ))}
        </Select>
    )
}