import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {CreateCurationDto, UpdateCurationDto} from "../../../types/curation";
import {
    addCurationProduct,
    deleteCurationItem,
    getCurationItem,
    postCuration,
    updateCurationItem
} from "../../../api/curationAPI";
import {CategoryProductModal} from "../modal/CategoryProductModal";
import {TextField} from "../../../components/TextField";
import {Checkbox} from "../../../components/Checkbox";
import {Button} from "../../../components/Button";
import {ProductList} from "../ProductList";
import {BannerType} from "../../../types/banner";
import BannerListTemplate from "./BannerListTemplate";
import {toast} from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
import { CurationProductDto } from "../../../types/arcnShop";

export interface CategoryTemplateProps {
    type: 'ADD' | 'EDIT';
    targetId?: number;
}

const CategoryTemplate = (props: CategoryTemplateProps) => {
    const {type, targetId} = props;
    const {goBack, replace} = useHistory();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const form = useForm<CreateCurationDto | UpdateCurationDto>({
        defaultValues: {
            banners: [],
            products: [],
        }
    });

    const onSubmit = async (data: CreateCurationDto) => {
        if (type === 'ADD') {
            postCuration(data)
                .then((res) => {
                    toast.success('추가 성공. 배너와 상품을 추가해주세요.');
                    replace(`/admin/category/${res.data.id}`);
                })
                .catch(err => console.error(err.response));
        } else {
            updateCurationItem(targetId!, {name: data.name, isBannerShow: data.isBannerShow})
                .then(() => {
                    toast.success('수정되었습니다.');
                    goBack();
                })
                .catch(err => console.error(err.response));
        }
    }

    const removeThis = () => {
        deleteCurationItem(targetId!).then(() => {
            toast.success('삭제되었습니다.');
            goBack();
        }).catch(err => console.error(err.response));
    };

    const addProduct = (productId: number, order: number) => {
        addCurationProduct(targetId!, {productId, order}).then(() => {
            toast.success('상품이 추가되었습니다.');
            getItem();
        }).catch(err => console.error(err.response));
    }

    const getItem = () => {
        targetId && getCurationItem(targetId).then(res => {
            const {data} = res;
            form.setValue('name', data.name);
            form.setValue('isBannerShow', data.isBannerShow);
            form.setValue('banners', data.banners);
            form.setValue('products', data.products as any);
        });
    }

    useEffect(() => {
        if (type === 'ADD') {
            form.setValue('isBannerShow', true);
            form.setValue('banners', []);
            form.setValue('products', []);
        } else {
            getItem();
        }
    }, [])

    return (
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <CategoryProductModal open={isOpen} onClose={() => setIsOpen(false)}/>

            <div className="grid grid-cols-3 gap-5">
                <TextField label="카테고리명"
                           {...form.register('name', {required: true})}
                />
                {targetId && (
                    <>
                        <TextField label="해당 카테고리 url"
                                   value={`${process.env.REACT_APP_FRONT_URL}/curations/${targetId}`}
                                   disabled/>
                        <CopyToClipboard text={`${process.env.REACT_APP_FRONT_URL}/curations/${targetId}`}
                                         onCopy={() => toast.info('복사되었습니다.')}>
                            <Button text="복사" className="filled-black h-12 px-6 w-20 mt-7"/>
                        </CopyToClipboard>
                    </>
                )}
                <div className="col-start-1">
                    <Checkbox label="상단 배너 숨기기"
                              checked={!form.watch('isBannerShow') || false}
                              onChange={() => form.setValue('isBannerShow', !form.watch('isBannerShow'))}
                    />
                </div>
            </div>

            {targetId && (
                <>
                    <div className="my-10 h-3 w-full bg-gray-50"/>
                    <div className="col-span-3 col-start-1">
                        <BannerListTemplate type={BannerType.CURATION} paged={false} curationId={targetId}
                                            fallBannerList={form.watch('banners')}/>
                    </div>
                    <div className="my-10 h-3 w-full bg-gray-50"/>
                    <ProductList products={form.watch('products')! as CurationProductDto[]} onAddProduct={addProduct}
                                 onModalClose={() => getItem()}/>
                </>
            )}

            {type === 'ADD' ? (
                <div className="fixed bottom-0 -ml-8 w-full space-x-4 border-t bg-white py-2 pl-10">
                    <Button text="취소"
                            className="outlined-black h-12 px-6"
                            onClick={() => goBack()}
                    />
                    <Button type={'submit'} text="저장" className="filled-black h-12 px-6"/>
                </div>
            ) : (
                <div className="fixed bottom-0 -ml-8 w-full space-x-4 border-t bg-white py-2 pl-10">
                    <Button type={'button'} onClick={removeThis} text="삭제" className="outlined-black h-12 px-6"/>
                    <Button type={'submit'} text="저장" className="filled-black h-12 px-6"/>
                </div>
            )}


            <div className="pt-14"/>
        </form>
    );
}

export default CategoryTemplate