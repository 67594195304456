import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { Checkbox } from '../../../components/Checkbox';
import { Icon } from '../../../components/Icon';
import { TextArea } from '../../../components/TextArea';
import { TextField } from '../../../components/TextField';
import { FloalaEditor } from '../../components/FloalaEditor';
import { FileAbout } from '../../../types/common';
import {
  deleteBookProduct,
  getProduct,
  putBooksenUpdateISBN,
} from '../../../api/productAPI';
import {
  CreateBookDto,
  DiscountType,
  ProductType,
} from '../../../types/product';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import moment from 'moment';
import EventTagSelector from '../../components/EventTagSelector';
import useCategories from '../../../hooks/useCategories';
import { useQuery } from 'react-query';
import { TabType } from '../../enum';
import { getCategory } from '../../../api/categoryAPI';
import { Select } from '../../../components/Select';
import { useUpdateBookProduct } from '../../../hooks/useUpdateProduct';

const updateKeys = [
  'barcode',
  'isNew',
  'isSoldOut',
  'isDisplayHidden',
  'discountAmount',
  'discountType',
  'price',
  'categoryRank',
  'contentText',
  'contentHtml',
  'categoryId',
  'images',
  'isDependOnBooksenSoldOut',
  'remainQuantity',
];

export const BooksProductShow = () => {
  const { id } = useParams<{ id: string }>();
  const numId = parseInt(id);
  const history = useHistory();
  const form = useForm<Partial<CreateBookDto>>();
  const { data, isLoading } = useQuery(['product', numId], async () => {
    const { data } = await getProduct(numId);
    if (data) {
      updateKeys.forEach((key) => {
        // @ts-ignore
        const value = data[key];
        // @ts-ignore
        form.setValue(key, value);
      });
      return data;
    }
  });

  const { categories } = useCategories(TabType.BOOK);
  const updateProduct = useUpdateBookProduct({
    type: ProductType.BOOK,
    onSuccess: () => {
      toast.success('상품이 수정되었습니다.');
      history.goBack();
    },
  });
  const [selectedCategory, setSelectedCategory] = useState<any>({
    parent: null,
    children: null,
  });
  const { data: subCategories } = useQuery(
    ['categories', TabType.BOOK, selectedCategory.parent?.id],
    () => getCategory(Number(selectedCategory.parent?.id)),
    {
      enabled: !!selectedCategory.parent,
    }
  );

  useEffect(() => {
    if (categories && data) {
      const parent = data.category.category;
      const children = data.category;
      setSelectedCategory({ parent, children });
    }
  }, [categories, data]);

  const onDelete = () => {
    const deleteConfirm = window.confirm('정말 삭제 하시겠습니까?');
    if (deleteConfirm) {
      deleteBookProduct(numId)
        .then(() => {
          toast.success('상품이 삭제되었습니다.');
          history.goBack();
        })
        .catch((e) => console.error(e.response));
    }
  };

  const handleParentSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const matchedCategory = categories.find(
      (category) => category.id === Number(e.target.value)
    );
    setSelectedCategory({ parent: matchedCategory, children: null });
  };

  const handleChildSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const matchedCategory = subCategories?.find(
      (category) => category.id === Number(e.target.value)
    );
    setSelectedCategory({ ...selectedCategory, children: matchedCategory });
  };

  const onSave = (data: Partial<CreateBookDto>) => {
    if (selectedCategory.children === null) {
      toast('중분류를 선택해주세요.', {
        type: 'error',
      });
    }
    form.setValue(
      'categoryId',
      selectedCategory.children ? selectedCategory.children.id : ''
    );
    updateProduct(numId, data);
  };

  useEffect(() => {
    if (!!data && form.watch('discountType') === DiscountType.RATE) {
      let price =
        data?.regularPrice -
        (data?.regularPrice * (form.watch('discountAmount') || 0)) / 100;
      if (form.watch('discountAmount')! > 100) {
        form.setValue('discountAmount', 100);
      }
      form.setValue('price', price);
    } else if (!!data && form.watch('discountType') === DiscountType.PRICE) {
      let price = data?.regularPrice - form.watch('discountAmount')!;
      if (data?.regularPrice < form.watch('discountAmount')!) {
        form.setValue('discountAmount', data?.regularPrice);
      }
      form.setValue('price', price);
    }
  }, [form.watch('discountType'), form.watch('discountAmount'), data]);

  if (!data) return null;

  const updateThisBook = () => {
    putBooksenUpdateISBN([data?.barcode!])
      .then((data) => {
        form.setValue('remainQuantity', data.data.total);
        toast.success('도서 정보 수동업데이트가 완료되었습니다.');
      })
      .catch(() =>
        toast.error(
          '도서 정보 수동업데이트에 실패하였습니다. 다시 시도해주세요.'
        )
      );
  };

  return (
    <>
      <div className="space-y-4">
        <div className={'border-b py-6'}>
          <label className="relative inline-flex cursor-pointer items-center">
            <input
              type="checkbox"
              value=""
              className="peer sr-only"
              checked={form.watch('isDependOnBooksenSoldOut')}
              onChange={() =>
                form.setValue(
                  'isDependOnBooksenSoldOut',
                  !form.watch('isDependOnBooksenSoldOut')
                )
              }
            />
            <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:top-0.5 after:left-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-black peer-checked:after:translate-x-full peer-checked:after:border-white dark:border-gray-600 dark:bg-gray-700"></div>
            <span className="ml-3 text-gray-900 dark:text-gray-300">
              북센 품절 연동 비활성화 / 활성화
            </span>
          </label>
        </div>
        <div className="flex space-x-5">
          <Checkbox
            label="상품 숨김처리하기"
            checked={form.watch('isDisplayHidden')}
            onChange={() =>
              form.setValue('isDisplayHidden', !form.watch('isDisplayHidden'))
            }
          />
          <Checkbox
            label="상품 품절처리하기"
            checked={form.watch('isSoldOut')}
            onChange={() =>
              form.setValue('isSoldOut', !form.watch('isSoldOut'))
            }
          />
        </div>
        <div className="flex space-x-5">
          <Checkbox
            label="신상품으로 설정(NEW)"
            checked={form.watch('isNew')}
            onChange={() => form.setValue('isNew', !form.watch('isNew'))}
          />
        </div>
      </div>

      <div className="w-full bg-gray-50 py-2" />

      <div className="grid grid-cols-3 gap-5">
        <label htmlFor="imgPath">
          <div className="mb-2 text-sm">표지이미지1 (북센)</div>

          {!data.images[0] && (
            <div className="grid aspect-[3/5] place-items-center rounded border text-gray-500">
              <div className="flex flex-col items-center space-y-2 text-xs">
                <Icon.FileUpload />
                <p>이미지를 업로드 해주세요.</p>
              </div>
            </div>
          )}

          {data.images[0] && (
            <>
              <div className="relative aspect-[3/5] overflow-hidden rounded border">
                <img
                  className="absolute h-full w-full object-cover"
                  src={data.images[0]}
                  alt=""
                />
              </div>
            </>
          )}
        </label>
        <label htmlFor="imgPath2">
          <div className="mb-2 text-sm">표지이미지2 (북센)</div>

          {!data.images[1] && (
            <div className="grid aspect-[3/5] place-items-center rounded border text-gray-500">
              <div className="flex flex-col items-center space-y-2 text-xs">
                <Icon.FileUpload />
                <p>이미지를 업로드 해주세요.</p>
              </div>
            </div>
          )}

          {data.images[1] && (
            <>
              <div className="relative aspect-[3/5] overflow-hidden rounded border">
                <img
                  className="absolute h-full w-full object-cover"
                  src={data.images[1]}
                  alt=""
                />
              </div>
            </>
          )}
        </label>
      </div>

      <div className="grid grid-cols-3 gap-5 pt-5">
        <div className="col-span-3 text-lg font-semibold">상품 세부정보</div>
        <TextField label="ISBN" value={data.barcode} disabled={true} />
        <div className={'space-y-3'}>
          <p>
            최근 업데이트 일자 :{' '}
            <b>{moment(data.updatedAt).format('YYYY.MM.DD HH:mm:ss')}</b>
          </p>
          <Button
            className="filled-black flex h-10 items-center space-x-2 text-sm"
            onClick={updateThisBook}
          >
            <Icon.RefreshCCW className="wh-4" />
            <p>도서정보 수동 업데이트하기</p>
          </Button>
        </div>
        <p>
          도서 상태 : <b>도서 상태</b>
        </p>
        <TextField
          type={'number'}
          label="재고수량"
          helper={form.formState.errors.remainQuantity?.message}
          {...form.register('remainQuantity', {
            required: '재고수량을 입력해주세요.',
            valueAsNumber: true,
          })}
        />
        <TextField
          label="정가(원)"
          value={data.regularPrice.toLocaleString()}
          disabled={true}
        />
        <TextField
          label="출고가(원)"
          value={data.sourcePrice.toLocaleString()}
          disabled={true}
        />
        <div>
          {form.watch('discountType') === DiscountType.RATE ? (
            <TextField
              label="할인율(%)"
              type={'number'}
              defaultValue={10}
              {...form.register('discountAmount', {
                required: '할인율을 입력하세요',
                setValueAs: (value) => Number(value),
              })}
              helper={form.formState.errors.discountAmount?.message}
            />
          ) : (
            <TextField
              label="할인금액(원)"
              type={'number'}
              defaultValue={0}
              {...form.register('discountAmount', {
                required: '할인율을 입력하세요',
                setValueAs: (value) => Number(value),
              })}
              helper={form.formState.errors.discountAmount?.message}
            />
          )}
          <button
            type={'button'}
            className={'label text-blue-500'}
            onClick={() => {
              form.setValue(
                'discountType',
                form.watch('discountType') === DiscountType.RATE
                  ? DiscountType.PRICE
                  : DiscountType.RATE
              );
              form.setValue('discountAmount', 0);
            }}
          >
            전환 {'→'}{' '}
            {form.watch('discountType') === DiscountType.RATE ? '₩' : '%'}
          </button>
        </div>
        <TextField
          label="할인율 적용시 할인가(실판매가)"
          value={(form.watch('price') || 0).toLocaleString()}
          disabled={true}
        />
        <TextField
          label="분류값에서 랭킹"
          defaultValue={0}
          type={'number'}
          {...form.register('categoryRank', {
            setValueAs: (value) => Number(value),
          })}
        />
        <TextField label="도서명" value={data.name} disabled={true} />
        <TextField
          label="저자"
          value={data.bookInfo.authorName}
          disabled={true}
        />
        <TextField
          label="출판사"
          value={data.bookInfo.publisherName}
          disabled={true}
        />
        <Select
          label={'대분류'}
          value={selectedCategory.parent?.id.toString()}
          onChange={handleParentSelect}
        >
          <option value={''}>선택하세요</option>
          {categories.map((category, index) => (
            <option key={index} value={category.id}>
              {category.name}
            </option>
          ))}
        </Select>
        <Select
          label={'중분류'}
          value={selectedCategory.children?.id.toString()}
          onChange={handleChildSelect}
        >
          <option hidden={true} value={''}>
            선택하세요
          </option>
          <option value={''}>전체</option>
          {subCategories?.map((category, index) => (
            <option key={index} value={category.id}>
              {category.name}
            </option>
          ))}
        </Select>
        <TextField
          label="발간일"
          value={moment(data.bookInfo.publishedAt).format('YYYY.MM.DD')}
          disabled={true}
        />
        <div className="col-span-3 ">
          <TextArea
            label="목차"
            className="h-32"
            value={data.bookInfo.descTable}
            disabled={true}
          />
        </div>
        <div className="col-span-3 ">
          <TextArea
            label="출판사서평"
            className="h-32"
            value={data.bookInfo.descPub}
            disabled={true}
          />
        </div>
        <div className="col-span-3 ">
          <TextArea
            label="상세설명"
            className="h-32"
            {...form.register('contentText')}
          />
        </div>
        <div className="col-span-3 ">
          <div className="col-span-3 mb-2 text-lg font-semibold">
            상품 상세이미지
          </div>
          <FloalaEditor
            imageType={FileAbout.PRODUCT_BOOK}
            defaultValue={form.watch('contentHtml')}
            onChange={(content) => form.setValue('contentHtml', content)}
          />
        </div>
      </div>

      <div className="w-full bg-gray-50 py-2" />

      <EventTagSelector productId={numId} />

      <div className="fixed bottom-0 z-10 -ml-8 w-full space-x-4 border-t bg-white py-2 pl-10">
        <Button
          text="삭제"
          className="outlined-black h-12 px-6"
          onClick={onDelete}
        />
        <Button
          text="저장"
          className="filled-black h-12 px-6"
          onClick={form.handleSubmit(onSave)}
        />
      </div>
    </>
  );
};
