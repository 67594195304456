import React, { useEffect, useMemo } from 'react';
import { AdminH1 } from '../../components/AdminH1';
import { CreateSectionDto, SectionType } from '../../../types/sections';
import SelectBox from '../../../components/SelectBox';
import { TextField } from '../../../components/TextField';
import { TextArea } from '../../../components/TextArea';
import { useForm } from 'react-hook-form';
import { Button } from '../../../components/Button';
import { useHistory } from 'react-router-dom';
import { addSection, getSections } from '../../../api/sectionAPI';
import queryString from 'query-string';

export interface SectionSelectItem {
  id: SectionType;
  title: string;
  imgSrc: string;
  disabled?: boolean;
}

const SectionAddPage = () => {
  const history = useHistory();
  const query = queryString.parse(history.location.search);

  const { goBack } = useHistory();

  const form = useForm();

  const [selectedItem, setSelectedItem] =
    React.useState<SectionSelectItem | null>(null);

  const [hasType, setHasType] = React.useState({
    keyword: false,
    banner: false,
    gridCard1: false,
    gridCard2: false,
  });

  const handleSelect = (item: SectionSelectItem) => {
    setSelectedItem(item);
  };

  const getList = () => {
    if (!query.type) return;

    getSections(query.type as 'MAIN' | 'LIFE')
      .then((res) => {
        const list = res.data;
        const hasKeyword = list.find(
          (section) => section.type === SectionType.KEYWORD_SECTION
        );
        const hasBanner = list.find(
          (section) => section.type === SectionType.BANNER_SECTION
        );
        const hasGridCard1 = list.find(
          (section) => section.type === SectionType.GRID_CARD_SECTION_1
        );
        const hasGridCard2 = list.find(
          (section) => section.type === SectionType.GRID_CARD_SECTION_2
        );

        setHasType({
          keyword: !!hasKeyword,
          banner: !!hasBanner,
          gridCard1: !!hasGridCard1,
          gridCard2: !!hasGridCard2,
        });
      })
      .catch((err) => console.error(err.response));
  };

  const onSubmit = (
    data: Pick<CreateSectionDto, 'title' | 'description' | 'clickUrl'>
  ) => {
    if (!selectedItem || !query.type) return;
    addSection({
      position: query.type as 'MAIN' | 'LIFE',
      title: data.title,
      description: data.description,
      clickUrl: data.clickUrl,
      order: 9999,
      isShow: false,
      type: selectedItem?.id,
    }).then(() => {
      goBack();
    });
  };
  useEffect(() => getList(), []);

  const SectionTypes: SectionSelectItem[] = useMemo(() => {
    return [
      {
        id: SectionType.BANNER_SECTION,
        title: '배너 섹션',
        imgSrc:
          'https://arcnbook-service.s3.ap-northeast-2.amazonaws.com/sections/banner_section.png',
        disabled: hasType.banner,
      },
      {
        id: SectionType.GRID_CARD_SECTION_1,
        title: '그리드 카드 섹션 1',
        imgSrc:
          'https://arcnbook-service.s3.ap-northeast-2.amazonaws.com/sections/gridcard_section_1.png',
        disabled: hasType.gridCard1,
      },
      {
        id: SectionType.GRID_CARD_SECTION_2,
        title: '그리드 카드 섹션 2',
        imgSrc:
          'https://arcnbook-service.s3.ap-northeast-2.amazonaws.com/sections/gridcard_section_2.png',
        disabled: hasType.gridCard2,
      },
      {
        id: SectionType.KEYWORD_SECTION,
        title: '키워드 섹션',
        imgSrc:
          'https://arcnbook-service.s3.ap-northeast-2.amazonaws.com/sections/keyword_section.png',
        disabled: hasType.keyword,
      },
      {
        id: SectionType.MULTI_CARD_SECTION_1,
        title: '멀티 카드 섹션 1',
        imgSrc:
          'https://arcnbook-service.s3.ap-northeast-2.amazonaws.com/sections/multicard_section_1.png',
        disabled: false,
      },
      {
        id: SectionType.MULTI_CARD_SECTION_2,
        title: '멀티 카드 섹션 2',
        imgSrc:
          'https://arcnbook-service.s3.ap-northeast-2.amazonaws.com/sections/multicard_section_2.png',
        disabled: false,
      },
      {
        id: SectionType.SINGLE_CARD_SECTION,
        title: '싱글 카드 섹션',
        imgSrc:
          'https://arcnbook-service.s3.ap-northeast-2.amazonaws.com/sections/singlecard_section.png',
        disabled: false,
      },
    ];
  }, [hasType]);

  const formComponent = useMemo(() => {
    let formComponent = <></>;
    if (selectedItem === null) return formComponent;
    switch (selectedItem.id) {
      case SectionType.BANNER_SECTION:
        break;
      case SectionType.GRID_CARD_SECTION_1:
      case SectionType.GRID_CARD_SECTION_2:
        formComponent = (
          <div className={'flex flex-col gap-4'}>
            <TextField
              label={'타이틀'}
              helper={form.formState.errors.title?.message}
              {...form.register('title')}
            />

            <div className="col-span-2">
              <TextArea
                label="상세설명"
                className="h-32"
                {...form.register('description')}
              />
            </div>
          </div>
        );
        break;
      case SectionType.KEYWORD_SECTION:
        formComponent = (
          <div>
            <TextField
              label={'타이틀'}
              helper={form.formState.errors.title?.message}
              {...form.register('title')}
            />
          </div>
        );
        break;
      default:
        formComponent = (
          <div className={'grid grid-cols-2 gap-5'}>
            <TextField
              label={'타이틀'}
              helper={form.formState.errors.title?.message}
              {...form.register('title')}
            />
            <TextField
              label={'클릭시 이동 URL'}
              helper={form.formState.errors.clickUrl?.message}
              {...form.register('clickUrl', {
                required: 'URL은 필수입니다.',
              })}
            />
            <div className="col-span-2">
              <TextArea
                label="상세설명"
                className="h-32"
                {...form.register('description')}
              />
            </div>
          </div>
        );
        break;
    }
    return formComponent;
  }, [selectedItem]);

  return (
    <div>
      <AdminH1>
        {query.type === 'MAIN' ? '메인 섹션 추가' : '아크앤샵 섹션 추가'}
      </AdminH1>
      <div className={'mt-4 flex flex-col gap-4'}>
        <div>
          <div>
            {/* TODO: 설명 문구 ? */}
            <p className={'font-bold text-red-500'}>
              페이지의 배너, 키워드 섹션, 그리드 섹션의 경우 하나의 타입만 추가
              하실 수 있습니다.
            </p>
          </div>
          <SelectBox
            placeholder={'섹션 타입을 선택해주세요.'}
            selectedItem={selectedItem}
            onSelect={handleSelect}
            items={SectionTypes}
          />
        </div>
        <div>
          {selectedItem && (
            <form onSubmit={form.handleSubmit(onSubmit)}>
              {formComponent}
              <div className={'mt-4 flex gap-2'}>
                <Button
                  type={'button'}
                  text="취소"
                  className="outlined-black h-12 px-6"
                  onClick={() => goBack()}
                />
                <Button
                  type={'submit'}
                  text="저장"
                  className="filled-black h-12 px-6"
                />
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default SectionAddPage;
