import { Button } from '../../components/Button';
import { Card } from '../../components/Card';
import { Table } from '../../components/Table';
import React, { useState } from 'react';
import { ArcProductModal, ModalOpen } from './modal/ArcProductModal';
import {
  deleteCurationProduct,
  updateCurationProduct,
} from '../../api/ArcnShopAPI';
import moment from 'moment';
import { toast } from 'react-toastify';
import { ProductType } from '../../types/product';
import Reorderable from './Reorderable';
import { CurationProductDto } from '../../types/arcnShop';
import { Icon } from '../../components/Icon';

export interface ProductListProps {
  isDetailedProduct?: boolean;
  isHasOrder?: boolean;
  products: CurationProductDto[];
  isNoTitle?: boolean;
  onModalClose: () => void;
  onAddProduct: (
    productId: number,
    order: number,
    title?: string,
    description?: string
  ) => void;
}

export const ProductList = (props: ProductListProps) => {
  const [isOpen, setIsOpen] = useState<ModalOpen>({ open: false });

  const removeProduct = (productId: number) => {
    const confirm = window.confirm('정말 삭제하시겠습니까?');
    if (confirm) {
      deleteCurationProduct(productId)
        .then(() => {
          toast.success('삭제되었습니다.');
          props.onModalClose();
        })
        .catch((err) => console.error(err.response));
    }
  };

  const itemList = props.isHasOrder
    ? props.products.sort((a, b) => a.order - b.order)
    : props.products.sort((a, b) =>
        b.product.createdAt.localeCompare(a.product.createdAt)
      );

  return (
    <div>
      <ArcProductModal
        open={isOpen}
        isDetailedProduct={props.isDetailedProduct || false}
        isHasOrder={props.isHasOrder || false}
        onClose={() => {
          setIsOpen({ open: false });
          props.onModalClose();
        }}
        preSelectedItems={props.products}
        isNoTitle={props.isNoTitle || false}
        onSave={props.onAddProduct}
      />
      <div>
        <Button
          type={'button'}
          text="상품 추가"
          className="filled-black mb-4 h-12"
          onClick={() => setIsOpen({ open: true })}
        />
      </div>

      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>
                {props.isHasOrder ? '노출 우선순위' : '등록일자'}
              </Table.Th>
              <Table.Th>상품 노출 여부</Table.Th>
              <Table.Th>상품명</Table.Th>
              {props.isDetailedProduct ? (
                <>
                  {!props.isNoTitle && <Table.Th>타이틀</Table.Th>}
                  <Table.Th>상세설명</Table.Th>
                </>
              ) : null}
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {props.products.length > 0 ? (
              itemList.map((product, index) => (
                <Table.Row key={index}>
                  <Table.Td>
                    {props.isHasOrder ? (
                      <Reorderable
                        order={product.order}
                        onReordered={(order) => {
                          updateCurationProduct(product.id, {
                            productId: product.product.id,
                            order,
                          }).then(() => {
                            toast.success('순서가 변경되었습니다.');
                            props.onModalClose();
                          });
                        }}
                      />
                    ) : (
                      moment(product.product.createdAt).format(
                        'YYYY.MM.DD HH:mm'
                      )
                    )}
                  </Table.Td>
                  <Table.Td>
                    {!product.product.isDisplayHidden ? (
                      <Icon.Eye />
                    ) : (
                      <Icon.EyeOff />
                    )}
                  </Table.Td>

                  <Table.Td>
                    <a
                      href={
                        product.product.type === ProductType.BOOK
                          ? `/admin/books/product/${product.product.id}`
                          : `/admin/normal/product/${product.product.id}`
                      }
                      className={'text-blue-500'}
                    >
                      {product.product.name}
                    </a>
                  </Table.Td>
                  {props.isDetailedProduct && (
                    <>
                      {!props.isNoTitle && <Table.Td>{product.title}</Table.Td>}
                      <Table.Td>{product.description}</Table.Td>
                    </>
                  )}
                  <Table.Td className="space-x-3 text-right">
                    <button
                      type={'button'}
                      className="rounded-md font-normal text-red-600 hover:text-red-900"
                      onClick={() => removeProduct(product.id)}
                    >
                      삭제
                    </button>
                    <button
                      type={'button'}
                      className="rounded-md font-normal text-indigo-600 hover:text-indigo-900"
                      onClick={() =>
                        setIsOpen({ open: true, target: product.id })
                      }
                    >
                      수정
                    </button>
                  </Table.Td>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Td colSpan={3}>상품이 없습니다.</Table.Td>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Card>
      <div style={{ height: 40 }} />
    </div>
  );
};
