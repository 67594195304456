import React, {useEffect} from 'react';
import {Link, useHistory, useParams} from 'react-router-dom';
import {Card} from '../../../components/Card';
import {Table} from '../../../components/Table';
import {TextArea} from '../../../components/TextArea';
import {TextField} from '../../../components/TextField';
import {deletePromotion, getPromotionDetail, patchPromotion} from '../../../api/promotionAPI';
import {PromotionDto} from '../../../types/promotion';
import {deleteGeneralProduct} from '../../../api/productAPI';
import moment from "moment";
import {Button} from "../../../components/Button";
import {PromotionKindKeySelector} from "../../components/PromotionKindKeySelector";
import {toast} from "react-toastify";

export const PromotionDetail = () => {
    const history = useHistory();
    const {id} = useParams() as { id: string };
    const [promotion, setPromotion] = React.useState<PromotionDto | undefined>(undefined);

    const onDelete = (id: number) => {
        const confirm = window.confirm('상품을 삭제하시겠습니까?');
        if (confirm) {
            deleteGeneralProduct(id).then(() => getItem());
        }
    };

    const getItem = () => {
        getPromotionDetail(id).then((res) => {
            setPromotion(res.data);
        });
    };

    const isStarted = moment(promotion?.startAt) < moment();

    const removeThis = () => {
        deletePromotion(Number(id)).then(() => {
            toast.success('삭제되었습니다.');
            history.goBack();
        });
    }

    const saveThis = () => {
        patchPromotion(Number(id), {
            kindKey: promotion?.kindKey,
            title: promotion?.title,
            startAt: promotion?.startAt,
            finishAt: promotion?.finishAt,
            content: promotion?.content,
        }).then(() => {
            toast.success('변경사항이 저장되었습니다.');
            history.goBack();
        });
    }

    useEffect(() => {
        getItem();
    }, [id]);

    useEffect(() => {
        console.log(promotion);
    }, [promotion])

    if (!promotion) return null;
    return (
        <>
            <div className="grid grid-cols-2 gap-4">
                <PromotionKindKeySelector defaultValue={promotion.kindKey}
                                          onSelect={key => setPromotion({...promotion, kindKey: key})}
                                          disabled={isStarted}
                />
                <TextField label="프로모션명" defaultValue={promotion.title} disabled={isStarted}
                           onChange={e => setPromotion({...promotion, title: e.target.value})}
                />
                <TextField label="노출 시작일" type="date"
                           defaultValue={moment(promotion.startAt).format('YYYY-MM-DD')}
                           onChange={e => setPromotion({...promotion, startAt: e.target.value})}
                           // disabled={isStarted}
                />
                <TextField label="노출 종료일" type="date"
                           defaultValue={moment(promotion.finishAt).format('YYYY-MM-DD')}
                           onChange={e => setPromotion({...promotion, finishAt: e.target.value})}
                           // disabled={isStarted}
                />
                <div className="col-span-2">
                    <TextArea label="상세설명"
                              className="h-32"
                              disabled={isStarted}
                              defaultValue={promotion.content}
                              onChange={e => setPromotion({...promotion, content: e.target.value})}
                    />
                </div>
            </div>

            <div className="-mx-8 h-2 bg-gray-50"/>

            <p className={'text-gray-500'}>
                * 프로모션 상품을 추가하시려면 일반상품 상세 페이지에서 "프로모션 상품
                생성하기" 버튼을 눌러주세요.
            </p>
            <Card>
                <Table>
                    <Table.Head>
                        <Table.Row>
                            <Table.Th>상품명</Table.Th>
                            <Table.Th>프로모션가</Table.Th>
                            <Table.Th>판매수량</Table.Th>
                            <Table.Th></Table.Th>
                        </Table.Row>
                    </Table.Head>
                    <Table.Body>
                        {!!promotion.products && promotion.products.length === 0 && (
                            <Table.Row>
                                <Table.Td>상품이 없습니다.</Table.Td>
                            </Table.Row>
                        )}
                        {!!promotion.products && promotion.products.map((e) => (
                            <Table.Row key={e.id}>
                                <Table.Td>{e.name}</Table.Td>
                                <Table.Td>{e.price.toLocaleString()}</Table.Td>
                                <Table.Td>{e.soldQuantity!.toLocaleString()}</Table.Td>

                                <Table.Td className="space-x-3 text-right">
                                    <button className="rounded-md font-normal text-red-600 hover:text-red-900"
                                            onClick={() => onDelete(e.id)}
                                    >
                                        삭제
                                    </button>
                                    <Link className="rounded-md font-normal text-indigo-600 hover:text-indigo-900"
                                          to={`/admin/promotion/product/${e.id}`}
                                    >
                                        수정
                                    </Link>
                                </Table.Td>

                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </Card>

            <div className="py-5"/>

            <div className="fixed bottom-0 -ml-8 w-full space-x-4 border-t bg-white py-2 pl-10">
                <Button text="삭제" disabled={isStarted} onClick={removeThis} className="outlined-black h-12 px-6"/>
                <Button text="저장" disabled={false} onClick={saveThis} className="filled-black h-12 px-6"/>
            </div>
        </>
    );
};
