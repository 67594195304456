import React, { useState } from 'react';
import { Button } from '../../../../components/Button';
import { TextField } from '../../../../components/TextField';
import { AdminH1 } from '../../../components/AdminH1';
import { ReactComponent as FileUploadImg } from '../../../../assets/svg/file-upload.svg';
import { useHistory } from 'react-router-dom';

export const BannerTypeProductAdd = () => {
  const [pcBanner, setPcBanner] = useState<File | null | undefined>();
  const [mobileBanner, setMobileBanner] = useState<File | null | undefined>();
  const { goBack } = useHistory();
  return (
    <div className="max-w-md space-y-4">
      <AdminH1>배너 추가</AdminH1>

      <TextField label="노출 우선순위" />
      <TextField label="배너명" />
      <TextField label="url" />
      <div>
        <label htmlFor="pcBanner">
          <div className="mb-2 text-sm text-gray-800 ">PC 배너</div>

          {pcBanner ? (
            <>
              <img src={URL.createObjectURL(pcBanner)} alt="" />
              <div className="mt-4 w-full">
                <Button className="filled-black h-12 py-3">
                  <label htmlFor="pcBanner" className="w-full cursor-pointer">
                    수정하기
                  </label>
                </Button>
              </div>
            </>
          ) : !pcBanner ? (
            <div className="border-grey-5 flex w-full flex-col items-center justify-center space-y-1 border-2 border-dashed py-10">
              <FileUploadImg />
              <div className="w-3/5 text-center text-xs text-gray-500">
                상세 이미지 첨부하기
              </div>
            </div>
          ) : (
            ''
          )}
        </label>
        <input
          type="file"
          id="pcBanner"
          className="hidden"
          accept="image/*"
          onChange={(e) => {
            e.target.validity.valid && setPcBanner(e.target.files?.item(0));
          }}
        />
      </div>

      <div>
        <label htmlFor="mobileBanner">
          <div className="mb-2 text-sm text-gray-800 ">Mobile 배너</div>

          {mobileBanner ? (
            <>
              <img src={URL.createObjectURL(mobileBanner)} alt="" />
              <div className="mt-4 w-full">
                <Button className="filled-black h-12 py-3">
                  <label
                    htmlFor="mobileBanner"
                    className="w-full cursor-pointer"
                  >
                    수정하기
                  </label>
                </Button>
              </div>
            </>
          ) : !mobileBanner ? (
            <div className="border-grey-5 flex w-full flex-col items-center justify-center space-y-1 border-2 border-dashed py-10">
              <FileUploadImg />
              <div className="w-3/5 text-center text-xs text-gray-500">
                상세 이미지 첨부하기
              </div>
            </div>
          ) : (
            ''
          )}
        </label>
        <input
          type="file"
          id="mobileBanner"
          className="hidden"
          accept="image/*"
          onChange={(e) => {
            e.target.validity.valid && setMobileBanner(e.target.files?.item(0));
          }}
        />
      </div>

      <Button text="저장하기" className="filled-black w-full" />
      <Button
        text="취소하기"
        className="filled-gray-200 w-full text-gray-800"
        onClick={() => goBack()}
      />
    </div>
  );
};
