import {TextField} from "../../components/TextField";
import {Button} from "../../components/Button";
import React, {useEffect} from "react";
import {CreateTopMenuDto} from "../../types/topMenu";
import {useHistory, useParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {deleteMenu, getMenu, updateMenu} from "../../api/menuAPI";
import {toast} from "react-toastify";

const MenuShow = () => {
    const {id} = useParams<{ id: string }>();
    const numId = Number(id);
    const history = useHistory();
    const form = useForm<Partial<CreateTopMenuDto>>();

    const onSubmit = (data: CreateTopMenuDto) => {
        updateMenu(numId, data)
            .then(() => {
                toast.success('메뉴 수정 성공');
                history.goBack();
            })
            .catch(err => console.error(err.response));
    }

    const removeThis = () => {
        const remove = window.confirm('정말 삭제하시겠습니까?');
        if (remove) {
            deleteMenu(numId)
                .then(() => {
                    toast.success('메뉴 삭제 성공');
                    history.goBack();

                })
                .catch(err => console.error(err.response));
        }
    }

    useEffect(() => {
        getMenu(numId).then((res) => {
            form.setValue('name', res.data.name);
            form.setValue('url', res.data.url);
            form.setValue('order', res.data.order);
        })
    }, [numId])

    return (
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="max-w-md space-y-4">
                <TextField label="노출 우선순위"
                           {...form.register('order', {required: true, setValueAs: value => Number(value)})}/>
                <TextField label="카테고리명"
                           {...form.register('name', {required: true})}/>
                <TextField label="클릭시 URL"
                           {...form.register('url', {required: true})}/>
                <Button type={'submit'} text="저장하기" className="filled-black h-12 w-full px-6"/>
                <Button type={'button'}
                        text="삭제하기"
                        onClick={removeThis}
                        className="filled-gray-200 h-12 w-full px-6 text-gray-800"
                />
            </div>
        </form>
    )
}

export default MenuShow;