import React, { useEffect, useState } from 'react';
import { Button } from '../../../components/Button';
import { Checkbox } from '../../../components/Checkbox';
import { Icon } from '../../../components/Icon';
import { TextArea } from '../../../components/TextArea';
import { TextField } from '../../../components/TextField';
import { FloalaEditor } from '../../components/FloalaEditor';
import { FileAbout } from '../../../types/common';
import {
  deleteGeneralProduct,
  getProduct,
  patchGeneralProduct,
} from '../../../api/productAPI';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { CreateGeneralDto, DiscountType } from '../../../types/product';
import EventTagSelector from '../../components/EventTagSelector';
import CompanySelector from '../../components/CompanySelector';
import { toast } from 'react-toastify';
import { uploadFile } from '../../../api/fileAPI';
import { Promotion } from '../../components/modal/PromotionModal';
import { Select } from '../../../components/Select';
import { useQuery } from 'react-query';
import { TabType } from '../../enum';
import { getCategory } from '../../../api/categoryAPI';
import useCategories from '../../../hooks/useCategories';
import { CategoryDto } from '../../../types/categories';
type SelectedCategory = {
  parent: CategoryDto | null;
  children: CategoryDto | null;
};
export const NormalProductShow = () => {
  const param = useParams<{ id: string }>();
  const id = Number(param.id);
  const history = useHistory();
  const { categories } = useCategories(TabType.LIFE);

  const form = useForm<Partial<CreateGeneralDto>>();

  const { data, isLoading } = useQuery(
    ['life', id],
    async () => {
      const { data } = await getProduct(id);
      if (data) {
        form.setValue('isDisplayHidden', data.isDisplayHidden);
        form.setValue('isSoldOut', data.isSoldOut);
        form.setValue('isNew', data.isNew);
        form.setValue('companyId', data.companyId);
        form.setValue('images', data.images);
        form.setValue('barcode', data.barcode);
        form.setValue('name', data.name);
        form.setValue('regularPrice', data.regularPrice);
        form.setValue('price', data.price);
        form.setValue('discountAmount', data.discountAmount);
        form.setValue('discountType', data.discountType);
        form.setValue('contentText', data.contentText);
        form.setValue('contentHtml', data.contentHtml);
        form.setValue('promotionId', data.promotionId);
        return data;
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<SelectedCategory>({
    parent: null,
    children: null,
  });
  const { data: subCategories } = useQuery(
    ['categories', TabType.BOOK, selectedCategory.parent?.id],
    () => getCategory(Number(selectedCategory.parent?.id)),
    {
      enabled: !!selectedCategory.parent,
    }
  );

  useEffect(() => {
    if (categories && data) {
      if (data.category.category) {
        const parent = data.category.category;
        const children = data.category;
        setSelectedCategory({ parent, children });
      }
      if (!data.category.category) {
        const parent = data.category;
        setSelectedCategory({ parent, children: null });
      }
    }
  }, [categories, data]);
  const isPromotionProduct = history.location.pathname.includes('promotion');

  const handleParentSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const matchedCategory = categories.find(
      (category) => category.id === Number(e.target.value)
    ) ?? null;
    setSelectedCategory({ parent: matchedCategory, children: null });
  };

  const handleChildSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const matchedCategory = subCategories?.find(
      (category) => category.id === Number(e.target.value)
    ) ?? null;
    setSelectedCategory((prevState) => ({
      ...prevState,
      children: matchedCategory,
    }));
  };

  const deleteItem = () => {
    const confirm = window.confirm('정말 삭제하시겠습니까?');
    if (confirm) {
      deleteGeneralProduct(id)
        .then((r) => {
          toast.success('삭제되었습니다.');
          history.goBack();
        })
        .catch((e) => console.error(e.response));
    }
  };

  const onSubmit = (data: Partial<CreateGeneralDto>) => {
    const result = {
      ...data,
      categoryId: selectedCategory.children
        ? selectedCategory.children.id
        : selectedCategory.parent?.id,
    };

    patchGeneralProduct({ id, data: result })
      .then((r) => {
        toast.success('상품이 수정되었습니다.');
        history.goBack();
      })
      .catch((e) => console.error(e.response));
  };

  useEffect(() => {
    if (form.watch('discountType') === DiscountType.RATE) {
      const rPrice = form.watch('regularPrice')!;
      const rate = form.watch('discountAmount')!;
      if (rate > 100) {
        form.setValue('discountAmount', 100);
      }
      form.setValue('price', rPrice - (rPrice * rate) / 100);
    } else {
      const rPrice = form.watch('regularPrice')!;
      const discount = form.watch('discountAmount')!;
      if (discount > rPrice) {
        form.setValue('discountAmount', rPrice);
      }
      form.setValue('price', rPrice - discount);
    }
  }, [form.watch('discountAmount'), form.watch('regularPrice')]);

  return (
    <>
      <Promotion
        open={isOpen}
        productId={id}
        onClose={() => setIsOpen(false)}
      />
      <form onSubmit={form.handleSubmit(onSubmit)}>
        {!isPromotionProduct && (
          <div className={'pb-6'}>
            <Button
              text="프로모션 상품 생성하기"
              className="filled-black h-10 text-sm"
              onClick={() => setIsOpen(true)}
            />
          </div>
        )}

        <div className="space-y-4">
          <div className="flex space-x-5">
            <Checkbox
              label="상품 숨김처리하기"
              checked={form.watch('isDisplayHidden')}
              onChange={() =>
                form.setValue('isDisplayHidden', !form.watch('isDisplayHidden'))
              }
            />
            <Checkbox
              label="상품 품절처리하기"
              checked={form.watch('isSoldOut')}
              onChange={() =>
                form.setValue('isSoldOut', !form.watch('isSoldOut'))
              }
            />
          </div>
          <div className="flex space-x-5">
            {/*<Checkbox label="성인 인증 상품" checked={form.watch('isAdultOnly')}*/}
            {/*          onChange={() => form.setValue('isAdultOnly', !form.watch('isAdultOnly'))}/>*/}
            <Checkbox
              label="신상품으로 설정(NEW)"
              checked={form.watch('isNew')}
              onChange={() => form.setValue('isNew', !form.watch('isNew'))}
            />
          </div>
        </div>
        <div className="my-6 w-full bg-gray-50 py-2" />

        <div className="grid grid-cols-3 gap-5">
          <CompanySelector
            selectedCompanyId={form.watch('companyId')}
            onChange={(company) =>
              form.setValue('companyId', company ? company.id : undefined)
            }
          />

          <div className="col-span-3 text-lg font-semibold">상품 이미지</div>

          {(form.watch('images') || []).map((image, index) => (
            <div key={index}>
              <div className="relative aspect-[1/1] overflow-hidden rounded border">
                <img
                  className="absolute h-full w-full object-cover"
                  src={image || ''}
                  alt=""
                />
              </div>
              <div className="mt-4 flex w-full">
                <Button className="filled-black w-full">
                  <label htmlFor={`imgChange_${index}`}>수정하기</label>
                  <input
                    type="file"
                    id={`imgChange_${index}`}
                    className="hidden"
                    accept="image/*"
                    onChange={(e) => {
                      e.target.validity.valid &&
                        uploadFile(
                          FileAbout.PRODUCT_GENERAL,
                          e.target.files?.item(0)!
                        )
                          .then((r) => form.setValue(`images.${index}`, r))
                          .catch((e) => console.error(e.response));
                    }}
                  />
                </Button>
                <Button
                  text={'삭제하기'}
                  className={'outlined-black ml-4 w-32'}
                  onClick={() => {
                    form.setValue(
                      `images`,
                      form.watch('images')?.filter((_, i) => i !== index)
                    );
                  }}
                />
              </div>
            </div>
          ))}
          <label htmlFor="imgPath">
            <div className="grid aspect-[1/1] place-items-center rounded border text-gray-500">
              <div className="flex flex-col items-center space-y-2 text-xs">
                <Icon.FileUpload />
                <p>이미지를 업로드 해주세요.</p>
              </div>
            </div>
            <input
              type="file"
              id="imgPath"
              className="hidden"
              accept="image/*"
              onChange={(e) => {
                e.target.validity.valid &&
                  uploadFile(
                    FileAbout.PRODUCT_GENERAL,
                    e.target.files?.item(0)!
                  )
                    .then((r) => {
                      form.setValue('images', [...form.watch('images')!, r]);
                    })
                    .catch((e) => console.error(e.response));
              }}
            />
          </label>
        </div>

        <div className="grid grid-cols-3 gap-5 pt-5">
          <TextField
            label="바코드"
            {...form.register('barcode', {
              required: '바코드를 입력하세요',
              setValueAs: (value) => value.trim(),
            })}
            helper={form.formState.errors.barcode?.message}
          />
          <TextField
            label="상품명"
            {...form.register('name', { required: '상품명을 입력하세요' })}
            helper={form.formState.errors.name?.message}
          />
          <div className="col-start-1">
            <TextField
              label="정가(원)"
              type={'number'}
              {...form.register('regularPrice', {
                required: '상품 가격을 입력하세요',
                setValueAs: (value) => Number(value),
              })}
              helper={form.formState.errors.regularPrice?.message}
            />
          </div>
          <div>
            {form.watch('discountType') === DiscountType.RATE ? (
              <TextField
                label="할인율(%)"
                type={'number'}
                defaultValue={10}
                {...form.register('discountAmount', {
                  required: '할인율을 입력하세요',
                  setValueAs: (value) => Number(value),
                })}
                helper={form.formState.errors.discountAmount?.message}
              />
            ) : (
              <TextField
                label="할인금액(원)"
                type={'number'}
                defaultValue={0}
                {...form.register('discountAmount', {
                  required: '할인율을 입력하세요',
                  setValueAs: (value) => Number(value),
                })}
                helper={form.formState.errors.discountAmount?.message}
              />
            )}
            <button
              type={'button'}
              className={'label text-blue-500'}
              onClick={() => {
                form.setValue(
                  'discountType',
                  form.watch('discountType') === DiscountType.RATE
                    ? DiscountType.PRICE
                    : DiscountType.RATE
                );
                form.setValue('discountAmount', 0);
              }}
            >
              전환 {'→'}{' '}
              {form.watch('discountType') === DiscountType.RATE ? '₩' : '%'}
            </button>
          </div>

          <TextField
            label="할인율 적용시 할인가(실판매가)"
            type={'number'}
            value={form.watch('price')}
            disabled={true}
          />
          <Select
            label={'대분류'}
            value={selectedCategory.parent?.id.toString()}
            onChange={handleParentSelect}
          >
            <option value={''}>선택하세요</option>
            {categories.map((category, index) => (
              <option key={index} value={category.id}>
                {category.name}
              </option>
            ))}
          </Select>
          <Select
            label={'중분류'}
            value={selectedCategory.children ? selectedCategory.children.id.toString() : ''}
            onChange={handleChildSelect}
          >
            <option value={''}>전체</option>
            {subCategories?.map((category, index) => (
              <option key={index} value={category.id}>
                {category.name}
              </option>
            ))}
          </Select>

          <div className="col-span-3 ">
            <TextArea
              label="상세설명"
              className="h-32"
              {...form.register('contentText')}
            />
          </div>
          <div className="col-span-3 ">
            <div className="col-span-3 mb-2 text-lg font-semibold">
              상품 상세이미지
            </div>
            <FloalaEditor
              imageType={FileAbout.PRODUCT_GENERAL}
              defaultValue={form.watch('contentHtml')}
              onChange={(content) => form.setValue('contentHtml', content)}
            />
          </div>
        </div>

        {!isPromotionProduct ? (
          <>
            <div className="my-6 w-full bg-gray-50 py-2" />
            <EventTagSelector productId={id} />
          </>
        ) : (
          <div className={'py-12'} />
        )}

        <div className="fixed bottom-0 z-10 -ml-8 w-full space-x-4 border-t bg-white py-2 pl-10">
          <Button
            text="삭제"
            className="outlined-black h-12 px-6"
            onClick={deleteItem}
          />
          <Button
            type={'submit'}
            text="저장"
            className="filled-black h-12 px-6"
          />
        </div>
      </form>
    </>
  );
};
