import {UserManageDetailDto} from "../../../types/user";
import {Button} from "../../../components/Button";
import {Card} from "../../../components/Card";
import {Table} from "../../../components/Table";
import {MomentFormat, utcToLocalFormat} from "../../../plugins/moment";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import queryString from "query-string";
import {CouponModal} from "../modal/CouponModal";
import {getUsersCoupons} from "../../../api/couponsAPI";
import {Paged} from "../../../types/common";
import {ExpireReason, UsersCouponDto} from "../../../types/coupons.type";
import {getProductTypeText} from "../../../utils/productTypeText";
import {Pagination} from "../../../components/Pagination";
import moment from "moment";

type UserCouponTableProps = {
    user: UserManageDetailDto;
}

export const UserCouponTable = (props: UserCouponTableProps) => {
    const history = useHistory();
    const query = queryString.parse(history.location.search);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [coupons, setCoupons] = useState<Paged<UsersCouponDto>>({items: []});

    const getItems = () => {
        getUsersCoupons(props.user.id, Number(query.page), Number(query.limit)).then(res => setCoupons(res.data));
    }

    useEffect(() => {
        getItems();
    }, [history.location])

    return (
        <>
            <CouponModal open={isOpen}
                         onClose={() => setIsOpen(false)}
                         onComplete={() => {
                             getItems();
                             setIsOpen(false);
                         }}
            />
            <div className="pt-5">
                <div className="my-3 flex items-center justify-end">
                    <Button
                        text="쿠폰 수동 발급하기"
                        className="filled-black h-10 text-sm"
                        onClick={() => setIsOpen(true)}
                    />
                </div>
                <Card>
                    <Table>
                        <Table.Head>
                            <Table.Row>
                                <Table.Th>ID</Table.Th>
                                <Table.Th>생성일</Table.Th>
                                <Table.Th>사용일</Table.Th>
                                <Table.Th>주문번호</Table.Th>
                                <Table.Th>쿠폰명</Table.Th>
                                <Table.Th>쿠폰 상태</Table.Th>
                                <Table.Th>적용 상품</Table.Th>
                            </Table.Row>
                        </Table.Head>
                        <Table.Body>
                            {coupons.items.length === 0 && (
                                <Table.Row>
                                    <Table.Td colSpan={7} className="text-center">쿠폰이 없습니다.</Table.Td>
                                </Table.Row>
                            )}
                            {coupons.items.map((item, index) => {
                                    return (
                                        <Table.Row key={index}>
                                            <Table.Td>{item.id}</Table.Td>
                                            <Table.Td>
                                                {utcToLocalFormat(item.createdAt.toLocaleString(), MomentFormat.YYYYMMDDHmm)}
                                            </Table.Td>
                                            <Table.Td>
                                                {(item.expireReason === ExpireReason.USED && item.deletedAt) ? utcToLocalFormat(item.deletedAt.toLocaleString(), MomentFormat.YYYYMMDDHmm) : '-'}
                                            </Table.Td>
                                            <Table.Td>{item.orderCode || '-'}</Table.Td>
                                            <Table.Td>{item.coupon.title} {item.coupon.description}</Table.Td>
                                            <Table.Td>{item.isUsed ? '사용완료' : moment(item.expireAt) < moment() ? '기간만료' : !!item.deletedAt ? '만료됨' : ''}</Table.Td>
                                            <Table.Td>{getProductTypeText(item.coupon.allowedProductTypes)}</Table.Td>
                                        </Table.Row>
                                    )
                                })}
                        </Table.Body>
                    </Table>

                    <Pagination>
                        <Pagination.Label count={coupons.items.length}
                                          total={coupons.pagination?.totalItemCount!}/>
                        <Pagination.Nav basePath={history.location.pathname}
                                        total={coupons.pagination?.totalItemCount!}/>
                    </Pagination>
                </Card>
            </div>
        </>
    )
}
